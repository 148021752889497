<template>
 <div>
     <dir>
     <Editor :init='init' v-model="content"></Editor>
     <Tinymce></Tinymce>
 </dir>
 </div>
</template>

<script>
import { Url } from '../../plugin/http'
import Editor from '@tinymce/tinymce-vue'
import Tinymce from './tinymce.min.js'
import './icons/default/icons.min.js'
import './themes/silver/theme.min.js'
import './langs/zh_CN'
// 注册插件 预览插件
import './plugins/preview/plugin.min.js'
import './plugins/paste/plugin.min.js'
import './plugins/wordcount/plugin.min.js'
import './plugins/code/plugin.min.js'

import './plugins/image/plugin.min.js'
import './plugins/imagetools/plugin.min.js'
import './plugins/media/plugin.min.js'
import './plugins/codesample/plugin.min.js'
import './plugins/lists/plugin.min.js'
import './plugins/table/plugin.min.js'

import './plugins/link/plugin.min.js'
export default {
  components: { Editor, Tinymce },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      upUrl: Url + '/file/uploadFiles',
      init: {
        language: 'zh_CN',
        height: '500px',
        margin: '0',
        padding: '0',
        plugins: 'preview paste wordcount code image imagetooles media link lists',
        branding: false,
        indentation: '2em',
        toolbar: ['undo redo | styleselect| bold italic underline strikethrough| alignleft | aligncenter | alignright |alignjustify |fontselect |  image imagetooles|link |removeformat|media ',
          'lineheight| fontsizeselect|outdent indent|codesample |preview paste code codesample |  '],
        fontsize_formats: '8pt 10pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 24pt 36pt',
        font_formats: "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
        // 上传图片的函数
        images_upload_handler: async (blobInfo, succFun, failFun) => {
          const formData = new FormData()
          formData.append('file', blobInfo.blob(), blobInfo.name)// 此处与源文档不一样
          const { data: res } = await this.$http.post(this.upUrl, formData)
          console.log(res)
          if (res.code === 0) {
            succFun(
              res.data.fileUrl
            )

            this.$message.success(res.data.msg)
          } else {
            failFun(this.$message.error('上传失败'))
          }
        },
        imagetools_cors_hosts: [
          '*'
        ],
        imagetools_proxy: ['*']
      },
      content: this.value
    }
  },
  created () {
    console.log(this.upUrl)
  },
  watch: {
    value (newV) {
      this.content = newV
    },
    content (newV) {
      this.$emit('input', newV)
    }
  }
}
</script>

<style>
@import url('./skins/ui/oxide/skin.min.css')
</style>

<template>
  <div>
      <div>
        <a-card>
        <div class="border-left">
            抵押登记/抵押须知
            </div>
            </a-card>
        <div  class="bgc_white" >
          <a-row :gutter="24" >
                <a-col class="gutter-row marginTop" :span="24">
                    <a-table
                      rowKey='id'
                      :columns="columns"
                      :data-source="articleList"
                      bordered
                    >
                    <template slot="action" slot-scope="data">
                        <div class="actionSlot">
                        <a-button class="bgc_none ed"  icon='form' @click="showModal(data.id,data)" >
                            编辑
                        </a-button>
                      </div>
                    </template>
                    </a-table>
                </a-col>
              </a-row>
        </div>
        <a-modal
            class="ant_modal"
              width='83%'
              :title="title"
              :visible="visible"
              :confirm-loading="confirmLoading"
              fill="#4a72b7"
              @cancel="handleCancel"
              cancelText="关闭"
              wrapClassName="ant-modal-cust-warp"
              style="top:5%;height: 85%;overflow-y: hidden"
            >
                  <a-form-model ref='formData3' :rules="rules" :model="form2"   :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="标题:" >
                      <a-input disabled v-model="formData3.powerAttrList.title" />
                    </a-form-model-item>
                    <a-form-model-item label="介绍:" >
                      <a-input disabled v-model="formData3.powerAttrList.intr" />
                    </a-form-model-item>
                    <a-form-model-item label="正文:" prop='journalismContent' >
                    <editor-bar v-model="formData3.powerAttrList.info" :isClear="isClear"  ></editor-bar>
                    </a-form-model-item>
                  </a-form-model>
                 <template slot="footer">
                    <div class="footer">
                        <a-button @click=" submodal " style="background:#70B603;color:white">保存</a-button>
                        <a-button  @click="handleCancel">取消</a-button>
                    </div>
                    </template>
          </a-modal>
        <!-- <a-modal
            class="height_up"
            width='83%'
            title="编辑抵押须知"
            :visible="disabled"
            fill="#4a72b7"
            @cancel="handleCancel"
            @ok="handleOk"
            cancelText="关闭"
            wrapClassName="ant-modal-cust-warp"
            style="top:5%; overflow-y: hidden"
            >
            <a-form-model  class="bgc_white he_80" :model="form2" :label-col="labelCol2" :wrapper-col="wrapperCol2">
            <a-form-model-item label=""  >
                 <editor-bar v-model="formData2.info" :isClear="isClear"  ></editor-bar>
            </a-form-model-item>
            </a-form-model>
        </a-modal> -->
      </div>
  </div>
</template>

<script>
import { Url } from '../../plugin/http'

import EditorBar from '../wangEnduit.vue'
const columns = [
  {
    title: '抵押主题',
    width: '35%',
    dataIndex: 'title',
    align: 'center',
    key: 'title'
  },

  {
    title: '抵押介绍',
    dataIndex: 'intr',
    align: 'center',
    key: 'intr'
  },
  {
    title: '操作',
    width: '2%',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  components: { EditorBar },
  data () {
    return {
      isClear: false,
      disabled: false,
      idEditor: 0,
      titleArticle: '',
      source: '天津市北方公证处',
      formData3rules: {},
      //   el add
      lanmuId: '',
      formData3: {
        powerAttrList: [
          {
            intr: '',
            title: '',
            info: ''
          }
        ]
      },
      workForm: {
        moDate: '',
        moName: '',
        certificateId: '',
        mortgagee: '',
        guarantee: '',
        amount: '',
        moDead: ''
      },
      //   el add
      addObj: {
        title: '',
        source: '天津市北方公证处',
        moDate: '',
        moName: '',
        certificateId: '',
        mortgagee: '',
        guarantee: '',
        amount: '',
        moDead: '',
        moType: 0,
        status: 0,
        id: 0
      },
      // 图片上传 start
      previewVisible: false,
      previewImage: '',
      fileList: [],
      // 图片上传 end
      defaultFileList: [],
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      articleList: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      labelCol2: { span: 4 },
      wrapperCol: { span: 14 },
      wrapperCol2: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formData: {
        endTime: '',
        startTime: '',
        title: '',
        status: 0,
        logType: 0
      },
      formData2: {
        info: '',
        id: 0
      },
      form2: {
        info: ''
      },
      ids: [],
      // 文章的消息
      formModel: {
        title: '',
        journalismContent: '',
        status: 0,
        id: 0
      },
      optionList: [
      ],
      cityList: [{
        type: 0,
        label: '全部'
      },
      {
        type: 1,
        label: '发布'
      }, {
        type: 2,
        label: '草稿'
      }, {
        type: 3,
        label: '撤销'
      }
      ],
      rules: {
        title: [{ required: true, message: '抵押主题不能为空', trigger: 'blur' }],
        source: [{ required: true, message: '来源不能为空', trigger: 'blur' }],
        moDate: [{ required: true, message: '抵押日期不能为空', trigger: 'blur' }],
        moName: [{ required: true, message: '抵押人不能为空', trigger: 'blur' }],
        certificateId: [{ required: true, message: '抵押权人不能为空', trigger: 'blur' }],
        mortgagee: [{ required: true, message: '抵押日期不能为空', trigger: 'blur' }],
        guarantee: [{ required: true, message: '抵押物不能为空', trigger: 'blur' }],
        amount: [{ required: true, message: '数额不能为空', trigger: 'blur' }],
        moDead: [{ required: true, message: '债务人履行债务的期限不能为空', trigger: 'blur' }]
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      },
      upUrl: Url + '/file/uploadFiles'
    }
  },
  created () {
    this.getarticleList()
    this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    //   修改抵押登记内容
    editorden () {
      this.disabled = true
    },
    async handleOk () {
      const { data: res } = await this.$http.post('/backMortgageRegistration/mortgageNoticeUpdate', {
        info: this.formData2.info,
        id: this.formData2.id
      })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.optionListChange()
    },
    // 新增编辑抵押登记
    async submodal (e) {
      if (this.title === '新增抵押登记') {
        this.$refs.formData3.validate(async valid => {
        // console.log(valid)
          if (!valid) return this.$message.error('输入非法数据，请重新输入')
          // 解构赋值，把data赋值给res { data: res }
          const { data: res } = await this.$http.post('/backMortgageRegistration/insertMortgageRegistration', {

            source: this.source,
            status: e,
            list: this.formData3.powerAttrList
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.getarticleList()
        })
      } else {
        this.$refs.formData3.validate(async valid => {
        // console.log(valid)
          if (!valid) return this.$message.error('输入非法数据，请重新输入')
          // 解构赋值，把data赋值给res { data: res }
          const { data: res } = await this.$http.post('/backMortgageRegistration/mortgageNoticeUpdate', {
            info: this.formData3.powerAttrList.info,
            id: this.idEditor
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.getarticleList()
        })
      }
      this.visible = false
    },
    showModal (id, data) {
      this.title = '编辑抵押须知'
      this.idChanges(id)
      this.visible = true
    },
    async idChanges (id) {
      this.idEditor = id
      const { data: res } = await this.$http.get('/backMortgageRegistration/queryMortgageNoticeById')
      console.log(res.data)
      this.titleArticle = res.data.title
      //   this.source = res.data.source
      this.formData3.powerAttrList = res.data
    },
    //   el add
    // 增加工作经历
    addWork () {
      console.log(this.formData3.powerAttrList)
      this.formData3.powerAttrList.push({
        moDate: '',
        moName: '',
        certificateId: '',
        mortgagee: '',
        guarantee: '',
        amount: '',
        moDead: ''
      })
    },
    // 删除属性列
    // 删除
    async removeRow (index) {
      console.log(index)
      var id = index
      const { data: res } = await this.$http.get('/backMortgageRegistration/deleteContent/' + id)
      this.$message.success('删除成功')
      this.formData3.powerAttrList = res.data
      //   const inxsxs = parseInt(index)
      //   this.formData3.powerAttrList = this.formData3.powerAttrList.splice(inxsxs)
      console.log(this.formData3.powerAttrList)
    },
    // 模态框 star

    // 取消
    handleCancel (e) {
      this.titleArticle = ''
      this.source = '天津市北方公证处'
      this.formData3.powerAttrList = []
      this.visible = false
      this.disabled = false
    },
    // 模态框 end
    // this.$router.push('admin/index')
    // 更改文章状态
    async confirm (e, statu) {
      const { data: res } = await this.$http.post('/backMortgageRegistration/updateMortgageRegistrationStatu', {
        id: e,
        status: parseInt(statu)
      })
      this.$message.success(res.msg)
      this.getarticleList()
    },

    handleUser (key) {
    },

    // 删除
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backMortgageRegistration/DeleteInMortgageRegistration', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.ids = []
      this.getarticleList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.title = ''
      this.formData.status = 0
      this.getarticleList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (date, dateString) {
      console.log(dateString)
      this.addObj.moDate = dateString
    },
    onChangeb (date, dateString) {
      console.log(dateString)
      this.addObj.moDate = dateString
      var len = this.formData3.powerAttrList.length - 1
      console.log(this.formData3.powerAttrList[len])
      this.formData3.powerAttrList[len].moDate = dateString
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async getarticleList () {
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      const { data: res } = await this.$http.get('/backMortgageRegistration/mortgageNoticeList')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res.data)
      this.articleList = res.data
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.getarticleList()
    },
    // 抵押须知
    async optionListChange () {
      const { data: res } = await this.$http.get('/backMortgageRegistration/mortgageNotice')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res.data.deptList)
      this.formData2 = res.data
      this.disabled = false
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    }
  }
}
</script>

<style  scoped>
/* 图片上传 start */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 图片上传 结束 end */
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #70B603;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.footer{
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
  align-items: center;
}

.fromList{
    width:62.5%;
    border-bottom: 1px solid #f0f0f0;
    padding: 10px;
    margin-left: 13%;
}
.chongzhi[data-v-1c4d6706] {
    background-color: #00BFBF;
    color: white;
}
.he_80{
    height: 300px;
}
/deep/.ant-modal-content{
    /* height: 630px!important; */
}
/deep/.ant-modal-body{
    height: 700px!important;
}
.from_data{
    text-indent: 2rem;
}
  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
</style>

<template>
  <div>
    <a-card>
         <div class="border-left">
        机构设置/机构简介
         </div>
        </a-card>
      <div>
          <div class="bgc_white  marginTop">
              <a-row :gutter="24" >
                <a-col class="gutter-row marginTop" :span="24">
                    <a-table
                      rowKey='id'
                      :columns="columns"
                      :data-source="articleList"
                      bordered
                    >
                    <template slot="action" slot-scope="data">
                        <div class="actionSlot">
                        <a-button  class="bgc_none ed"  icon='form' @click="showModal(data.id,data)" >
                            编辑
                        </a-button>
                      </div>
                    </template>
                    </a-table>
                </a-col>
              </a-row>
          </div>
          <!-- 模态框 start -->
          <a-modal
            class="ant_modal"
              width='83%'
              :title="title"
              :visible="visible"
              :confirm-loading="confirmLoading"
              fill="#4a72b7"
              @cancel="handleCancel"
              cancelText="关闭"
              wrapClassName="ant-modal-cust-warp"
              style="top:5%;height: 85%;overflow-y: hidden"
            >
                  <a-form-model ref='formModel' :rules="rules" :model="formModel"   :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="标题:" prop="journalismTitle">
                      <a-input disabled v-model="formModel.journalismTitle" />
                    </a-form-model-item>
                    <a-form-model-item label="正文:" prop='journalismContent' >
                    <editor-bar v-model="formModel.journalismContent" :isClear="isClear"  ></editor-bar>

                    </a-form-model-item>
                  </a-form-model>
                 <template slot="footer">
                    <div class="footer">
                        <a-button @click=" submodal " style="background:#70B603;color:white">保存</a-button>
                        <a-button  @click="handleCancel">取消</a-button>
                    </div>
                    </template>
          </a-modal>
          <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
import { Url } from '../../plugin/http'
import EditorBar from '../wangEnduit.vue'
const columns = [
  {
    title: '序号',
    width: '10%',
    dataIndex: 'id',
    align: 'center',
    key: 'id'
  },
  {
    title: '标题',
    width: '85%',
    dataIndex: 'journalismTitle',
    align: 'center',
    key: 'journalismTitle'
  },
  {
    title: '操作',
    width: '8%',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  components: { EditorBar },
  data () {
    return {
      isClear: false,
      // 图片上传 start
      previewVisible: false,
      previewImage: '',
      fileList: [],
      // 图片上传 end
      defaultFileList: [],
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      articleList: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pagesSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      // 文章的消息
      formModel: {
        accessoryList: [],
        journalismTitle: '',
        articleType: '',
        journalismContent: '',
        status: 0,
        id: 0
      },
      optionList: [],
      cityList: [{
        type: 0,
        label: '全部'
      },
      {
        type: 1,
        label: '发布'
      }, {
        type: 2,
        label: '草稿'
      }, {
        type: 3,
        label: '撤销'
      }
      ],
      rules: {
        journalismTitle: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        articleType: [{ required: true, message: '所属栏目不能为空', trigger: 'blur' }]
      },
      formData: {
        endTime: '',
        startTime: '',
        userName: '',
        userStatu: 0
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      },
      upUrl: Url + '/file/uploadFiles',
      deleteUrl: ''
    }
  },
  created () {
    this.getarticleList()
    this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    //   图片上传递 start
    handleUploadSuccess (file) {
      console.log(file)
      var data = {}// 在外面定义对象

      data.url = file.data.fileUrl
      data.name = file.data.fileName
      this.formModel.accessoryList.push(data)
      //   console.log(file.data.fileName)
      //   this.formModel.accessoryList.url = file.data.fileUrl
      //   this.formModel.accessoryList.name = file.data.fileName
      //   arr.url.push(file.data.fileUrl)
      //   arr.name.push(file.data.fileName)
      console.log(this.formModel.accessoryList)
    },
    handleRemove (file, fileList) {
      console.log(file)
      console.log(fileList)
      var arr = []
      for (var i = 0; i < this.formModel.accessoryList.length; i++) {
        if (this.formModel.accessoryList[i] !== file.response.data.fileUrl) {
          arr.push(this.formModel.accessoryList[i])
        }
      }
      console.log(arr)
      this.formModel.accessoryList = arr
    },
    handlePreview (file) {
      console.log(file)
      console.log(this.data.fileList)
    },
    // 图片上传 end
    //  图片上传 start
    handleFileRemove (file) {
      console.log(file.response.data.fileUrl)
      this.deleteUrl = file.response.data.fileUrl
      this.$message.warn('您点击了删除')
      // 去重
      var arr = []
      for (var i = 0; i < this.formModel.accessoryList.length; i++) {
        if (this.formModel.accessoryList[i] !== file.response.data.fileUrl) {
          arr.push(this.formModel.accessoryList[i])
        }
      }
      console.log(arr)
      this.formModel.accessoryList = arr
    },
    upChange (info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        this.$message.success('成功')
        console.log(info)
        const imgUrl = info.file.response.data.fileUrl
        console.log(imgUrl)
        this.formModel.accessoryList.push(imgUrl)
        console.log(this.formModel.accessoryList)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    // 图片上传 end
    // 模态框 start
    showModal (id, data) {
      console.log(id)
      if (id === 0) {
        this.title = '新增文章'
        this.formModel.accessoryList = []
        this.formModel.journalismTitle = ''
        this.formModel.articleType = ''
        this.formModel.journalismContent = ''
        this.formModel.status = ''
      } else {
        this.title = '编辑文章'
        console.log(data)
        this.editor(id)
      }
      this.visible = true
    },
    // 编辑文章
    async editor (e) {
      var id = e
      const { data: res } = await this.$http.get('/orgIntr/queryOrgIntrById/' + id)
      console.log(res.data)
      this.formModel = res.data
    },
    // 新增文章
    async submodal () {
      this.$refs.formModel.validate(async valid => {
      // console.log(valid)
        if (!valid) return this.$message.error('输入非法数据，请重新输入')
        const { data: res } = await this.$http.post('/orgIntr/updateOrgData', {
          journalismTitle: this.formModel.journalismTitle,
          journalismContent: this.formModel.journalismContent,
          id: this.formModel.id
        })
        if (res.code !== 0) return this.$message.error(res.msg)

        this.visible = false
        this.getarticleList()
        this.$message.success(res.msg)
      })
    },
    // 取消
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
      this.formModel.accessoryList = []
      this.fileList = []
    },
    // 模态框 end
    // this.$router.push('admin/index')
    // 更改文章状态
    async confirm (e, statu) {
      const { data: res } = await this.$http.post('/backArticle/updateArticleStatu', {
        id: e,
        status: parseInt(statu)
      })
      this.$message.success(res.data)
      this.getarticleList()
    },

    handleUser (key) {
    },

    // 删除
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backArticle/deleteInArticle', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.ids = []
      this.getarticleList()
    },
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.userName = ''
      this.formData.userStatu = 0
      this.getarticleList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (value, dateString) {
      console.log(dateString)
      this.formData.startTime = dateString[0]
      this.formData.endTime = dateString[1]
      console.log(this.formData.startTime)
      console.log(this.formData.endTime)
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async getarticleList () {
      const { data: res } = await this.$http.post('/orgIntr/queryOrgIntr', {
        formData: this.formData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      // console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.articleList = res.data
    },
    // 获取部门列表
    async optionListChange () {
      const { data: res } = await this.$http.get('/backArticle/insertArticleDate')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.optionList = res.data
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    }
  }
}
</script>

<style  scoped>
/* 图片上传 start */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 图片上传 结束 end */
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #00BFBF;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black!important
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.footer{
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
  align-items: center;
}
/deep/.ant-form input[type='file']{
 display: none!important;
}
.header_3{
    width: 75%;
    display: flex;
}
.width_100{
    width: 45%;
    padding-left: 5%;
    box-sizing: border-box;
}
.width_30{
      display: flex;
      float: right;
      justify-content:flex-end;
}
.width_30 :last-child{
    margin-left: 10%;
}
/deep/.ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 30px;
    width: 220px;
}
/deep/ .ant-form-item-label{
    width: 100px;
}
.he_2{
    width: 100%;
    display: flex;
}
/deep/.reset.ant-btn .anticon {
    color: black!important;
}
/deep/.ant-pagination-item-link  .anticon{
  color: black!important;
}

  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
</style>

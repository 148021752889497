<template>
    <div class="container">
        <div class="loginBox">
          <div class="title_login">
             <img src="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/10/18/56014298-ec24-41b4-a843-dbad7a55dd0d.png" alt="">
          </div>
        <a-form-model ref="loginFromRef" :rules='rules' :model="formdata" class="loginForm">
        <a-form-model-item prop='userName'>
           <a-input v-model="formdata.userName" placeholder="请输入用户名" type='user'>
               <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
            </a-input>
        </a-form-model-item>
         <a-form-model-item prop='passWord'>
            <a-input  v-on:keyup.enter='login' v-model="formdata.passWord" placeholder="请输入密码" type='passWord'>
                <a-icon slot="prefix" type="unlock" style="color:rgba(0,0,0,.25)" />
            </a-input>
        </a-form-model-item>
         <a-form-model-item class="fle_row" prop='code'>
        <!-- 随机码 start -->
          <div class="fle_row" >
            <a-input class="code"  v-on:keyup.enter='login' v-model="formdata.code" placeholder="请输入验证码">
   <!-- <a-icon type="safety-certificate" /> -->
    <a-icon slot="prefix" type="safety-certificate" style="color:rgba(0,0,0,.25)" />
            </a-input>
              <s-identify :identifyCode="identifyCode"></s-identify>
              <span class="login-code"  @click="refreshCode"> <a href="#" class="text_dection">换一张</a> </span>
          </div>
        <!-- 随机码 end -->
        </a-form-model-item>
        <a-form-model-item class="loginbtn">
           <a-button type='primary' class="login1" @click="login">登录</a-button>
        </a-form-model-item>
        </a-form-model>
        </div>
    </div>
</template>
<script>
import SIdentify from '../components/sidentify.vue'
export default {
  components: { SIdentify },
  data () {
    return {
      formdata: {
        userName: '',
        passWord: '',
        code: ''
      },
      identifyCodes: '1234567890',
      identifyCode: '',
      rules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        //   { min: 4, max: 12, message: '用户名必须在4到12个字符之间', trigger: 'blur' }
        ],
        passWord: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码必须在6到20个字符之间', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '验证码错误', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)
  },
  created () {
    this.refreshCode()
  },
  methods: {
    //   重置
    resetForm () {
    //   console.log(this.$refs)
      this.$refs.loginFromRef.resetFields()
    },
    // 登录
    login () {
      this.$refs.loginFromRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return this.$message.error('输入非法数据，请重新输入')
        // 解构赋值，把data赋值给res { data: res }
        const { data: res } = await this.$http.post('/backUser/login', this.formdata)
        // console.log(res)
        if (res.code !== 0) return this.$message.error(res.msg)
        console.log(res.data.token)
        window.sessionStorage.setItem('token', res.data.token)
        window.sessionStorage.setItem('realName', res.data.backUser.realName)
        // realName
        window.sessionStorage.setItem('userType', res.data.backUser.userType)
        window.sessionStorage.setItem('role', res.data.backUser.role)
        // 直接连接到首页的index
        this.$router.push('admin/index')
        // this.$router.push('admin/usermanagement')
      })
    },
    // 验证码
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    refreshCode () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
      console.log(this.identifyCode)
    }

  }
}
</script>
<style  scoped>
.container{
    height: 100%;
    width: 100%;
    background-image: url(http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/10/18/71eb3db3-7251-461a-9dd8-e84797791e69.jpg);
    background-size: 100% 100%;
    background-color: #4a72b7;
}
.title_login{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  margin-bottom: 43px;
  font-weight: 800;
  font-size: 20px;
}
.title_login img{
    width: 298rpx;
}
.loginBox{
    width: 550px;
    height: 410px;
    background-color:rgba(255,255,255);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 9px;
}
.ant-form-item {
margin-bottom: 10px;
}
.loginForm{
    left: 50%;
    top: 1%;
    transform: translate(-50%,-6%);
    width: 65%;
    position: relative;
    bottom: 10%;
    padding: 0 20px;
    box-sizing: border-box;
}
.loginbtn{
    display: flex;
    justify-content: center;
    /* width: 50%; */
    font-size: 16px;
}
.login1{
    width: 320px;
    height: 48px;
    background-color: #4E80E7;

}
.code{
     width:135px;
    height:31px;
 }
 .login-code{
      cursor: pointer;
  text-decoration: #1890FF;
 }
.fle_row{
  width: 100%;
  display: flex;
  /* justify-content: space-around; */

}
.text_dection{
  padding-left: 10px;
  box-sizing: border-box;
  text-decoration: underline;
  /* text-decoration: #1890FF; */
}
</style>

<template>
  <div>
    <a-card>
         <div class="border-left">
        产品管理/热点产品
         </div>
        </a-card>
      <div>
         <!-- 搜索过滤 start -->
         <!-- <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="he_2">
                <div class="header_3">
                  <a-form-model-item label="热点产品:"  >
                     <a-input style="width:80%" v-model="formData.title" allowClear />
                 </a-form-model-item>
                  <div class="width_100">
                      <a-form-model-item label="状态:" class="">
                    <a-select
                        :allowClear="true"
                        placeholder="全部"
                        v-model="formData.status">
                        >
                        <a-select-option v-for="(role,index) in cityList" :key="index"  :value="role.type" >
                        {{ role.label }}
                        </a-select-option>
                    </a-select>
                 </a-form-model-item>
                  </div>
                </div>
                <div class="width_30">
                      <a-button class="search" icon="search" @click="externalLinkChange">
                          搜索
                      </a-button>
                      <a-button   class="reset" icon="redo" @click="reset">
                          重置
                      </a-button>
                </div>
            </div>
         </a-form-model> -->
         <!-- 搜索过滤 end -->
          <div class="bgc_white  marginTop">
              <a-row :gutter="24" >
                <a-col class="gutter-row marginTop" :span="24">
                    <a-table
                      rowKey='id'
                      :columns="columns"
                      :pagination= pagination
                      :data-source="articleList"
                      bordered
                      @change="handleTableChange"
                    >
                     <span slot="pic" slot-scope="pic">
                      <img style="width:100px;background:#1890ff"  :src="pic" alt="">
                     </span>
                      <span slot="status" slot-scope="status">{{ status == 1?'发布':status==2?'草稿':'撤销'}} </span>
                     <template slot="action" slot-scope="data">
                        <div class="actionSlot">
                        <a-button  class="bgc_none ed"  icon='form' @click="showModal(data.id,data)" >
                            编辑
                        </a-button>
                      </div>
                    </template>
                    </a-table>
                </a-col>
              </a-row>
          </div>
          <!-- 模态框 start -->
          <a-modal
            class="ant_modal"
              width='70%'
              :title="title"
              :visible="visible"
              :confirm-loading="confirmLoading"
              fill="#4a72b7"
              @cancel="handleCancel"
              cancelText="关闭"
              wrapClassName="ant-modal-cust-warp"
              style="top:5%;height: 85%;overflow-y: hidden"
            >
                <a-form-model ref='formModel' :rules="rules" :model="formModel"   :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="热点产品名称:" prop="title">
                      <a-input v-model="formModel.title" />
                    </a-form-model-item>

                    <a-form-model-item label="热点产品介绍:" prop="intr">
                      <a-input v-model="formModel.intr" />
                    </a-form-model-item>
                     <a-form-model-item  v-if="isEditor" label="产品介绍:" prop='info' >
                        <editor-bar v-model="formModel.info" :isClear="isClear"  ></editor-bar>
                    </a-form-model-item>
                          <a-form-model-item label="附件:" prop="accessoryList" >
                    <div class="input_hidden" >
                        <el-upload
                            class="upload-demo"
                            :action="upUrl"
                            :on-success="handleUploadSuccess"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            list-type="picture">
                            <!-- v-if="!fileList" -->
                            <el-button   size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </div>
                    </a-form-model-item>
                  </a-form-model>
                    <template slot="footer">
                        <div class="footer">
                          <a-button  @click="submodal" type="primary">保存</a-button>
                          <a-button  @click="handleCancel">取消</a-button>
                        </div>
                      </template>
          </a-modal>
          <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
import { Url } from '../../plugin/http'
import EditorBar from '../wangEnduit.vue'
const columns = [
  {
    title: '序号',
    dataIndex: 'num',
    align: 'center',
    key: 'num'
  },
  {
    title: '热点产品名称',
    width: '15%',
    dataIndex: 'title',
    align: 'center',
    key: 'title'
  },
  {
    title: '产品描述',
    dataIndex: 'intr',
    align: 'center',
    key: 'intr'
  }, {
    title: '图片',
    dataIndex: 'pic',
    align: 'center',
    key: 'pic',
    scopedSlots: {
      customRender: 'pic'
    }
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: '10',
    align: 'center',
    scopedSlots: {
      customRender: 'status'
    }
  },

  {
    title: '操作',
    width: '8%',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  components: { EditorBar },
  data () {
    return {
      isClear: false,
      // 判断是否会有此编辑
      isEditor: false,
      // 图片上传 start
      previewVisible: false,
      previewImage: '',
      fileList: [],
      // 图片上传 end
      defaultFileList: [],
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      articleList: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pagesSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      // 热点产品的消息
      formModel: {
        title: '',
        intr: '',
        pic: '',
        info: '',
        status: '',
        id: 0,
        accessoryList: []
      },
      cityList: [{
        type: 0,
        label: '全部'
      },
      {
        type: 1,
        label: '发布'
      }, {
        type: 3,
        label: '撤销'
      }
      ],
      rules: {
        title: [{ required: true, message: '热点产品名称不能为空', trigger: 'blur' }],
        url: [{ required: true, message: '热点产品地址不能为空', trigger: 'blur' }]
      },
      formData: {
        endTime: '',
        startTime: '',
        title: '',
        status: 0
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      },
      upUrl: Url + '/file/uploadFiles',
      deleteUrl: ''
    }
  },
  created () {
    this.externalLinkChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    //   图片上传递 start
    handleUploadSuccess (file) {
      console.log(file.data.fileName)
      console.log(file.data.fileUrl)
      console.log(this.fileList)
      console.log(this.formModel.accessoryList)
      this.fileList[0].name = file.data.fileName
      this.fileList[0].url = file.data.fileUrl
      console.log(this.fileList)
    },
    handleRemove (file, fileList) {
      console.log(file)
      console.log(file, fileList)
      console.log(this.formModel.accessoryList)
    },
    handlePreview (file) {
      console.log(file)
      console.log(this.data.fileList)
    },
    // 图片上传 end
    //  图片上传 start
    handleFileRemove (file) {
      console.log(file.response.data.fileUrl)
      this.deleteUrl = file.response.data.fileUrl
      this.$message.warn('您点击了删除')
      // 去重
      var arr = []
      for (var i = 0; i < this.formModel.accessoryList.length; i++) {
        if (this.formModel.accessoryList[i] !== file.response.data.fileUrl) {
          arr.push(this.formModel.accessoryList[i])
        }
      }
      console.log(arr)
      this.formModel.accessoryList = arr
    },
    upChange (info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        this.$message.success('成功')
        console.log(info)
        const imgUrl = info.file.response.data.fileUrl
        console.log(imgUrl)
        this.formModel.accessoryList.push(imgUrl)
        console.log(this.formModel.accessoryList)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    // 图片上传 end
    // 模态框 start
    showModal (id, data) {
      this.title = '编辑热点产品'
      this.editor(id)
      this.visible = true
    },
    // 根据id回显
    async editor (e) {
      var id = e
      const { data: res } = await this.$http.get('/backProduct/findByProductId/' + id)
      console.log(res.data)
      if (res.data.info) {
        this.isEditor = true
      }
      //   isEditor
      this.formModel = res.data
      this.fileList = res.data.accessoryList
    },
    // 修改热点产品
    async submodal () {
      this.$refs.formModel.validate(async valid => {
        if (!valid) return this.$message.error('输入非法数据，请重新输入')
        // 解构赋值，把data赋值给res { data: res }
        const { data: res } = await this.$http.post('/backProduct/updateProduct', {
          id: this.formModel.id,
          intr: this.formModel.intr,
          title: this.formModel.title,
          url: this.formModel.url,
          info: this.formModel.info,
          accessoryList: this.fileList
        })
        if (res.code !== 0) return this.$message.error(res.msg)

        this.visible = false
        this.externalLinkChange()
        this.$message.success(res.data)
      })
    },
    // 取消
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
      this.isEditor = false
      this.formModel.accessoryList = []
      this.fileList = []
    },
    // 模态框 end
    // this.$router.push('admin/index')
    // 更改热点产品状态
    async confirm (e, statu) {
      const { data: res } = await this.$http.post('/backProduct/doUpdateProductStatus', {
        id: e,
        status: parseInt(statu)
      })
      this.$message.success(res.data)
      this.externalLinkChange()
    },

    handleUser (key) {
    },

    // 删除
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backArticle/deleteInArticle', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.ids = []
      this.externalLinkChange()
    },
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.title = ''
      this.formData.status = 0
      this.externalLinkChange()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (value, dateString) {
      console.log(dateString)
      this.formData.startTime = dateString[0]
      this.formData.endTime = dateString[1]
      console.log(this.formData.startTime)
      console.log(this.formData.endTime)
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async externalLinkChange () {
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      const { data: res } = await this.$http.post('/backProduct/queryProduct', {
        formData: this.formData,
        pageData: this.pageData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      // console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.articleList = res.data.list
      this.pagination.total = res.data.total
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      console.log(pagination.pageSize)
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      //   this.pagination.pageSize
      this.externalLinkChange()
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    }
  }
}
</script>

<style  scoped>
/* 图片上传 start */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 图片上传 结束 end */
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #00BFBF;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black!important
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.footer{
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
  align-items: center;
}
/deep/.ant-form input[type='file']{
 display: none!important;
}
.header_3{
    width: 75%;
    display: flex;
}
.width_100{
    width: 45%;
    padding-left: 5%;
    box-sizing: border-box;
}
.width_30{
      display: flex;
      float: right;
      justify-content:flex-end;
}
.width_30 :last-child{
    margin-left: 10%;
}
/deep/.ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 30px;
    width: 220px;
}
/deep/ .ant-form-item-label{
    width: 100px;
}
.he_2{
    width: 100%;
    display: flex;
}
/deep/.reset.ant-btn .anticon {
    color: black!important;
}
/deep/.ant-pagination-item-link  .anticon{
  color: black!important;
}
/* .upload-demo{
    position: relative;
    top: -50px;
}
.upload-demo button{
    position: absolute;
    top: 51px;
    z-index: 999;
    width: 100%;
    height: 92px;
    border: none;
    background-color: transparent;
}*/
/deep/.el-upload-list--picture .el-upload-list__item-thumbnail{
    width: auto;
}
[data-v-555b28ad] .ant-input-affix-wrapper .ant-input:not(:last-child){
        width: 292px;

}
 /deep/ .header_3 .anticon{
    color: rgba(0, 0, 0, 0.25)!important;
}
/deep/.ant-input-affix-wrapper .ant-input-suffix {
right: -62px;
}
/deep/.el-upload-list--picture .el-upload-list__item-thumbnail{
    background-color:#1890ff!important ;
}

  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
</style>

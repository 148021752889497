<template>
  <div>
    <a-card>
            <div class="border-left">
               系统管理/用户管理
            </div>
    </a-card>
    <div>
      <a-form-model class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
               <div class="he_2">
                <div class="header_3">
                <a-form-model-item label="登录账号:">
                    <a-input v-model="formData.userName" allowClear/>
                </a-form-model-item>
                <div class="width_100">
                    <a-form-model-item label="账户状态:">
                        <a-select
                        :allowClear="true"
                        style="width: 100%"
                        placeholder="全部"
                        v-model="formData.userStatu">
                        >
                        <a-select-option v-for="(roleId,index) in cityList" :key="index">
                            {{ roleId.label }}
                        </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div class="width_100" style="padding-left:0">
                        <a-form-model-item label="创建时间:">
                        <a-range-picker
                        :show-time="{ format: 'HH:mm' }"
                          :value="createValue"
                        format="YYYY-MM-DD HH:mm"
                        :placeholder="['开始时间', '结束时间']"
                        @change="onChange"
                        @ok="onOk"
                        />
                    </a-form-model-item>
                </div>
                </div>
                    <div class="width_30">
                        <a-button class="search" icon="plus" @click="showModal(0,0)">
                        新增
                        </a-button>
                       <a-button class="search" icon="search" @click="getuserList">
                            搜索
                        </a-button>
                        <a-button class="reset" icon="redo" @click="reset">
                            重置
                        </a-button>
                  </div>
               </div>
      </a-form-model>
      <div class="bgc_white  marginTop">
        <a-row :gutter="24">
          <a-col class="gutter-row marginTop" :span="24">
            <a-table
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              rowKey='id'
              :columns="columns"
              :pagination=pagination
              :data-source="userlist"
              bordered
              @change="handleTableChange"
            >
              <span slot="sex" slot-scope="sex">{{ sex == '男' ? '男' : '女' }} </span>
              <!-- roleIdName -->
              <span slot="userStatu" slot-scope="userStatu">{{ userStatu == 0 ? '正常' : '禁用' }} </span>
              <template slot="action" slot-scope="data">
                <div class="actionSlot">
                  <a-button  class="bgc_none ed"  icon='form' @click="showModal(data.id,data)">
                    编辑
                  </a-button>

                  <a-popconfirm placement="left" ok-text="删除" cancel-text="不删除" @confirm="deleteChange(data.id)">
                    <template slot="title">
                      <p>是否删除此用户?</p>
                      <p>删除成功后将不能恢复</p>
                    </template>
                    <a-button class="bgc_none delete" icon="delete">
                      删除
                    </a-button>
                  </a-popconfirm>
                  <a-popconfirm placement="left" ok-text="重置" cancel-text="不重置" @confirm="confirm(data.id)">
                    <template slot="title">
                      <p>是否重置此用户?</p>
                      <p>密码的值：123456</p>
                    </template>
                    <a-button class="chongzhis" icon='unlock'>
                      重置密码
                    </a-button>
                  </a-popconfirm>
                </div>
              </template>
            </a-table>
          </a-col>
        </a-row>
      </div>

      <!-- 模态框 start -->
      <a-modal
        width='40%'
        :title="title"
        :visible="visible"
        :confirm-loading="confirmLoading"
        fill="#4a72b7"
        @ok="handleOk"
        @cancel="handleCancel"
        ok-text="确认"
        cancelText="取消"
      >
        <a-form-model  ref='ruleForm' :rules="ruleFormrules" :model="formModel" :label-col="labelCol"
                      :wrapper-col="wrapperCol">
          <a-form-model-item label="登录账户：" prop="userName">
            <a-input :disabled='disabled' v-model="formModel.userName"/>
          </a-form-model-item>
          <a-form-model-item label="真实姓名：" prop="realName">
            <a-input :disabled='disabled' v-model="formModel.realName"/>
          </a-form-model-item>
          <a-form-model-item label="性别：" prop="sex">
            <a-radio-group v-model="formModel.sex">
              <a-radio value="女">
                女
              </a-radio>
              <a-radio value="男">
                男
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="电话号码：" prop='phone'>
            <a-input   v-model="formModel.phone"/>
          </a-form-model-item>
          <a-form-model-item label="电子邮箱：" prop='email'>
            <a-input v-model="formModel.email"/>
          </a-form-model-item>
          <a-form-model-item label="账户状态：" prop='userStatu'>
            <a-select
              style="width: 160px;"
              v-model="formModel.userStatu"
              placeholder="请选择账户状态"
              @change="handleUser">
              <a-select-option :key="item.type" v-for="item in statusList">{{ item.label }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="用户角色：" prop="roleId">
            <a-radio-group v-model="formModel.roleName">
              <a-radio value="公证员">
                公证员
              </a-radio>
              <a-radio value="管理员">
                管理员
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="所属部门：" prop="deptId">
            <a-select
              style="width: 160px;"
              v-model="formModel.deptId"
              placeholder="请选择属性"
              @change="handleProperty">
              <a-select-option :key="item.id" v-for="item  in optionList">{{ item.deptName }}</a-select-option>
            </a-select>
          </a-form-model-item>

        </a-form-model>
      </a-modal>
      <!-- 模态框 end -->
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    width: '4%',
    align: 'center',
    key: 'id'
  },
  {
    title: '登录账户',
    dataIndex: 'userName',
    width: '6%',
    align: 'center',
    key: 'userName'
  },
  {
    title: '姓名',
    dataIndex: 'realName',
    width: '6%',
    align: 'center',
    key: 'realName'
  },
  {
    title: '性别',
    dataIndex: 'sex',
    width: '4%',
    key: 'sex',
    align: 'center',
    scopedSlots: {
      customRender: 'sex'
    }
  },
  {
    title: '部门',
    dataIndex: 'deptName',
    width: '10%',
    align: 'center',
    key: 'deptName'
  },
  {
    title: '电话号码',
    dataIndex: 'phone',
    width: '10%',
    align: 'center',
    key: 'phone'
  },
  {
    title: '电子邮箱',
    dataIndex: 'email',
    width: '15%',
    align: 'center',
    key: 'email'
  },
  {
    title: '账户状态',
    dataIndex: 'userStatu',
    width: '6%',
    key: 'userStatu',
    align: 'center',
    scopedSlots: {
      customRender: 'userStatu'
    }
  },
  {
    title: '角色',
    dataIndex: 'roleName',
    width: '6%',
    align: 'center',
    key: 'roleName',
    scopedSlots: {
      customRender: 'roleName'
    }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: '10%',
    align: 'center',
    key: 'createTime'
  },
  {
    title: '操作',
    width: '25%',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]
const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  data () {
    return {
      createValue: [],
      disabled: false,
      ruleFormrules: {
        userName: [
          {
            required: true,
            message: '请输入登录账户',
            trigger: 'blur'
          }
        ],
        realName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: '请输入电话号码',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: '请输入电子邮箱',
            trigger: 'blur'
          }
        ],
        sex: [
          {
            required: true,
            message: '请选择性别',
            trigger: 'blur'
          }
        ],
        userStatu: [
          {
            required: true,
            message: '请选择用户状态',
            trigger: 'blur'
          }

        ],
        roleId: [
          {
            required: true,
            message: '请选择用户角色',
            trigger: 'blur'
          }
        ],
        deptId: [
          {
            required: true,
            message: '请选择所属部门',
            trigger: 'blur'
          }
        ]
      },
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      userlist: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      formModel: {
        id: 0,
        deptId: '',
        email: '',
        isDelete: true,
        passWord: '',
        phone: '',
        realName: '',
        roleId: '',
        sex: '男',
        userName: '',
        userStatu: 0
      },
      statusList: [
        {
          type: 0,
          label: '正常'
        }, {
          type: 1,
          label: '禁用'
        }
      ],
      roleIdList: [
        {
          type: 2,
          label: '公证员'
        }, {
          type: 3,
          label: '管理员'
        }
      ],
      optionList: [],
      cityList: [
        {
          type: 0,
          label: '正常'
        }, {
          type: 1,
          label: '禁用'
        }
      ],

      formData: {
        endTime: '',
        startTime: '',
        makeName: '',
        makePhone: ''
      },
      rules: {
        userName: [{
          required: true,
          message: '登陆账号不能为空',
          trigger: 'blur'
        }],
        realName: [{
          required: true,
          message: '姓名不能为空',
          trigger: 'blur'
        }],
        sex: [{
          required: true,
          message: '请选择性别',
          trigger: 'blur'
        }],
        deptId: [{
          required: true,
          message: '请选择部门',
          trigger: 'blur'
        }],
        roleId: [{
          required: true,
          message: '请选择角色',
          trigger: 'blur'
        }]
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      }
    }
  },
  created () {
    this.getuserList()
    this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    // 重置密码
    async confirm (e) {
      console.log(e)
      var id = e
      const { data: res } = await this.$http.get('/backUser/resetPassword/' + id)
      this.$message.success(res.msg)
    },
    // 获取部门列表
    async optionListChange () {
      const { data: res } = await this.$http.get('/backUser/insertData')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      // console.log(res.data.deptIdList)
      this.optionList = res.data.deptList
    },
    handleUser (key) {
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    },
    // 模态框 start
    showModal (id, data) {
      if (id === 0) {
        this.disabled = false
        this.title = '新增用户'
      } else {
        this.title = '编辑用户'
        console.log(data)
        this.disabled = true
        this.editorChange(id)
      }
      this.visible = true
    },
    // 根据id查询订单
    async  editorChange (id) {
      const { data: res } = await this.$http.get('/backUser/getUserById/' + id)
      this.formModel = res.data
    //   this.roleList = res.data.roleList
    //   this.fileList = res.data.picList
    //   this.fileList1 = res.data.busCardList
    },
    // 新增
    async handleOk (e) {
      if (this.formModel.roleName === '公证员') {
        this.formModel.roleId = 1
      } else if (this.formModel.roleName === '管理员') {
        this.formModel.roleId = 2
      }
      if (this.title === '新增用户') {
        this.$refs.ruleForm.validate(async valid => {
          // console.log(valid)
          if (!valid) return this.$message.error('输入非法数据，请重新输入')
          // 解构赋值，把data赋值给res { data: res }
          const { data: res } = await this.$http.post('/backUser/insertUser', {
            userName: this.formModel.userName,
            realName: this.formModel.realName,
            sex: this.formModel.sex,
            phone: this.formModel.phone,
            email: this.formModel.email,
            userStatu: this.formModel.userStatu,
            deptId: this.formModel.deptId,
            roleId: this.formModel.roleId
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.visible = false
          this.formModel.deptId = ''
          this.formModel.roleId = ''
          this.formModel.email = ''
          this.formModel.isDelete = true
          this.formModel.passWord = ''
          this.formModel.phone = ''
          this.formModel.realName = ''
          this.formModel.roleId = ''
          this.formModel.sex = '男'
          this.formModel.userName = ''
          this.formModel.userStatu = 0
          this.getuserList()
        })
      } else {
        if (this.formModel.roleName === '公证员') {
          this.formModel.roleId = 1
        } else if (this.formModel.roleName === '管理员') {
          this.formModel.roleId = 2
        }
        this.$refs.ruleForm.validate(async valid => {
          // console.log(valid)
          if (!valid) return this.$message.error('输入非法数据，请重新输入')
          // 解构赋值，把data赋值给res { data: res }
          const { data: res } = await this.$http.post('/backUser/updateBackUser', {
            userName: this.formModel.userName,
            realName: this.formModel.realName,
            sex: this.formModel.sex,
            phone: this.formModel.phone,
            email: this.formModel.email,
            userStatu: this.formModel.userStatu,
            deptId: this.formModel.deptId,
            roleId: this.formModel.roleId,
            id: this.formModel.id
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.visible = false
          this.formModel.deptId = ''
          this.formModel.roleId = ''
          this.formModel.email = ''
          this.formModel.isDelete = true
          this.formModel.passWord = ''
          this.formModel.phone = ''
          this.formModel.realName = ''
          this.formModel.roleId = ''
          this.formModel.sex = '男'
          this.formModel.userName = ''
          this.formModel.userStatu = 0
          this.getuserList()
        })
      }
    },
    // 取消
    handleCancel (e) {
      this.visible = false
      this.formModel.deptId = ''
      this.formModel.roleId = ''
      this.formModel.email = ''
      this.formModel.isDelete = true
      this.formModel.passWord = ''
      this.formModel.phone = ''
      this.formModel.realName = ''
      this.formModel.roleId = ''
      this.formModel.sex = '男'
      this.formModel.userName = ''
      this.formModel.userStatu = 0
    },
    // 模态框 end
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backUser/DeleteInBatches', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.userlist = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.ids = []
      location.reload()
      this.$router.go(0)
      this.getuserList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.userName = ''
      this.formData.userStatu = 0
      this.createValue = []
      this.getuserList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (value, dateString) {
      this.createValue = value
      console.log(dateString)
      this.formData.startTime = dateString[0]
      this.formData.endTime = dateString[1]
      console.log(this.formData.startTime)
      console.log(this.formData.endTime)
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async getuserList () {
      this.pageData.pageSize = 5
      this.pageData.currentPage = this.queryParam.pagenum
      const { data: res } = await this.$http.post('/backUser/QueryUser', {
        formData: this.formData,
        pageData: this.pageData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      console.log(res)
      if (res.data == null) {
        this.userlist = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userlist = res.data.list
      this.pagination.total = res.data.total
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.getuserList()
    }
  }
}
</script>

<style scoped>
.style_80 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.style_70 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.style_80 button {
  margin: 10px;
  margin-top: 0;
}

.search {
  background-color: #4a72b7;
  color: white;
}

.prink_search {
  background-color: #EC808D;
  color: white;
}

.chongzhi {
  background-color: #00BFBF;
  color: white;
}

.reset {
  background-color: #F2F2F2;
  color: black
}

.bgc_white {
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}

.marginTop {
  margin-top: 20px;
}

.actionSlot {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/deep/ .ant-modal-header {
  background-color: #4a72b7;
}

/deep/ .ant-modal-title {
  color: white;
  font-weight: 800;
}

/deep/ .ant-btn.ant-btn-primary {
  background-color: #4a72b7;
}
.header_3{
    width: 75%;
    display: flex;
}
.he_2{
    width: 100%;
    display: flex;
}
.width_100{
    width: 50%;
    padding-left: 5%;
    box-sizing: border-box;
}
.width_30{
      display: flex;
}
.width_30 .search {
    margin-top: 5px;
    margin-right: 1%;
}
.width_30 .reset{
    margin-top: 5px;
}
/deep/.ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 30px;
    width: 220px;
}
/deep/ .ant-form-item-label{
    width: 100px;
}

  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
 .chongzhis{
     color: #44d0cf ;
     border: 1px solid #44d0cf;
 }
</style>

<template>
  <div>
      <div>
        <a-card>
            <div class="border-left">
               便民服务/表单下载
            </div>
        </a-card>
        <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row :gutter="24" >
             <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                 <a-form-model-item label="表单名称:"  >
                     <a-input v-model="formData.title" allowClear />
                 </a-form-model-item>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                <a-form-model-item label="创建时间:">
                    <a-range-picker
                          :show-time="{ format: 'HH:mm' }"
                          format="YYYY-MM-DD"
                          :placeholder="['开始时间', '结束时间']"
                          @change="onChange"
                          @ok="onOk"
                        />
                </a-form-model-item>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                 <a-col class="gutter-row"  :span="24">
                    <div class="gutter-box style_80">
                      <a-button class="search"  icon="plus" @click="showModal(0,0)"  >
                            新增
                      </a-button>
                      <a-button class="search" icon="search" @click="onlineList">
                          搜索
                      </a-button>
                      <a-button   class="reset" icon="redo" @click="reset">
                          重置
                      </a-button>
                    </div>
              </a-col>
              </div>
            </a-col>
          </a-row>
           <a-row :gutter="24" >
              <a-col class="gutter-row marginTop" :span="24">
                  <a-table
                     rowKey='id'
                    :columns="columns"
                    :pagination= pagination
                    :data-source="articleList"
                     bordered
                     @change="handleTableChange"
                  >
                   <!-- roleName -->
                         <span slot="status" slot-scope="status">{{ status == 1?'发布':status==2?'草稿':'撤销'}} </span>
                   <span slot="makeStatus" slot-scope="makeStatus">{{ makeStatus == true?'已回复':'未回复'}} </span>
                   <template slot="action" slot-scope="data">
                      <div class="actionSlot">
                        <a-button  class="bgc_none ed"  icon='form' @click="showModal(data.id,data)" >
                          编辑
                        </a-button>
                     <div>
                        <a-popconfirm placement="left"  ok-text="发布" cancel-text="不发布"  @confirm="confirm(data.id,1)">
                         <template slot="title">
                          <p>是否发布此表单?</p>
                          <p>发布后能下载此表单</p>
                        </template>
                        <a-button class="cao" icon='container'  v-if="data.status==2" >
                            草稿
                        </a-button>
                      </a-popconfirm>
                      <a-popconfirm placement="left" v-if="data.status==1"  ok-text="撤销" cancel-text="不撤销" @confirm="confirm(data.id,3)">
                        <template slot="title">
                          <p>是否撤销此表单?</p>
                          <p>撤销后将不能下载此表单</p>
                        </template>
                        <a-button class="bgc_none che" icon='rollback'   >
                            撤销
                        </a-button>
                      </a-popconfirm>
                        <a-popconfirm placement="left" v-if="data.status==3"  ok-text="发布" cancel-text="不发布" @confirm="confirm(data.id,1)">
                        <template slot="title">
                          <p>是否发布此表单?</p>
                          <p>发布后能下载此表单</p>
                        </template>
                        <a-button class="bgc_none fabu" icon='upload'   >
                            发布
                        </a-button>
                      </a-popconfirm>
                     </div>
                      <a-popconfirm placement="left" ok-text="删除" cancel-text="不删除" @confirm="deleteChange(data.id)">
                        <template slot="title">
                          <p>是否删除此表单?</p>
                          <p>删除成功后将不能恢复</p>
                        </template>
                         <a-button class="bgc_none delete" icon="delete" >
                            删除
                         </a-button>
                      </a-popconfirm>

                       </div>
                   </template>
                  </a-table>
              </a-col>
            </a-row>
         </a-form-model>
         <!-- 模态框 start -->
         <a-modal
            width='40%'
              :title="title"
              :visible="visible"
              :confirm-loading="confirmLoading"
              fill="#4a72b7"
              @cancel="handleCancel"
              cancelText="关闭"
              wrapClassName="ant-modal-cust-warp"
              style="top:5%;height: 85%;overflow-y: hidden"
          >
              <a-form-model ref='formModel' :rules="rules" :model="formModel" :label-col="labelCol" :wrapper-col="wrapperCol">
                   <a-form-model-item label="表单名称" prop="title">
                      <a-input v-model="formModel.title" />
                   </a-form-model-item>
                   <a-form-model-item label="附件" prop="fromUrl" >
                     <el-upload
                            :before-upload="beforeUploadFile"
                            :limit="limitNum"
                            class="upload-demo"
                            :action="upUrl"
                            :on-success="handleUploadSuccess"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            >
                            <el-button   size="small" type="primary">添加附件</el-button>
                        </el-upload>
                    </a-form-model-item>
                </a-form-model>
                  <template slot="footer">
                        <div class="footer">
                          <a-button   class="chongzhi "   >
                            <a :href="fileListUrl"  :download="filelistName" >下载</a>
                         </a-button>
                          <a-button  @click="submodal(2)" style="background:#F59A23;color:white">草稿</a-button>
                          <a-button  @click="submodal(1)" type="primary">发布</a-button>
                          <a-button  @click="handleCancel">取消</a-button>
                        </div>
                      </template>
         </a-modal>
        <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
import { MessageBox } from 'element-ui'
import { Url } from '../../plugin/http'
const columns = [
  {
    title: '序号',
    dataIndex: 'rowId',
    align: 'center',
    key: 'rowId'
  },
  {
    title: '表单名称',
    width: '35%',
    dataIndex: 'title',
    align: 'center',
    key: 'title'
  },
  {
    title: '下载次数',
    dataIndex: 'upDown',
    align: 'center',
    key: 'upDown'
  },
  {
    title: '上传时间',
    width: '15%',
    dataIndex: 'createTime',
    align: 'center',
    key: 'createTime'
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '操作',
    align: 'center',
    width: '25%',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  data () {
    return {
      // 图片上传 start
      limitNum: 1,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      fileListUrl: '',
      filelistName: '',
      // 图片上传 end
      defaultFileList: [],
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      articleList: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      // 文章的消息
      formModel: {
        fromUrl: '',
        title: '',
        status: 0,
        id: 0
      },
      optionList: [
      ],
      cityList: [{
        type: 0,
        label: '全部'
      },
      {
        type: 1,
        label: '发布'
      }, {
        type: 3,
        label: '撤销'
      }
      ],
      rules: {
        title: [{ required: true, message: '文章标题不能为空', trigger: 'blur' }]
      },
      formData: {
        endTime: '',
        startTime: '',
        title: ''
      },
      pageData: {
        currentPage: 1,
        pageSize: 10
      },
      upUrl: Url + '/file/uploadFiles'
    }
  },
  created () {
    this.onlineList()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    beforeUploadFile () {

    },
    //  图片上传 start
    handleUploadSuccess (file) {
      var arr = [{
        name: '',
        url: ''
      }]
      console.log(this.fileList)
      if (
        this.title === '编辑表单') {
        MessageBox.confirm('此操作会覆盖以前的附件？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.fileListUrl = file.data.fileUrl
            this.formModel.accessoryList = []
            this.fileList = []
            if (this.fileList.length === 0) {
              arr[0].name = file.data.fileName
              arr[0].url = file.data.fileUrl
              if (arr[0].name) {
                this.fileList = arr
              }
            } else {
              this.fileList[0].url = file.data.fileUrl
            }
          })
          .catch(() => {
            console.log(this.fileList)
          })
      } else {
        this.fileListUrl = file.data.fileUrl
        this.formModel.accessoryList = []
        this.fileList = []
        if (this.fileList.length === 0) {
          arr[0].name = file.data.fileName
          arr[0].url = file.data.fileUrl
          if (arr[0].name) {
            this.fileList = arr
          }
        } else {
          this.fileList[0].url = file.data.fileUrl
        }
      }
    },
    handleRemove (file, fileList) {
      console.log(file)
      console.log(file, fileList)
      console.log(this.formModel.accessoryList)
    },
    handlePreview (file) {
      console.log(file)
      console.log(this.data.fileList)
    },
    // 图片上传 end
    // 模态框 start
    showModal (id, data) {
      if (id === 0) {
        this.title = '新增表单'
        this.formModel.fromUrl = ''
        this.formModel.title = ''
      } else {
        this.title = '编辑表单'
        this.limitNum = 2
        this.editorChange(id)
      }
      this.visible = true
    },
    // 根据id查询订单
    async  editorChange (id) {
      const { data: res } = await this.$http.get('/backForm/queryFormById/' + id)
      this.formModel = res.data
      this.fileList = res.data.accessoryList
    //   this.fileListUrl = res.data.accessoryList[0].url
    //   this.filelistName = res.data.accessoryList[0].name
    },
    // 新增文章表单下载
    async submodal (e) {
      if (e === 0) {
        return this.$message.success('请点击文件预览即可查看')
      } else if (e === 1) {
        this.formModel.status = 1
      } else if (e === 2) {
        this.formModel.status = 2
      }
      this.$refs.formModel.validate(async valid => {
      // console.log(valid)
        if (!valid) return this.$message.error('输入非法数据，请重新输入')
        // 解构赋值，把data赋值给res { data: res }
        if (this.title === '新增表单') {
          const { data: res } = await this.$http.post('/backForm/insertFrom', {
            title: this.formModel.title,
            accessoryList: this.fileList,
            status: this.formModel.status
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.onlineList()
          this.visible = false
          this.limitNum = 1
          this.formModel.accessoryList = []
          this.fileList = []
        } else {
          const { data: res } = await this.$http.post('/backForm/updateFrom', {
            accessoryList: this.fileList,
            title: this.formModel.title,
            status: this.formModel.status,
            id: this.formModel.id
          })
          if (res.code !== 0) return this.$message.error(res.msg)

          this.visible = false
          this.onlineList()
          this.limitNum = 1
          this.formModel.accessoryList = []
          this.fileList = []
          this.$message.success(res.msg)
        }
      })
    },
    // 取消
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
      this.formModel.accessoryList = []
      this.fileList = []
      this.fileListUrl = ''
      this.filelistName = ''
    },
    // 模态框 end
    // this.$router.push('admin/index')
    // 更改文章状态
    async confirm (e, statu) {
      const { data: res } = await this.$http.post('/backForm/updateFromStatus', {
        id: e,
        status: parseInt(statu)
      })
      this.$message.success(res.msg)
      this.onlineList()
    },

    handleUser (key) {
    },

    // 删除
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backForm/deleteInFrom', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.ids = []
      this.onlineList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.title = ''
      this.formData.makePhone = ''
      this.onlineList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (value, dateString) {
      console.log(dateString)
      this.formData.startTime = dateString[0]
      this.formData.endTime = dateString[1]
      console.log(this.formData.startTime)
      console.log(this.formData.endTime)
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async onlineList () {
      if (this.formData.makePhone) {
        if (!(/^1[3456789]\d{9}$/.test(this.formData.makePhone))) {
          // this.$notify.error({
          //   message: '手机号格式错误'
          // })
          return this.$message.error('手机号格式错误')
        }
      }
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      // this.$refs.loginFromRef.validate(async valid => {
      // console.log(valid)
      // if (!valid) return this.$message.error('输入非法数据，请重新输入')
      // 解构赋值，把data赋值给res { data: res }
      const { data: res } = await this.$http.post('/backForm/queryForm', {
        formData: this.formData,
        pageData: this.pageData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      // console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.articleList = res.data.list
      this.pagination.total = res.data.total
      // console.log(res.data.token)
      // window.sessionStorage.getItem('token', res.data.token)
      // 直接连接到首页的index
      // this.$router.push('admin/index')
      // this.$router.push('admin/usermanagement')
      // })
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.onlineList()
    }
  }
}
</script>

<style  scoped>
/* 图片上传 start */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 图片上传 结束 end */
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #70B603;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.footer{
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
  align-items: center;
}
/deep/.ant-form input[type='file']{
 display: none!important;
}
/deep/.el-upload-list--picture .el-upload-list__item-thumbnail{
    width: auto;
}
/deep/.el-upload-list__item.is-success .el-upload-list__item-status-label{
    display: none;
}

  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
</style>

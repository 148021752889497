<template>
  <div>
    <a-card>
         <div class="border-left">

        便民服务/联系我们
         </div>
        </a-card>
      <div>
         <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="he_2">
                <div class=" ">
                  <a-form-model-item label="工作时间:"  >
                     <a-input  placeholder="1" v-model="list_first.wkTime" allowClear />
                 </a-form-model-item>
                   <a-form-model-item label="上午:">
                         <el-time-select
                            placeholder="起始时间"
                            v-model="list_first.amtime1"
                            :picker-options="{
                            start: '08:00',
                            step: '00:30',
                            end: '12:00'
                            }">
                        </el-time-select> ~
                        <el-time-select
                            placeholder="结束时间"
                            v-model="list_first.amtime2"
                            :picker-options="{
                            start: '08:00',
                            step: '00:30',
                            end: '12:00',
                            minTime: startTime
                            }">
                        </el-time-select>
                 </a-form-model-item>
                   <a-form-model-item label="下午:">
                        <el-time-select
                            placeholder="起始时间"
                            v-model="list_first.pmtime1"
                            :picker-options="{
                            start: '13:00',
                            step: '00:30',
                            end: '18:00',
                            }">
                        </el-time-select> ~
                        <el-time-select
                            placeholder="结束时间"
                            v-model="list_first.pmtime2"
                            :picker-options="{
                            start: '13:00',
                            step: '00:30',
                            end: '18:00',
                            minTime: startTime
                            }">
                        </el-time-select>
                 </a-form-model-item>
                </div>
                <div class="header_3">
                      <a-form-model-item label="地址:"  >
                     <a-input    v-model="list_first.addr" allowClear />
                 </a-form-model-item>
                </div>
                 <div class="header_3">
                      <a-form-model-item label="传真:"  >
                     <a-input   v-model="list_first.fax" allowClear />
                 </a-form-model-item>
                </div>
                <div class="header_3">
                      <a-form-model-item label="电子邮箱:"  >
                     <a-input   v-model="list_first.email" allowClear />
                 </a-form-model-item>
                </div>
                   <div class="header_3">
                 <a-form-model-item label="总机电话:"  >
                     <a-input   v-model="list_first.mainTel" allowClear />
                 </a-form-model-item>
                 </div>
                 <div>
                    <el-row>
                     <el-col :span="24">
                         <!-- :label-width="formLabelWidth" -->
                        <a-form-model-item label="分机电话:" >
                            <el-table :data="zichandetail.members" :border=true style="width:54%;">
                                <el-table-column type="index" label="序号" width="100"></el-table-column>
                                <el-table-column prop="name" label="分机部门" width="150">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.name" autocomplete="off" size="small"  placeholder="请输入部门名称"></el-input>
                                    </template>
                                </el-table-column>
                                 <el-table-column prop="name" label="分机电话" width="150">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.tel" autocomplete="off" size="small"  placeholder="请输入分机电话"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="100">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="danger" plain @click="delmembers(scope.$index, scope.row)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <i class="el-icon-circle-plus-outline" @click="addmembers"></i>
                        </a-form-model-item>
                    </el-col>
                    </el-row>
                 </div>

                <div class="header_3">
                      <a-form-model-item label="公交路线:"  >
                     <a-input   type='textarea'   v-model="list_first.busRoute" allowClear />
                 </a-form-model-item>
                </div>
                 <div class="header_3">
                      <a-form-model-item label="地铁路线:"  >
                        <a-input  type='textarea'    v-model="list_first.subwayRoute" allowClear />
                      </a-form-model-item>
                </div>

                <!-- 分机 电话 end-->
                <div class="header_3 float_right">
                      <a-form-model-item  class="header_3 ">
                        <a-button type="primary" html-type="submit"  @click="submitForm('environmentForm')">
                            修改
                        </a-button>
                     </a-form-model-item>
                </div>
                 </div>
         </a-form-model>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list_first: {
        addr: '',
        amtime: '',
        busRoute: '',
        email: '',
        fax: '',
        id: 0,
        mainTel: '',
        pmtime: '',
        posiNum: '',
        posiNums: null,
        qrUrl: '',
        subTel: '',
        subTelList: [],
        subwayRoute: '',
        wkTime: '',
        amtime1: '',
        amtime2: '',
        pmtime1: '',
        pmtime2: ''
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {},
      cityList: [],
      relation: {
        endTime: '',
        startTime: '',
        title: '',
        status: 0
      },
      startTime: '',
      endTime: '',
      zichandetail: {
        members: [{
          name: '',
          tel: ''
        }]
      },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 }
        }
      },
      dynamicValidateForm: {
        domains: []
      }
    }
  },
  created () {
    this.datalist()
  },
  methods: {
    //   添加
    addmembers () {
      console.log('与承租户同户籍成员')
      var member = this.zichandetail.members
      console.log(member)
      //   var length = member.length
      this.zichandetail.members.push(
        {
          name: '',
          tel: ''
        })
    },
    //   删除
    async delmembers (index, row) {
      console.log(row.id)
      var id = row.id
      var that = this
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      // 点击确定的操作(调用接口)
        var hasmembers = that.zichandetail.members
        for (var i = 0; i < hasmembers.length; i++) {
          if (row.id === hasmembers[i].id) {
            that.zichandetail.members.splice(i, 1)
            this.$message({ message: '删除成功', duration: 2000, type: 'success' })
            // deleteSub
          }
        }
      }).then(() => {
        const { data: res } = this.$http.get('/contactUs/deleteSub/' + id)
        console.log(res.data)
      }).catch(() => {
        // 点取消的提示

      })
    },
    //   获取数据回显
    async datalist () {
      const { data: res } = await this.$http.post('/contactUs/QueryContact')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      console.log(res.data)
      this.list_first = res.data
      this.zichandetail.members = res.data.subTelList
    },
    // 提交事件
    async submitForm (formName) {
      console.log(this.zichandetail.members)
      this.list_first.subTelList = this.zichandetail.members
      const { data: res } = await this.$http.post('/contactUs/UpdateContact', this.list_first)
      console.log(res.data)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.datalist()
    },
    // 重置事件
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
    // 移除表单项事件
    // removeEnvironmentForm (item) {
    //   var index = this.environmentForm.items.indexOf(item)
    //   if (index !== -1) {
    //     this.environmentForm.items.splice(index, 1)
    //   }
    //   console.log(this.environmentForm)
    // },
    // // 添加表单项事件
    // addEnvironmentForm () {
    //   this.environmentForm.items.push({
    //     name: '',
    //     tel: '',
    //     key: Date.now()
    //   })
    // }
  }

}
</script>

<style  scoped>
/* 图片上传 start */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 图片上传 结束 end */
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #00BFBF;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black!important
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.footer{
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
  align-items: center;
}

/deep/.ant-form input[type='file']{
 display: none!important;
}
.header_3{
    width: 100%;
    display: flex;
}
.header_4{
    width: 400px;
    display: flex;
}
.width_100{
    width: 45%;
    padding-left: 5%;
    box-sizing: border-box;
}
.width_30{
      display: flex;
      float: right;
      justify-content:flex-end;
}
.width_30 :last-child{
    margin-left: 10%;
}
/deep/.ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 30px;
    width: 220px;
}
/deep/ .ant-form-item-label{
    width: 100px;
}
.he_2{
    width: 100%;
    /* display: flex; */
}
/deep/ .anticon {
    color: white!important;
}
/deep/.reset.ant-btn .anticon {
    color: black!important;
}
/deep/.ant-pagination-item-link  .anticon{
  color: black!important;
}
/deep/.el-upload-list--picture .el-upload-list__item-thumbnail{
    width: auto;
}
[data-v-555b28ad] .ant-input-affix-wrapper .ant-input:not(:last-child){
        width: 292px;

}
 /deep/ .header_3 .anticon{
    color: rgba(0, 0, 0, 0.25)!important;
}
/deep/.ant-input-affix-wrapper .ant-input-suffix {
right: -140px;
}
.fle_row{
    display: flex;
}
/deep/[data-v-1282d48d] .ant-input-affix-wrapper .ant-input:not(:last-child){
    width: 500px;
}
.float_right{
    /* float: right; */
}

  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
</style>

<template>
  <div>
      <div>
            <a-card>
                <div class="border-left">抵押登记/登记列表
                </div>
            </a-card>
        <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row :gutter="24" >
             <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                 <a-form-model-item label="抵押主题:"  >
                     <a-input v-model="formData.title" allowClear />
                 </a-form-model-item>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                 <a-form-model-item label="状态:">
                    <a-select
                        :allowClear="true"
                        style="width: 100%"
                        placeholder="全部"
                        v-model="formData.status">
                        >
                        <a-select-option v-for="(role,index) in cityList" :key="index" :value="role.type"  >
                        {{ role.label }}
                        </a-select-option>
                    </a-select>
                 </a-form-model-item>
              </div>
            </a-col>
            <a-col class="gutter-row"  :span="7">
                    <div class="gutter-box style_80">
                      <a-button class="search"  icon="plus" @click="showModal(0,0)"  >
                            新增
                  </a-button>
                   <a-button class="chongzhi"  icon="DownOutlined"  >
                            <a href="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/09/15/0c2b5c06-ca99-499e-b21a-4e082cbaaf64.xlsx" target="_blank" rel="noopener noreferrer">模板下载</a>
                  </a-button>
                      <a-button class="search" icon="search" @click="getarticleList">
                          搜索
                      </a-button>
                      <a-button   class="reset" icon="redo" @click="reset">
                          重置
                      </a-button>
                    </div>
              </a-col>
          </a-row>
            <a-row :gutter="24" >
                <a-col class="gutter-row marginTop" :span="24">
                    <a-table
                       rowKey='id'
                      :columns="columns"
                      :pagination= pagination
                      :data-source="articleList"
                      bordered
                      @change="handleTableChange"
                    >
                   <span style="color:#70B603" slot="status" slot-scope="status">{{ status == 3?'已撤销':(status==1?'已发布':'草稿')}} </span>
                    <template slot="action" slot-scope="data">
                        <div class="actionSlot">
                        <a-button class="bgc_none ed"  icon='form' @click="showModal(data.id,data)" >
                            编辑
                        </a-button>
                      <div>
                        <a-popconfirm placement="left" ok-text="发布" cancel-text="不发布" @confirm="confirm(data.id,1)">
                            <template slot="title">
                            <p>发布此登记列表</p>
                            <p>发布此登记列表后直接适应到平台</p>
                            </template>
                            <a-button class="cao" icon='container'  v-if="data.status==2" >
                                草稿
                            </a-button>
                        </a-popconfirm>
                        <a-popconfirm placement="left" ok-text="撤销" cancel-text="不撤销" @confirm="confirm(data.id,3)">
                          <template slot="title">
                            <p>是否撤销?</p>
                            <p>撤销后将取消此登记列表发布</p>
                          </template>
                          <a-button class="bgc_none che" icon='rollback' v-if="data.status==1" >
                            撤销
                          </a-button>
                        </a-popconfirm>
                        <a-popconfirm placement="left" ok-text="发布" cancel-text="不发布" @confirm="confirm(data.id,1)">
                          <template slot="title">
                            <p>发布此登记列表</p>
                            <p>发布此登记列表后直接适应到平台</p>
                          </template>
                          <a-button class="bgc_none fabu" icon='upload'  v-if="data.status==3"   >
                              发布
                          </a-button>
                        </a-popconfirm>
                      </div>
                        <a-popconfirm placement="left" ok-text="删除" cancel-text="不删除" @confirm="deleteChange(data.id)">
                          <template slot="title">
                            <p>是否删除此登记列表?</p>
                            <p>删除成功后将不能恢复</p>
                          </template>
                          <a-button class="bgc_none delete" icon="delete">
                              删除
                          </a-button>
                        </a-popconfirm>
                      </div>
                    </template>
                    </a-table>
                </a-col>
              </a-row>
         </a-form-model>
          <!-- 模态框 start -->
          <a-modal
             width='50%'
              :title="title"
              :visible="visible"
              :confirm-loading="confirmLoading"
              fill="#4a72b7"
              @cancel="handleCancel"
              cancelText="关闭"
              wrapClassName="ant-modal-cust-warp"
              style="top:5%;height: 85%;overflow-y: hidden"
            >
                  <a-form-model
                        ref='formModel' :rules="rules" :model="formModel"   :label-col="labelCol" :wrapper-col="wrapperCol"
                        >
                          <a-form-model-item label="抵押主题:" prop="title">
                            <a-input v-model="formModel.title" />
                          </a-form-model-item>
                          <a-form-model-item label="来源:" prop="source">
                            <a-input v-model="formModel.source" />
                          </a-form-model-item>
                          <a-form-model-item label="生效日期:"  prop='forceTime' >
                             <a-date-picker v-model="formModel.forceTime"  @change="onChangeb" />
                          </a-form-model-item>
                           <a-form-model-item label="附件:" v-if="title=='新增抵押登记'" prop="accessoryList" >
                            <div class="input_hidden" >
                                <el-upload
                                    :before-upload="beforeAvatarUpload"
                                    :limit="limitNum"
                                    class="upload-demo"
                                    :action="upUrl"
                                    :on-success="handleUploadSuccess"
                                    :on-preview="handlePreview"
                                    :on-remove="handleRemove"
                                    :file-list="fileList"
                                     >
                                    <el-button   size="small" type="primary">点击上传</el-button>
                                </el-upload>
                               <span class="span_down"> （请先下载模板 然后再上传数据）</span>
                            </div>
                            </a-form-model-item>
                            <a-form-model-item label="抵押内容:" v-if="title=='编辑抵押登记'" prop="accessoryList" >
                                  <a-row :gutter="24" >
                <a-col class="gutter-row marginTop" :span="24">
                    <!-- <a-table
                       rowKey='id'
                      :columns="columns1"
                      :data-source="formModel.list"
                      bordered
                    >
                   <span style="color:#70B603" slot="status" slot-scope="status">{{ status == 3?'已撤销':(status==1?'已发布':'草稿')}} </span>

                    </a-table> -->
                     <el-table
                          :data="formModel.list"
                          border
                          stripe
                          style="width: 100%"
                        >
                          <el-table-column
                            prop="moDate"
                            center
                            width="120"

                            label="抵押登记日期"
                          >
                          </el-table-column>
                          <el-table-column
                            width="140"
                            prop="certificateId"
                            label="登记证号"
                          >
                          </el-table-column>
                          <el-table-column prop="moName"
                            width="120" label="抵押人">
                          </el-table-column>
                          <el-table-column prop="mortgagee"
                            width="120" label="抵押权人">
                          </el-table-column>
                          <el-table-column prop="guarantee" label="抵押物">
                          </el-table-column>
                          <el-table-column prop="amount" label="数额">
                          </el-table-column>
                          <el-table-column
                            prop="moDead"
                            width="300"
                            label="债务人履行债务的期限"
                          >
                          </el-table-column>
                        </el-table>
                </a-col>
              </a-row>
                            </a-form-model-item>
                  </a-form-model>
                  <template slot="footer">
                        <div  class="footer">
                              <a-button   type='primary' style="background:#70B603;color:white"> <a
                                class="class_dot text_de"
                                :href="formModel.fileUrl"
                                :download="formModel.fileUrl"
                                >下载</a
                            ></a-button>
                            <a-button  @click="submodal2(2)" style="background:#F59A23;color:white">草稿</a-button>
                            <a-button  @click="submodal2(1)" type="primary">发布</a-button>
                            <a-button  @click="handleCancel">取消</a-button>
                        </div>
                   </template>
          </a-modal>
          <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
import { MessageBox } from 'element-ui'
import { Url } from '../../plugin/http'
// import Edia from '../editor/index.vue'
const columns = [
  {
    title: '序号',
    dataIndex: 'rowId',
    align: 'center',
    key: 'rowId'
  },
  {
    title: '抵押主题',
    width: '35%',
    dataIndex: 'title',
    align: 'center',
    key: 'title'
  },
  {
    title: '点击数',
    dataIndex: 'upDown',
    align: 'center',
    key: 'upDown'
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '发布时间',
    width: '15%',
    dataIndex: 'createTime',
    align: 'center',
    key: 'createTime'
  },
  {
    title: '操作',
    align: 'center',
    width: '25%',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]
const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
//   components: { Edia },
  data () {
    return {
      limitNum: 1,
      disabled: false,
      idEditor: 0,
      titleArticle: '',
      source: '天津市北方公证处',
      moDate: '',
      formData3rules: {},
      //   el add
      lanmuId: '',
      formData3: {
        powerAttrList: [
          {
            moDate: '',
            moName: '',
            certificateId: '',
            mortgagee: '',
            guarantee: '',
            amount: '',
            moDead: ''
          }
        ]
      },
      workForm: {
        moDate: '',
        moName: '',
        certificateId: '',
        mortgagee: '',
        guarantee: '',
        amount: '',
        moDead: ''
      },
      //   el add
      addObj: {
        title: '',
        source: '天津市北方公证处',
        moDate: '',
        moName: '',
        certificateId: '',
        mortgagee: '',
        guarantee: '',
        amount: '',
        moDead: '',
        moType: 0,
        status: 0,
        id: 0
      },
      // 图片上传 start
      previewVisible: false,
      previewImage: '',
      fileList: [],
      // 图片上传 end
      defaultfile: [],
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      articleList: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      labelCol2: { span: 4 },
      wrapperCol: { span: 14 },
      wrapperCol2: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formData: {
        endTime: '',
        startTime: '',
        title: '',
        status: 0,
        logType: 0
      },
      form2: {
        info: ''
      },
      ids: [],
      // 登记列表的消息
      formModel: {
        title: '',
        source: '天津市北方公证处',
        stId: 0,
        forceTime: '',
        file: '',
        list: [],
        fileUrl: ''
      },
      optionList: [
      ],
      cityList: [{
        type: 0,
        label: '全部'
      },
      {
        type: 1,
        label: '发布'
      }, {
        type: 3,
        label: '撤销'
      }
      ],
      rules: {
        title: [{ required: true, message: '抵押主题不能为空', trigger: 'blur' }],
        source: [{ required: true, message: '来源不能为空', trigger: 'blur' }],
        forceTime: [{ required: true, message: '生效日期不能为空', trigger: 'blur' }]
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      },
      upUrl: Url + '/file/uploadFiles'
    }
  },
  created () {
    this.getarticleList()
    this.addDate()
    this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    addDate () {
      const nowDate = new Date()
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      }
      const newmonth = date.month > 10 ? date.month : '0' + date.month
      const day = date.date > 10 ? date.date : '0' + date.date
      this.formModel.forceTime = date.year + '-' + newmonth + '-' + day
    },
    //  图片上传 start
    beforeAvatarUpload (file) {
      // const isJPG = file.type === 'image/jpeg';
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 2

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!extension && !extension2) {
        this.$message.error('只能上传 XLS 或者 xlsx 文件!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      // return isJPG && isLt2M;
      return (extension || extension2) && isLt2M
    },
    //  图片上传 start
    handleUploadSuccess (file) {
      console.log(this.fileList)
      if (
        this.title === '编辑表单') {
        MessageBox.confirm('此操作会覆盖以前的附件？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.fileList = file.data.fileUrl
          })
          .catch(() => {
            console.log(this.fileList)
          })
      } else {
        this.fileList = file.data.fileUrl
      }
    },
    handleRemove (file, fileList) {
      console.log(file)
      console.log(file, fileList)
      console.log(this.formModel.accessoryList)
    },
    handlePreview (file) {
      console.log(file)
      console.log(this.data.file)
    },
    // 图片上传 end
    //   修改抵押登记内容
    editorden () {
      this.disabled = true
    },
    // 新增编辑抵押登记
    async submodal2 (e) {
      if (this.formModel.title === '') {
        return this.$message.error('请填写抵押主题')
      }
      if (this.formModel.forceTime === '') {
        return this.$message.error('生效日期不能为空')
      }
      if (this.title === '新增抵押登记') {
        if (this.fileList.length === 0) {
          return this.$message.error('附件不能为空')
        }
        this.$refs.formModel.validate(async valid => {
        // console.log(valid)
          this.visible = true
          if (!valid) return this.$message.error('输入非法数据，请重新输入')
          // 解构赋值，把data赋值给res { data: res }
          const { data: res } = await this.$http.post('/backMortgageRegistration/insertMortgageRegistration', {
            title: this.formModel.title,
            source: this.formModel.source,
            forceTime: this.formModel.forceTime,
            stId: e,
            file: this.fileList
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.msg)
        //   this.getarticleList()
        })
      } else {
        this.$refs.formModel.validate(async valid => {
        // console.log(valid)
          if (!valid) return this.$message.error('输入非法数据，请重新输入')
          // 解构赋值，把data赋值给res { data: res }
          const { data: res } = await this.$http.post('/backMortgageRegistration/updateMortgageRegistration', {
            title: this.formModel.title,
            source: this.formModel.source,
            forceTime: this.formModel.forceTime,
            stId: e,
            id: this.idEditor
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.getarticleList()
        })
      }
      this.visible = false
    },
    showModal (id, data) {
      if (id === 0) {
        console.log(data)
        this.title = '新增抵押登记'
        // this.addObj = data
      } else {
        this.title = '编辑抵押登记'
        console.log(data)
        this.idChanges(id)
      }
      this.visible = true
    },
    async idChanges (id) {
      this.idEditor = id
      const { data: res } = await this.$http.get('/backMortgageRegistration/findByMortgageRegistrationId/' + id)
      this.formModel = res.data
      //   this.formModel.title = res.data.title
    //   this.formModel.source = res.data.source
    //   this.formData3.powerAttrList = res.data.list
    },
    // 模态框 star
    // 取消
    handleCancel (e) {
      this.formModel.title = ''
      this.formModel.source = '天津市北方公证处'
      this.forceTime = ''
      this.file = ''
      this.list = ''
      this.fileUrl = ''
      this.visible = false
      this.disabled = false
    },
    // 模态框 end
    // this.$router.push('admin/index')
    // 更改登记列表状态
    async confirm (e, statu) {
      const { data: res } = await this.$http.post('/backMortgageRegistration/updateMortgageRegistrationStatu', {
        id: e,
        status: parseInt(statu)
      })
      this.$message.success(res.msg)
      this.getarticleList()
    },

    handleUser (key) {
    },

    // 删除
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backMortgageRegistration/DeleteInMortgageRegistration', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.ids = []
      this.getarticleList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.title = ''
      this.formData.status = 0
      this.getarticleList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (date, dateString) {
      console.log(dateString)
      this.addObj.moDate = dateString
    },
    onChangeb (date, dateString) {
      console.log(dateString)
      this.formModel.forceTime = dateString
    //   var len = this.formData3.powerAttrList.length - 1
    //   console.log(this.formData3.powerAttrList[len])
    //   this.formData3.powerAttrList[len].moDate = dateString
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async getarticleList () {
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      const { data: res } = await this.$http.post('/backMortgageRegistration/queryMortgageRegistration', {
        formData: this.formData,
        pageData: this.pageData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.articleList = res.data.list
      this.pagination.total = res.data.total
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.getarticleList()
    },
    // 抵押须知
    async optionListChange () {
      const { data: res } = await this.$http.get('/backMortgageRegistration/mortgageNotice')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res.data.deptList)
      this.formData2 = res.data
      this.disabled = false
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    }
  }
}
</script>

<style  scoped>
/* 图片上传 start */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 图片上传 结束 end */
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #70B603;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.footer{
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
  align-items: center;
}

.fromList{
    width:62.5%;
    border-bottom: 1px solid #f0f0f0;
    padding: 10px;
    margin-left: 13%;
}
.chongzhi[data-v-1c4d6706] {
    background-color: #00BFBF;
    color: white;
}
.he_80{
    height: 300px;
}
/deep/.ant-form input[type='file']{
 display: none!important;
}
/deep/.el-upload-list--picture .el-upload-list__item-thumbnail{
    width: auto;
}
/deep/.el-upload-list__item.is-success .el-upload-list__item-status-label{
    display: none;
}
.chongzhi a{
    color: white;
}
.span_down{
    color: red;
}
  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
</style>

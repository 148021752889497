import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugin/http'
import './plugin/antui'
import './assets/css/style.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import 'default-passive-events'
// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
//
Vue.use(ElementUI)
// 将富文本编辑器，注册为全局可用的组件
Vue.use(VueQuillEditor)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

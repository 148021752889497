<template>
<div>
      <div class="my" >
       <img  src="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/07/08/3c42e630-6f6c-4d4a-aad7-8b0dd19a9f00.png" alt="">
           <span >
           {{realName}}</span>
        <img class="upLoad" @click="showDrawer"   src="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/07/08/eca9ac72-775f-4d81-9817-4b4f1f459feb.png" alt="">
       <!-- <a-button   @click="loginout" style="margin-left:10px" type="danger">
        退出
       </a-button> -->
     </div>
       <el-dialog class="dif"   center :visible.sync="visible">

            <div slot="footer" class="dialog-footer">
             <div class="flex_row">
                <img src="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/07/08/c6b5377a-aec2-4e43-8efb-2dc6a13481b3.png" alt="">
                <p  @click="innerVisible = true">修改密码</p>
                 <!-- <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="innerVisible = true">打开内层 Dialog</el-button> -->
            </div>
            <div class="flex_row">
                <img src="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/07/08/3e7c49ab-487d-4095-a5e5-7693bbeea61d.png" alt="">
                <p @click="loginout"> 退出登录</p>
            </div>
            </div>
        </el-dialog>
        <!-- <el-dialog
            width="30%"
            class="dialog2"
            title="用户修改密码"
            :visible.sync="innerVisible"
            >
              <el-form ref="loginFromRef" :rules='rules' :model="formdata" class="loginForm">

                    <el-form-item prop='passWord'>
                        <el-input  v-on:keyup.enter='login' show-password v-model="formdata.passWord" placeholder="新密码" type='passWord'><a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" /></el-input>
                    </el-form-item>
                    <el-form-item prop='repassWord'>
                        <el-input  v-on:keyup.enter='login' show-password v-model="formdata.repassWord" placeholder="再次输入密码" type='passWord'><a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" /></el-input>
                    </el-form-item>
                    <el-form-item class="loginbtn">
                    <a-button type='primary'  @click="login">提交</a-button>
                    </el-form-item>
              </el-form>
            </el-dialog> -->
                  <a-modal
                   width="40%"
                        class="dialog2"
                        title="用户修改密码"
                        :visible="innerVisible"
                        :confirm-loading="confirmLoading"
                        fill="#4a72b7"
                        @ok="login"
                        @cancel="handleCancel"
                        ok-text="确认"
                        cancelText="取消"
                    >
        <a-form-model  ref="loginFromRef" :rules='rules' :model="formdata" class="loginForm" :label-col="labelCol"
                      :wrapper-col="wrapperCol">
          <a-form-model-item label="新密码：" prop='passWord'>
            <a-input  v-on:keyup.enter='login' show-password v-model="formdata.passWord" placeholder="新密码" type='passWord'/>
          </a-form-model-item>
     <a-form-model-item label="再次输入密码" prop='passWord'>
            <a-input  v-on:keyup.enter='login' show-password v-model="formdata.repassWord" placeholder="再次输入密码" type='passWord'/>
          </a-form-model-item>

        </a-form-model>
      </a-modal>
</div>
</template>
<script>
export default {
  data () {
    return {

      // 模态框 start
      ModalText: 'Content of the modal',
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      // 模态框 end
      realName: '',
      visible: false,
      innerVisible: false,
      formdata: {
        passWord: '',
        repassWord: ''
      },
      rules: {
        passWord: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码必须在6到20个字符之间', trigger: 'blur' }
        ],
        repassWord: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码必须在6到20个字符之间', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.realName = window.sessionStorage.getItem('realName')
  },
  methods: {
    login () {
      if (this.formdata.passWord !== this.formdata.repassWord) {
        return this.$message.error('两次密码不一致')
      }
      this.$refs.loginFromRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.get('/backUser/updatePassword/' + this.formdata.passWord
        )
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.visible = false
        this.innerVisible = false
      })
    },
    handleOk () {},
    // 取消
    handleCancel (e) {
      this.visible = false
      this.innerVisible = false
    //   this.formModel.passWord = ''
    //   this.formModel.repassWord = ''
    },
    close () {

    },
    afterVisibleChange (val) {
      console.log('visible', val)
    },
    showDrawer () {
      this.visible = true
    },
    onClose () {
      this.visible = false
    },
    loginout () {
      console.log('dwdwd')
      window.sessionStorage.clear('token')
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
 .my{
   color: white;
   font-size: 16px;
   cursor: pointer;
 }
 .my img{
   width: 50px;
   padding-right: 10px;
   box-sizing: border-box;
 }
 .my img.upLoad{
   width: 35px;
   padding-right: 10px;
   padding-left: 10px;
   box-sizing: border-box;
 }
 .flex_row{
   cursor: pointer;
   display: flex;
   height: 30px;
   color: #000;
   line-height: 20px;
 }
 .flex_row img{
     margin-top: 3px;
   width: 25px;
   height: 15px;
   padding-right: 10px;
   box-sizing: border-box;
 }
 /deep/.dif{
    margin-top: 0px;
     width: 300px!important;
     left: 86%!important;
 }
 /deep/.el-dialog{
     margin: auto;
     margin-top: 19%!important;
 }
 /deep/.dif .el-dialog__headerbtn{
     display: none!important;
 }
 /deep/ .el-dialog--center .el-dialog__body{
     display: none;
 }
  /deep/ .dif .el-dialog__header{
     display: none!important;
 }

 /deep/.dif .el-dialog__footer{
     padding-top: 20px;
     box-sizing: border-box;
 }
 /deep/.dialog2{
     top: -20%;
 }
 /deep/.dialog2 .el-dialog__header{
     padding-top: 10px;
     box-sizing: border-box;
 }
  /deep/.dialog2  .el-dialog__headerbtn{
    top: 4px;
 }
  /deep/.dialog2 .el-dialog__body{
 padding: 10px 40px;
 }
 /deep/.ant-modal-header{
     background-color: #4a72b7;

     }
 /deep/.ant-modal-title {
    color: white;
    font-weight: 800;
}
</style>

<template>
   <a-layout-sider class="container" breakpoint='lg' v-model="collapsed">
        <div class="log" v-if="!collapsed">
           <img src="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/06/30/05bcdf38-82df-45b9-ad8b-026fc230eb3f.png" alt="">
           <h1>天津市北方公证处官网管理后台</h1>
        </div>
        <div class="log" v-else>
            <h1>北方后台</h1>
        </div>

    <!-- :defaultSelectedKeys="['index']" -->
    <!-- :defaultSelectedKeys="defaultSelectedKeys" -->
    <!-- :selectedKeys="[this.$route.path]" -->
    <a-menu
      :open-keys="openKeys"
      :defaultSelectedKeys="defaultSelectedKeys"
      class="menu_height"
      @click="goToPage"
      @openChange="onOpenChange"
      :mode="mode"
      :theme="theme"
    >
      <a-menu-item key="index"  >
        <a-icon type="home" />
          首页
        </a-menu-item>
       <a-menu-item key="articlerotation"  >
            <a-icon type="picture" />
          首页轮播
        </a-menu-item>
        <a-menu-item key="article"  >
            <a-icon type="form" />
          文章管理
        </a-menu-item>
        <a-sub-menu key="thematicmanagement" >
            <span slot="title"><a-icon  type="container" /><span>产品管理</span></span>
            <a-menu-item key="externalLinks">
            <a-icon type="dribbble" /><span>友情链接</span>
            </a-menu-item>
            <!-- AuditOutlined  -->
             <a-menu-item key="onlineNotarization">
            <a-icon type="audit" /><span>线上公证事项</span>
            </a-menu-item>
            <a-menu-item key="hotProducts">
            <a-icon type="fire" /><span>热点产品</span>
            </a-menu-item>
        </a-sub-menu>

        <!-- 1 -->
      <a-sub-menu key="sub1"> <span slot="title"> <a-icon type="appstore" /><span>机构设置</span></span>
        <a-menu-item key="jigou">
          <a-icon type="form" /><span>机构简介</span>
        </a-menu-item>
        <a-menu-item key="materialrotation"  >
            <a-icon type="crown" />
             荣誉奖励
        </a-menu-item>
        <!-- <a-menu-item key="certificationProcess"  >
            <a-icon type="audit" />
             办证流程
        </a-menu-item> -->
      </a-sub-menu>
      <!-- 1 -->
      <a-sub-menu key="sub2"  class="width_bgc">
        <span slot="title"><a-icon type="android" /><span>便民服务</span></span>
        <a-menu-item key="formDownload">
        <a-icon type="cloud-download" /><span>表单下载</span>
        </a-menu-item>
        <a-menu-item key="onlineService">
        <a-icon type="message" /><span>投诉与建议</span>
        </a-menu-item>
        <!-- switcher -->
         <a-menu-item key="personnelRotation">
        <a-icon type="usergroup-delete" /><span>公证精英</span>
        </a-menu-item>
        <a-menu-item key="contactUs">
          <a-icon type="phone" /><span>联系我们</span>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="sub3"  class="width_bgc">
        <span slot="title"><a-icon type="audit" /><span>抵押登记</span></span>
        <a-menu-item key="mortgagregistration">
        <a-icon type="file-text" /><span>登记列表</span>
        </a-menu-item>
        <a-menu-item key="mortgageNotice">
       <a-icon type="security-scan" /><span>抵押须知</span>
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item key="Bookingmanagement">
         <a-icon type="solution" />
          预约管理
      </a-menu-item>
      <a-sub-menu key="sub4"  v-if="role==2" >
         <span slot="title"><a-icon type="setting" /><span>系统设置</span></span>
        <a-menu-item key="usermanagement">
        <a-icon type="usergroup-delete" /><span>用户管理</span>
        </a-menu-item>
        <a-menu-item key="rolemanagement">
        <a-icon type="project" /><span>日志管理</span>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
    </a-layout-sider>
</template>

<script>
export default {
  data () {
    return {
      defaultSelectedKeys: [this.$route.path],
      role: 0,
      rootSubmenuKeys: ['sub1', 'sub2', 'sub3', 'sub4'],
      collapsed: false,
      openKeys: [''],
      mode: 'inline',
      theme: 'light'
    }
  },
  created () {
    this.role = window.sessionStorage.getItem('role')
  },
  methods: {
    onOpenChange (openKeys) {
      if (openKeys.length !== 0) {
        this.openKeys = [openKeys[1]]
      } else {
        this.openKeys = ['']
      }
    //   const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
    //   if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //     this.openKeys = openKeys
    //   } else {
    //     this.openKeys = latestOpenKey ? [latestOpenKey] : []
    //   }
    },
    goToPage (item) {
      console.log(item)
      this.$router.push('/admin/' + item.key).catch((err) => err)
    },
    changeMode (checked) {
      this.mode = checked ? 'vertical' : 'inline'
    },
    changeTheme (checked) {
      this.theme = checked ? 'dark' : 'light'
    }
  }
}
</script>

<style scoped >
/deep/ .headerBtn[data-v-54c38a05]{
    height: 200px!important;
}
.container{
    height: 100%;
}
.log{
    height: 32px;
    margin: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    width: 425px;
}
.log img{
  margin: 5px;
  width: 40px;
}
.log h1{
    margin-top: 20px;
    color: white;
    z-index: 9999;
    font-size: 25px;
}
.ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
    font-size: 20px;
}
.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title{
    font-size: 16px;
}
.ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span {
font-size: 16px;
}
.menu_height{
    background-color:#1E4264;
    color: white;
}
/deep/ul{
    background-color: #4c7ada!important;
    color: white!important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #2455bb;
    color: white;
}

.ant-menu-submenu-selected{
    color: white;
}
 .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
    background-image:  linear-gradient(to right, rgba(255, 255, 255), rgba(255, 255, 255))!important;
}
.ant-menu-submenu-arrow{
    background-color: #fff !important;
    background-image: none!important;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
      background-color: none!important;
    background-image: none!important;
}
.ant-menu-submenu>>>
/deep/.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background-image: none!important;
}
</style>

<template>
  <div>
      <a-card>
          内容管理/产品管理
        </a-card>
      <div>
        <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row :gutter="24" >
             <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                 <a-form-model-item label="产品名称:" >
                     <a-input v-model="formData.prName" allowClear />
                 </a-form-model-item>
              </div>
            </a-col>
            <a-col class="gutter-row"  :span="3">
                    <div class="gutter-box style_80">
                      <a-button class="search" icon="search" @click="getProctureList">
                          搜索
                      </a-button>
                      <a-button   class="reset" icon="redo" @click="reset">
                          重置
                      </a-button>
                    </div>
            </a-col>
          </a-row>
         </a-form-model>
        <div class="bgc_white  marginTop">
            <!-- <a-row :gutter="24" >
             <a-col class="gutter-row style_70" :span="6">
                 <a-button class="search"  icon="plus" @click="showModal(0,0)">
                          新增
                 </a-button>
                  <a-button class="prink_search" icon="delete" @click="deleteList">
                          批量删除
                 </a-button>
                  <a-button   :disabled="!hasSelected" :loading="loading" @click="start">
                          清空
                    </a-button>
             </a-col>
            </a-row> -->
            <a-row :gutter="24" >
              <a-col class="gutter-row marginTop" :span="24">
                <!-- 已选多少个 start -->
                 <!-- <div style="margin-bottom: 16px">

                    <span style="margin-left: 8px">
                      <template v-if="hasSelected">
                        {{ `Selected ${selectedRowKeys.length} items` }}
                      </template>
                    </span>
                  </div> -->
                    <!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
                  <a-table
                     rowKey='id'
                    :columns="columns"
                    :pagination= pagination
                    :data-source="userlist"
                     bordered
                     @change="handleTableChange"
                  >
                  <div slot="prInfo" slot-scope="prInfo">
                      <span v-html="prInfo"></span>
                  </div>
                  <div slot="prUrl" slot-scope="prUrl">
                     <img class="prUrl"   :src="prUrl" alt="">
                  </div>
                  <div slot="prPic"   class="bgc_image"  slot-scope="prPic">
                      <img :src="prPic" class="bgc_img" alt="">
                  </div>
                  <!-- roleIdName -->
                  <span slot="userStatu" slot-scope="userStatu">{{ userStatu == 0?'正常':'禁用'}} </span>
                   <template slot="action" slot-scope="data">
                      <div class="actionSlot">
                      <a-button  class="search"     icon='edit' @click="showModal(1,data)" >
                          编辑
                      </a-button>
                       <a-button  class="chongzhi"  icon='edit' @click="showModal(2,data)" >
                          查看
                      </a-button>
                    </div>
                   </template>
                  </a-table>
              </a-col>
            </a-row>
        </div>
        <!-- 模态框 start -->
         <a-modal
           width='83%'
              :title="title"
              :visible="visible"
              :confirm-loading="confirmLoading"
              fill="#4a72b7"
              @cancel="handleCancel"
              cancelText="关闭"
              wrapClassName="ant-modal-cust-warp"
              style="top:5%;height: 85%;overflow-y: hidden"
          >
            <a-form-model ref='ruleForm' :rules="ruleFormrules" :model="formModel" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="产品名称" prop="prName">
                  <a-input  v-if="title=='编辑产品管理'" v-model="formModel.prName" />
                  <div  v-if="title=='查看产品管理'" >
                      {{formModel.prName}}
                  </div>
                </a-form-model-item>
                <a-form-model-item label="产品介绍" prop="prIntr">
                    <a-input  v-if="title=='编辑产品管理'" v-model="formModel.prIntr" />
                  <div  v-if="title=='查看产品管理'" >
                      {{formModel.prIntr}}
                  </div>
                </a-form-model-item>
                <a-form-model-item label="产品详情" prop="prInfo">
                     <Edia v-if="title=='编辑产品管理'" v-model="formModel.prInfo"></Edia>
                     <div v-if="title=='查看产品管理'" class="bg_border"  v-html="formModel.prInfo">
                     </div>
                </a-form-model-item>
                <a-form-model-item label="产品二维码" prop="prUrl">
                    <div v-if="title=='编辑产品管理'">
                        <a-upload
                        name="file"
                        :multiple="false"
                        :action="upUrl"
                        :defaultFileList='fileList'
                        @change="upChange"
                            >
                        <img class="prUrl"   :src="formModel.prUrl" alt="">
                        <br>
                    </a-upload>
                    </div>
                    <div v-else>
                        <img class="prUrl"   :src="formModel.prUrl" alt="">
                    </div>
                </a-form-model-item>
                <a-form-model-item label="产品背景图" prop="prPic">
                    <div v-if="title=='编辑产品管理'" >
                         <a-upload
                        name="file"
                        :multiple="false"
                        :action="upUrl"
                        :defaultFileList='fileList1'
                        @change="upChange1"
                            >
                        <img :src="formModel.prPic" class="bgc_img" alt="">
                        <br>
                    </a-upload>
                    </div>
                    <div v-else>
                        <img :src="formModel.prPic" class="bgc_img" alt="">
                    </div>
                </a-form-model-item>
            </a-form-model>
             <template slot="footer">
                <div class="footer" v-if="title=='编辑产品管理'">
                    <a-button type='primary' @click="submodal(0)">确定</a-button>
                    <a-button @click="submodal(2)">取消</a-button>
                </div>
                <div class="footer" v-if="title=='查看产品管理'">
                </div>
              </template>
         </a-modal>
        <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
import { Url } from '../../plugin/http'
import Edia from '../editor/index.vue'
const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    width: '4%',
    align: 'center',
    key: 'id'
  },
  {
    title: '产品名称',
    dataIndex: 'prName',
    align: 'center',
    key: 'prName'
  },
  {
    title: '产品介绍',
    dataIndex: 'prIntr',
    align: 'center',
    key: 'prIntr'
  },
  {
    title: '产品二维码',
    dataIndex: 'prUrl',
    key: 'prUrl',
    align: 'center',
    scopedSlots: {
      customRender: 'prUrl'
    }
  },
  {
    title: '产品背景图',
    dataIndex: 'prPic',
    align: 'center',
    key: 'prPic',
    scopedSlots: {
      customRender: 'prPic'
    }
  },
  {
    title: '操作',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]
const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  components: { Edia },
  data () {
    return {
      // 图片上传 start
      previewVisible: false,
      previewImage: '',
      fileList: [],
      fileList1: [],
      // 图片上传 end
      defaultFileList: [],
      ruleFormrules: {
        prName: [
          { required: true, message: '请输入登录名称', trigger: 'blur' }
        ],
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入电子邮箱', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ],
        userStatu: [
          { required: true, message: '请选择用户状态', trigger: 'blur' }

        ],
        roleId: [
          { required: true, message: '请选择用户角色', trigger: 'blur' }
        ],
        deptId: [
          { required: true, message: '请选择所属部门', trigger: 'blur' }
        ]
      },
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      userlist: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      formModel: {
        id: 0,
        prName: '',
        prIntr: '',
        prInfo: '',
        prUrl: '',
        prPic: ''
      },
      statusList: [
        {
          type: 0,
          label: '正常'
        }, {
          type: 1,
          label: '禁用'
        }
      ],
      roleIdList: [
        {
          type: 2,
          label: '公证员'
        }, {
          type: 3,
          label: '管理员'
        }
      ],
      optionList: [
      ],
      cityList: [
        {
          type: 0,
          label: '正常'
        }, {
          type: 1,
          label: '禁用'
        }
      ],

      formData: {
        prName: ''
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      },
      upUrl: Url + '/file/uploadFiles'
    }
  },
  created () {
    this.getProctureList()
    this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    //  图片上传 start
    upChange (info) {
      console.log(info)
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        this.$message.success('成功')
        console.log(info)
        const imgUrl = info.file.response.data.fileUrl
        console.log(imgUrl)
        this.formModel.prUrl = imgUrl
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    upChange1 (info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        this.$message.success('成功')
        console.log(info)
        const imgUrl = info.file.response.data.fileUrl
        console.log(imgUrl)
        this.formModel.prPic = imgUrl
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    // 重置密码
    async confirm (e) {
      console.log(e)
      var id = e
      const { data: res } = await this.$http.get('/backUser/resetPassword/' + id)
      this.$message.success(res.msg)
    },
    // 获取部门列表
    async optionListChange () {
      const { data: res } = await this.$http.get('/backUser/insertData')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      // console.log(res.data.deptIdList)
      this.optionList = res.data.deptList
    },
    handleUser (key) {
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    },
    // 模态框 start
    showModal (id, data) {
      if (id === 1) {
        this.title = '编辑产品管理'
      } else {
        this.title = '查看产品管理'
      }
      this.formModel = data
      this.visible = true
    },
    // 编辑
    async submodal (e) {
      if (e === 0) {
        this.$refs.ruleForm.validate(async valid => {
          // console.log(valid)
          if (!valid) return this.$message.error('输入非法数据，请重新输入')
          // 解构赋值，把data赋值给res { data: res }
          const { data: res } = await this.$http.post('/backProduct/updateProdut', {
            prName: this.formModel.prName,
            prIntr: this.formModel.prIntr,
            prInfo: this.formModel.prInfo,
            prUrl: this.formModel.prUrl,
            prPic: this.formModel.prPic,
            id: this.formModel.id
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.visible = false
          this.getProctureList()
        })
      } else {
        this.visible = false
      }
    },
    // 取消
    handleCancel (e) {
      console.log(e)
      this.visible = false
      //   this.formModel.roleId = ''
    //   this.$refs.ruleForm.resetFields()
    },
    // 模态框 end
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backUser/DeleteInBatches', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.userlist = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)

      this.getProctureList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.formData.prName = ''
      this.formData.userStatu = 0
      this.getProctureList()
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed:', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async getProctureList () {
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      const { data: res } = await this.$http.post('/backProduct/queryProduct', {
        formData: this.formData,
        pageData: this.pageData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      console.log(res)
      if (res.data == null) {
        this.userlist = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userlist = res.data.list
      this.pagination.total = res.data.total
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.getProctureList()
    }
  }
}
</script>

<style     scoped>
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #00BFBF;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.bgc_image{
    width: 100%;
    height: 100%;
    background-color: #4a72b7;
}
.bgc_img{
    width: 100px;
    background-color: #4a72b7;
}
.prUrl{
    width: 100px;
}
.bg_border{
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
}

  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
</style>

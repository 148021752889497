<template>
  <a-layout  class="container" id="components-layout-demo-custom-trigger">
    <Nav></Nav>
    <a-layout>
      <a-layout-header class="headerBtn">
          <Header></Header>
      </a-layout-header>
      <a-layout-content class="content_detail"
        :style="{ margin: '24px 16px'     }"
      >
      <!-- 清理缓存 :key='$route.path' -->
        <router-view :key='$route.path'></router-view>
      </a-layout-content>
      <!-- <a-layout-footer>
        <Footer></Footer>
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>
<script>
import Nav from '../components/Nav.vue'
import Header from '../components/Header.vue'
// import Footer from '../components/Footer.vue'
export default {
  components: {
    Nav,
    Header
    // Footer
  },
  data () {
    return {
      collapsed: false
    }
  },
  methods: {
    loginout () {
      window.sessionStorage.clear('token')
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
/* #components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
} */
.container{
  height: 100%;
}
.headerBtn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.ant-layout-header {
    background-image: linear-gradient(to right, #4c7ada, #4c7ada);
}
.logo{
    display: flex;
    justify-content: space-between;
}
.ant-layout-sider{
    background: #4c7ada;
}
.flexspace{
    display: flex;
    justify-content: space-between;
}
.headerBtn{
    position: fixed;
    width: 90%;
    z-index: 999;
}
.content_detail.ant-layout-content{
    z-index: 0;
    margin-top: 80px!important;
}
</style>

<template>
  <div>
      <div>
        <a-card>
            <div class="border-left">
               便民服务/投诉与建议
            </div>
        </a-card>
        <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
              <div class="he_2">
                <div class="header_3">
                     <a-form-model-item label="姓名:"  >
                        <a-input v-model="formData.makeName" allowClear />
                     </a-form-model-item>
                    <a-form-model-item label="电话:">
                       <a-input v-model="formData.makePhone" allowClear />
                    </a-form-model-item>
                    <div class="width_100">
                        <a-form-model-item label="创建时间:">
                            <a-range-picker
                            :show-time="{ format: 'HH:mm' }"
                            format="YYYY-MM-DD"
                            :placeholder="['开始时间', '结束时间']"
                             :value="createValue"
                            @change="onChange"
                            @ok="onOk"
                            />
                        </a-form-model-item>
                                </div>
                            </div>
                             <div class="width_30">
                                <a-button class="search" icon="search" @click="onlineList">
                                        搜索
                                    </a-button>
                                    <a-button   class="reset" icon="redo" @click="reset">
                                        重置
                                    </a-button>
                            </div>
                            </div>

                        </a-form-model>
        <div class="bgc_white  marginTop">
            <a-row :gutter="24" >
              <a-col class="gutter-row marginTop" :span="24">
                  <a-table

                     rowKey='id'
                    :columns="columns"
                    :pagination= pagination
                    :data-source="articleList"
                     bordered
                     @change="handleTableChange"
                  >
                   <!-- roleName -->
                  <span slot="makeStatus" slot-scope="makeStatus">{{ makeStatus == true?'已回复':'未回复'}} </span>

                   <template slot="action" slot-scope="data">
                      <div class="actionSlot">
                        <a-button  class="fabu"  icon='search' @click="showModal(data.id,data)" >
                          查看
                        </a-button>
                    <div>
                        <a-button  class="yihui" icon='tags'  v-if="data.makeStatus==true" >
                            已回复
                        </a-button>
                        <a-popconfirm placement="left" ok-text="回复" cancel-text="不回复" @confirm="confirm(data.id,1)">
                        <template slot="title">
                          <p>是否回复</p>
                          <p>可以通过邮箱或者电话的方式回复</p>
                        </template>
                        <a-button class="huifu" icon='tag'  v-if="data.makeStatus==false" >
                            回复
                        </a-button>
                      </a-popconfirm>
                     </div>
                      <a-popconfirm placement="left" ok-text="删除" cancel-text="不删除" @confirm="deleteChange(data.id)">
                        <template slot="title">
                          <p>是否删除此投诉与建议?</p>
                          <p>删除成功后将不能恢复</p>
                        </template>
                         <a-button class="delete" icon="delete">
                            删除
                         </a-button>
                      </a-popconfirm>

                    </div>
                   </template>
                  </a-table>
              </a-col>
            </a-row>
        </div>
         <!-- 模态框 start -->
         <a-modal
            width='40%'
            title="查看信息"
            :visible="visible"
            :confirm-loading="confirmLoading"
            fill="#4a72b7"
            @cancel="handleCancel"
            cancelText="取消"
          >
              <a-form-model ref='ruleForm' :rules="rules" :model="formModel" :label-col="labelCol" :wrapper-col="wrapperCol">
                  <a-form-model-item label="姓名：" prop="makeName">
                    <a-input disabled v-model="formModel.makeName" />
                  </a-form-model-item>
                   <a-form-model-item label="联系电话：" prop='makePhone' >
                    <a-input disabled type='number' v-model="formModel.makePhone" />
                  </a-form-model-item>
                  <a-form-model-item label="电子邮箱："  prop='makeEmail'>
                    <a-input disabled v-model="formModel.makeEmail" />
                  </a-form-model-item>
                 <a-form-model-item label="留言内容"  prop='message'>
                    <a-input disabled v-model="formModel.message" />
                  </a-form-model-item>
                     <a-form-model-item label="咨询事项"  prop='message'>
                    <a-input disabled v-model="formModel.items" />
                  </a-form-model-item>
                 <a-form-model-item label="上传材料"  prop='accessoryList'>
                    <el-upload
                            class="upload-demo"
                            :action="upUrl"
                            :on-success="handleUploadSuccess"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            >
                        </el-upload>
                </a-form-model-item>
                  <a-form-model-item label="选择下载材料"  prop='noUrlArray'>
                         <a-button   class="chongzhi "  v-for="(item,index) in fileList" :key="index" >
                            <a :href="item.url"  :download="item.name" >下载{{item.name}}</a>
                         </a-button>
                    </a-form-model-item>
                </a-form-model>

         </a-modal>
        <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
import { Url } from '../../plugin/http'
const columns = [
  {
    title: '序号',
    dataIndex: 'rowId',
    align: 'center',
    key: 'rowId'
  },
  {
    title: '姓名',
    dataIndex: 'makeName',
    align: 'center',
    key: 'makeName'
  },
  {
    title: '联系电话',
    dataIndex: 'makePhone',
    align: 'center',
    key: 'makePhone'
  },
  {
    title: '电子邮箱',
    dataIndex: 'makeEmail',
    key: 'makeEmail',
    align: 'center',
    scopedSlots: {
      customRender: 'makeEmail'
    }
  },
  {
    title: '咨询事项',
    dataIndex: 'items',
    align: 'center',
    key: 'items'
  },
  {
    title: '创建时间',
    width: '15%',
    dataIndex: 'createTime',
    align: 'center',
    key: 'createTime'
  },
  {
    title: '回复状态',
    width: '15%',
    dataIndex: 'makeStatus',
    align: 'center',
    key: 'makeStatus',
    scopedSlots: {
      customRender: 'makeStatus'
    }
  },
  {
    title: '操作',
    width: '25%',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  data () {
    return {
      createValue: [],
      fileListUrl: '',
      filelistName: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 图片上传 start
      previewVisible: false,
      previewImage: '',
      fileList: [],
      // 图片上传 end
      defaultFileList: [],
      title: '',
      // 列表数据
      articleList: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      // 文章的消息
      formModel: {
        items: '',
        materialArray: [],
        makeName: '',
        makePhone: '',
        makeEmail: '',
        message: '',
        id: 0
      },
      optionList: [
      ],
      cityList: [{
        type: 0,
        label: '全部'
      },
      {
        type: 1,
        label: '发布'
      }, {
        type: 3,
        label: '撤销'
      }
      ],
      rules: {
        journalismTitle: [{ required: true, message: '文章标题不能为空', trigger: 'blur' }],
        articleType: [{ required: true, message: '所属栏目不能为空', trigger: 'blur' }]

      },
      formData: {
        endTime: '',
        startTime: '',
        makeName: '',
        userStatu: 0
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      },
      upUrl: Url + '/file/uploadFiles'
    }
  },
  created () {
    this.onlineList()
    // this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    // 模态框 start
    showModal (id, data) {
      console.log(data)
      this.editorChange(id)
      this.visible = true
    },
    async  editorChange (id) {
      const { data: res } = await this.$http.get('/backServer/findByServerId/' + id)
      this.formModel = res.data
      this.fileList = res.data.accessoryList
      this.fileListUrl = res.data.accessoryList[0].url
      this.filelistName = res.data.accessoryList[0].name
    },
    // 取消
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
      this.formModel.accessoryList = []
      this.fileList = []
      this.fileListUrl = ''
      this.filelistName = ''
    },
    // 模态框 end
    //  图片上传 start
    handleUploadSuccess (file) {
      console.log(file.data.fileName)
      console.log(file.data.fileUrl)
      console.log(this.fileList)
      var arr = [{
        name: '',
        url: ''
      }]
      if (this.fileList.length === 0) {
        arr[0].name = file.data.fileName
        arr[0].url = file.data.fileUrl
        if (arr[0].name) {
          this.fileList = arr
          console.log(this.fileList)
        }
      } else {
        this.fileList[0].name = file.data.fileName
        this.fileList[0].url = file.data.fileUrl
      }

      console.log(this.fileList)
    },
    handleRemove (file, fileList) {
      console.log(file)
      console.log(file, fileList)
      console.log(this.formModel.accessoryList)
    },
    handlePreview (file) {
      console.log(file)
      console.log(this.data.fileList)
    },
    // 图片上传 end
    // this.$router.push('admin/index')
    // 更改文章状态
    async confirm (e, statu) {
      const { data: res } = await this.$http.post('/backServer/updateServerStatus', {
        id: e,
        status: 1
      })
      this.$message.success(res.msg)
      this.onlineList()
    },

    handleUser (key) {
    },

    // 删除
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backServer/DeleteInServer', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.ids = []
      this.onlineList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.makeName = ''
      this.formData.makePhone = ''
      this.createValue = []

      this.onlineList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (value, dateString) {
      console.log(dateString)
      this.createValue = value
      this.formData.startTime = dateString[0]
      this.formData.endTime = dateString[1]
      console.log(this.formData.startTime)
      console.log(this.formData.endTime)
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async onlineList () {
      if (this.formData.makePhone) {
        if (!(/^1[3456789]\d{9}$/.test(this.formData.makePhone))) {
          // this.$notify.error({
          //   message: '手机号格式错误'
          // })
          return this.$message.error('手机号格式错误')
        }
      }
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      // this.$refs.loginFromRef.validate(async valid => {
      // console.log(valid)
      // if (!valid) return this.$message.error('输入非法数据，请重新输入')
      // 解构赋值，把data赋值给res { data: res }
      const { data: res } = await this.$http.post('/backServer/getServer', {
        formData: this.formData,
        pageData: this.pageData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      // console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.articleList = res.data.list
      this.pagination.total = res.data.total
      // console.log(res.data.token)
      // window.sessionStorage.getItem('token', res.data.token)
      // 直接连接到首页的index
      // this.$router.push('admin/index')
      // this.$router.push('admin/usermanagement')
      // })
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.onlineList()
    },
    // 获取部门列表
    async optionListChange () {
      const { data: res } = await this.$http.get('/backUser/insertData')
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res.data.deptList)
      this.optionList = res.data.deptList
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    }
  }
}
</script>

<style  scoped>
/* 图片上传 start */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 图片上传 结束 end */
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #00BFBF;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.footer{
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
  align-items: center;
}
/deep/.ant-form input[type='file']{
 display: none!important;
}
/deep/.el-upload-list__item.is-success .el-upload-list__item-status-label{
    display: none;
}
/deep/.ant-form input[type='file']{
 display: none!important;
}

/deep/.ant-form input[type='file']{
 display: none!important;
}
/deep/.el-upload-list__item.is-success .el-upload-list__item-status-label{
    display: none;
}
/deep/.el-upload{
    display: none!important;
}

.header_3{
    width: 75%;
    display: flex;
}
.he_2{
    width: 100%;
    display: flex;
}
.width_100{
    width: 45%;
    padding-left: 5%;
    box-sizing: border-box;
}
.width_30{
    margin-top: 5px;
    padding-left: 10%;
    box-sizing: border-box;
      display: flex;
      float: right;
      justify-content:flex-end;
}
.width_30 :last-child{
    margin-left: 10%;
}
/deep/.ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 30px;
    width: 220px;
}
/deep/ .ant-form-item-label{
    width: 100px;
}
  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
.yihui{
    color: #06c05a ;
    border: 1px solid #06c05a;
}
.huifu{
    color: #2ca9d0 ;
    border: 1px solid #2ca9d0;
}
</style>

<template>
  <div>
     <a-card>
          <a-row>
            <a-col :span="24">
               <div class="border-left">
                    用户使用情况统计
               </div>
               <div class="margin_top">
                  <a-row   type="flex" justify="space-around">
                  <a-col :span="4">
                     <div class="bgc_index">
                       <div class="logo_index">
                           <img src="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/10/18/d12f7175-682f-44ad-9130-89644d785bae.png" alt="">
                       </div>
                       <div class="flex_right">
                          <div>{{workForm.browseCount}}</div>
                          <div>浏览人数</div>
                       </div>
                     </div>
                  </a-col>
                  <a-col :span="4">
                     <div class="bgc_index">
                       <div class="logo_index">
                           <img src="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/10/18/ab829227-2f87-4c27-a155-b37f47120276.png" alt="">
                       </div>
                       <div class="flex_right">
                          <div>{{workForm.reservationCount}}</div>
                          <div>预约人数</div>
                       </div>
                     </div>
                  </a-col>
                  <a-col :span="4">
                     <div class="bgc_index">
                       <div class="logo_index">
                           <img src="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/10/18/0ef247fd-501d-478f-bdd5-72136d01677a.png" alt="">
                       </div>
                       <div class="flex_right">
                          <div>{{workForm.messageCount}}</div>
                          <div>留言人数</div>
                       </div>
                     </div>
                  </a-col>
                  <a-col :span="4">
                     <div class="bgc_index">
                       <div class="logo_index">
                           <img src="http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/10/18/0ef247fd-501d-478f-bdd5-72136d01677a.png" alt="">
                       </div>
                       <div class="flex_right">
                          <div>{{workForm.formDownCount}}</div>
                          <div>表单下载</div>
                       </div>
                     </div>
                  </a-col>
                </a-row>
               </div>
            </a-col>
          </a-row>
     </a-card>
     <a-card class="margin_Card">
          <a-row :gutter="20">
            <a-col :span="24">
               <div class="border-left">
                  发布文章数量
               </div>
               <div class="margin_top">
                  <a-row :gutter="20" type="flex" justify="space-between">
                  <a-col :span="12">
                        <div class="thirdLine">
                           <div :id="'shape2'+projectId" style="width: 100%; height: 400px"></div>
                        <div>

                    </div>
                    </div>
                  </a-col>
                  <a-col   :span="11">
                        <div class="thirdLine">
                                <div class="hehehe" :id="'cir1'+projectId" style="width:100%; height: 400px"></div>
                    </div>
                  </a-col>

                </a-row>
               </div>
            </a-col>
          </a-row>
     </a-card>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import 'echarts/theme/macarons'
export default {
//   components: { Edia },
  data () {
    return {
      //   选择年
      year: '',
      projectId: 0,
      buildList: [],
      buildId: '',
      roomStatistics: [],
      firstX: [],
      firstX1: [],
      firstX2: [],
      newleasePriceCompareds: [],
      newleasePriceAverage: [],
      unitPrice: '',
      repealCount: '',
      average: '',
      parkingmonthlyIncome: '',
      carX: [],
      carX1: [],
      totalMoney: '',
      // 颜色
      color: [
        '#FDA954',
        '#5185F6',
        '#8168C9',
        '#74CEBB',
        '#999999',
        '#FA58A1'
      ],
      workForm: {
        browseCount: '',
        reservationCount: '',
        messageCount: '',
        formDownCount: ''
      },
      datachageList: []
    }
  },
  created () {
    this.indexList()
    // 柱安装图
  },
  mounted () {
    this.dataList()
    this.dataList2()
  },
  methods: {
    // 用户使用情况统计获取数据回显
    async indexList () {
      const { data: res } = await this.$http.get('/backUser/homeDataHead')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.workForm = res.data
    //   this.pagination.total = res.data.total
    },
    // 发布文章数量柱状图 start
    async dataList () {
      const { data: res } = await this.$http.get('/backUser/homeDataBody')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      for (var i = 0; i < res.data.length; i++) {
        this.datachageList.push(res.data[i].typeName)
      }
      // console.log(this.datachageList)
      this.setOtherData(res.data)
    },
    setOtherData (data) {
      //  年新增出租
      this.newleasePriceCompareds = data
      this.firstX = []
      this.firstX1 = []
      this.firstX2 = []
      for (var i = 0; i < this.newleasePriceCompareds.length; i++) {
        this.firstX.push(this.newleasePriceCompareds[i].typeName)
        this.firstX1.push(this.newleasePriceCompareds[i].resultCount)
        this.firstX2.push(this.newleasePriceCompareds[i].repealCount)
      }
      this.setChart2()
    },
    setChart2 () {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('shape2' + this.projectId))
      // 指定图表的配置项和数据
      var option = {
        color: this.color,
        title: {
          text: '文章状态对比',
          left: 'left',
          align: 'right'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['已发布', '已撤销'],
          right: 20
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'category',
          axisTick: { show: false },
          data: this.firstX,
          axisLabel: {
            interval: 0,
            rotate: 30,
            rich: true
          }
        },
        yAxis: {
          type: 'value',
          name: '篇数(篇)'
        },
        series: [
          {
            name: '已发布',
            type: 'bar',
            barGap: 0,
            data: this.firstX1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0.3, 0, 1, [
                  { offset: 0, color: '#5185F6' },
                  { offset: 1, color: '#d5d6e8' }

                ])
              }
            }
          },
          {
            name: '已撤销',
            type: 'bar',
            barGap: 0,
            data: this.firstX2,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0.3, 0, 1, [
                  { offset: 0, color: '#FDA954' },
                  { offset: 1, color: '#ffffff' }

                ])
              }
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    },
    // 发布文章数量柱状图 end
    // 获取饼状图
    async dataList2 () {
      const { data: res } = await this.$http.get('/backUser/homeDataBody')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)

      for (var j = 0; j < res.data.length; j++) {
        // console.log(res.data[j].resultCount)
        this.roomStatistics.push(res.data[j].resultCount)
      }
      // console.log(this.roomStatistics)
      this.setCircleChart()
    },
    //  饼状图
    setCircleChart () {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('cir1' + this.projectId))
      // 指定图表的配置项和数据
      var option = {
        color: this.color,
        tooltip: {},
        title: {
          text: '文章状态对比',
          subtext: '单位(篇)'
        },
        legend: {
          data: [
            '法律法规',
            '公证常识',
            '常见问题',
            '办证资费',
            '公证案例',
            '抵押登记'
          ],
          bottom: 0
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            textStyle: {
              fontWeight: 'bold',
              fontSize: 34,
              color: 'rgb(255,20,147)'
            },
            label: {
              normal: {
                formatter: '{b}:{c}篇({d}%)',
                textStyle: {
                  fontWeight: 'normal',
                  fontSize: 14
                }
              }
            },
            selectedMode: 'single',
            data: [
              {
                value: this.roomStatistics[0],
                name: '法律法规',
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0.3, 0, 1, [
                      { offset: 0, color: '#FDA954' },
                      { offset: 1, color: '#ffffff' }

                    ])
                  }
                }
              },
              {
                value: this.roomStatistics[1],
                name: '公证常识',
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0.6, 0.6, 0, 0, [
                      { offset: 0, color: '#5185F6' },
                      { offset: 1, color: '#d5d6e8' }

                    ])
                  }
                }
              },
              {
                value: this.roomStatistics[2],
                name: '常见问题',
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 1, 1, 0, [
                      { offset: 0, color: '#8168C9' },
                      { offset: 1, color: '#b59ef7' }
                    ])
                  }
                }
              },
              {
                value: this.roomStatistics[3],
                name: '办证资费',
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      { offset: 0, color: '#74CEBB' },
                      { offset: 1, color: '#9cf2e0' }
                    ])
                  }
                }
              },
              {
                value: this.roomStatistics[4],
                name: '公证案例',
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 1, 1, 0, [
                      { offset: 0, color: '#999999' },
                      { offset: 1, color: '#ffffff' }
                    ])
                  }
                }
              },
              {
                value: this.roomStatistics[5],
                name: '抵押登记',
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                      { offset: 0, color: '#FA58A1' },
                      { offset: 1, color: '#ffb1d4' }
                    ])
                  }
                }
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    }

  }
}
</script>

<style scoped>
/deep/.v-modal{
    z-index: 11!important;
    display: none;
}
 .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .margin_top{
   margin-top: 20px;
 }
 .margin_Card{
   margin-top: 20px;
 }
 .bgc_index{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 244px;
   height: 164px;
   /* height: 224px; */
   background-color: #FBFBFB;
   box-shadow: 5px 5px 5px #F8F8F8;
 }
 .logo_index{
   width: 100px;
   height: 65px;
   display: flex;
   justify-content: center;
   align-items: center;
   /* background-repeat: no-repeat; */
   /* background-size: 100% 100%; */
   /* background-image: url(http://depositcenter.oss-cn-beijing.aliyuncs.com/2021/07/06/e20493e3-bc7c-46b1-b78f-c8a3a707eb70.png); */
 }
 .logo_index img{
   width: 60px;
 }
 .flex_right{
   display: flex;
   flex-direction: column;
   /* padding: 15px 10px 30px; */
   box-sizing: border-box;
 }
 .flex_right :first-child{
    font-size: 39px;
    text-align: center;
    color: #333333;
 }
.flex_right :last-child{
    font-size: 16px;
    text-align: center;
    color: #333333;
 }
.title {
  color: #3497db;
  font-size: 16px;
  font-weight: 550;
  margin-bottom: 30px;
}
.title_sum {
  display: flex;
  align-items: center;
  .tit_year {
    color: #3497db;
    font-size: 16px;
    font-weight: 550;
    margin-right: 40px;
  }
  .year_right {
    font-size: 16px;
    display: flex;
    align-items: center;
    .year {
      margin-right: 10px;
    }
    .picker {
      margin-right: 20px;
    }
  }
}
.each {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  div:first-child {
    margin-right: 10px;
  }
}
.firstLine {
  display: flex;
  div {
    flex: 1;
    justify-content: center;
  }
}
.title {
  color: #3497db;
  font-size: 16px;
  font-weight: 550;
  margin-top: 30px;
}
.top {
  border-top: 1px solid #888;
  padding-top: 20px;
  box-sizing: border-box;
}
.secondLine {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  div:first-child {
    flex: 3;
    justify-content: center;
  }
  div:second-child {
    flex: 2;
    justify-content: center;
  }
}
.thirdLine {
  display: flex;
  margin-bottom: 40px;
  div:first-child {
    flex: 3;
  }
  div:second-child {
    flex: 1;
    text-align: center;
  }
}
/deep/.hehehe{
    background-color: #fff!important;
}
</style>

import Vue from 'vue'
import {
  Button,
  FormModel,
  Input,
  Icon,
  message,
  Layout,
  Menu,
  Row,
  Col,
  Form,
  Select,
  DatePicker,
  Table,
  Switch,
  Tag,
  Modal,
  Checkbox,
  Radio,
  ConfigProvider,
  Card,
  Popconfirm,
  Drawer,
  Upload,
  Tabs

} from 'ant-design-vue'
message.config({
  top: '100px',
  duration: 2,
  maxCount: 3
})
Vue.use(Button)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(Select)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(Switch)
Vue.use(Tag)
Vue.use(Modal)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(ConfigProvider)
Vue.use(Card)
Vue.use(Popconfirm)
Vue.use(Drawer)
Vue.use(Upload)
Vue.use(Tabs)
Vue.prototype.$message = message

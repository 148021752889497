<template>
  <div>
       <a-card>
            <div class="border-left">
                轮播管理/首页轮播
            </div>
       </a-card>
      <div>
        <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row :gutter="24" >
             <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                 <a-form-model-item label="图片名称:"  >
                     <a-input v-model="formData.title" allowClear />
                 </a-form-model-item>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="7">
              <div class="gutter-box style_80">
                   <a-button class="search"  icon="plus" @click="showModal(0,0)"  >
                                新增
                    </a-button>
                      <a-button class="search" icon="search" @click="getarticleList">
                          搜索
                      </a-button>
                      <a-button   class="reset" icon="redo" @click="reset">
                          重置
                      </a-button>

                    </div>
            </a-col>
          </a-row>
             <a-row :gutter="24" >
                <a-col class="gutter-row marginTop" :span="24">
                    <a-table
                      rowKey='id'
                      :columns="columns"
                      :pagination= pagination
                      :data-source="articleList"
                      bordered
                      @change="handleTableChange"
                    >
                   <span slot="pic" slot-scope="pic">
                  <img style="width:350px"  :src="pic" alt="">
                  </span>

                    <span slot="status" slot-scope="status">{{ status == true?'使用中':'未使用'}} </span>

                    <template slot="action" slot-scope="data">
                        <div class="actionSlot">
                        <a-button  class="bgc_none ed"  icon='form' @click="showModal(data.id,data)" >编辑</a-button>
                      <div>
                        <a-popconfirm placement="left" ok-text="发布" cancel-text="不发布" @confirm="confirm(data.id,1)">
                          <template slot="title">
                            <p>发布此图片</p>
                            <p>发布此图片后直接适应到平台</p>
                          </template>
                          <a-button class="bgc_none fabu" icon='upload'  v-if="data.status==2" >
                            发布
                          </a-button>
                        </a-popconfirm>
                        <a-popconfirm placement="left" ok-text="发布" cancel-text="不发布" @confirm="confirm(data.id,1)">
                          <template slot="title">
                            <p>发布此图片</p>
                            <p>发布此图片后直接适应到平台</p>
                          </template>
                          <a-button class="bgc_none fabu" icon='upload'  v-if="data.status==3" >
                            发布
                          </a-button>
                        </a-popconfirm>
                        <a-popconfirm placement="left" ok-text="撤销" cancel-text="不撤销" @confirm="confirm(data.id,3)">
                          <template slot="title">
                            <p>是否撤销?</p>
                            <p>撤销后将取消此图片发布</p>
                          </template>
                          <a-button class="bgc_none che" icon='rollback'  v-if="data.status==1"   >
                              撤销
                          </a-button>
                        </a-popconfirm>
                      </div>
                        <a-popconfirm placement="left" ok-text="删除" cancel-text="不删除" @confirm="deleteChange(data.id)">
                          <template slot="title">
                            <p>是否删除此轮播图?</p>
                            <p>删除成功后将不能恢复</p>
                          </template>
                          <a-button class="bgc_none delete" icon="delete">
                              删除
                          </a-button>
                        </a-popconfirm>
                      </div>
                    </template>
                    </a-table>
                </a-col>
              </a-row>
         </a-form-model>
          <!-- 模态框 start -->
          <a-modal class="modal"
              width='50%'
              :title="title"
              :visible="visible"
              :confirm-loading="confirmLoading"
              fill="#4a72b7"
              @cancel="handleCancel"
              cancelText="关闭"
              wrapClassName="ant-modal-cust-warp"
              style="top:5%;height: 85%;overflow-y: hidden"
            >
                <a-form-model ref='formModel' :rules="rules" :model="formModel"   :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="简介" prop="title">
                      <a-input v-model="formModel.title" />
                    </a-form-model-item>
                    <a-form-model-item label="附件:" prop="accessoryList" >
                    <div class="input_hidden" >
                        <el-upload
                            class="upload-demo"
                            :action="upUrl"
                            :on-success="handleUploadSuccess"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            list-type="picture">
                            <el-button   size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </div>
                    </a-form-model-item>
                      <a-form-model-item label="轮播图展示设备端:" prop="isWap">
                      <a-select
                          :allowClear="true"
                          style="width: 100%"
                          placeholder="===请选择==="
                          v-model="formModel.isWap">
                          >
                          <a-select-option v-for="(role,index) in isWapList" :key="index"  :value="role.id">
                            {{ role.roleName }}
                          </a-select-option>
                      </a-select>
                    </a-form-model-item>
                      <a-form-model-item label="轮播图展示位置:" prop="employ">
                      <a-select
                          :allowClear="true"
                          style="width: 100%"
                          placeholder="===请选择==="
                          v-model="formModel.employ">
                          >
                          <a-select-option v-for="(role,index) in roleList" :key="index"  :value="role.id">
                            {{ role.roleName }}
                          </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-form-model>
                     <template slot="footer">
                        <div class="footer">
                          <a-button  @click="submodal(2)" style="background:#F59A23;color:white">草稿</a-button>
                          <a-button  @click="submodal(1)" type="primary">发布</a-button>
                          <a-button  @click="handleCancel">取消</a-button>
                        </div>
                      </template>
          </a-modal>
          <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
import { Url } from '../../plugin/http'
const columns = [
  {
    title: '图片名称',
    dataIndex: 'title',
    align: 'center',
    key: 'title'
  },
  {
    title: '图片',
    width: '35%',
    dataIndex: 'pic',
    align: 'center',
    key: 'pic',
    scopedSlots: {
      customRender: 'pic'
    }
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '上传时间',
    width: '15%',
    dataIndex: 'createTime',
    align: 'center',
    key: 'createTime'
  },
  {
    title: '操作',
    width: '25%',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  data () {
    return {
      isWapList: [{
        id: 0,
        roleName: 'PC端轮播图'
      }, {
        id: 1,
        roleName: '移动端轮播图'
      }],
      roleList: [
        {
          id: 0,
          roleName: '普通轮播展示'
        }, {
          id: 1,
          roleName: '首页轮播展示'
        }],
      // 图片上传 start
      previewVisible: false,
      previewImage: '',
      fileList: [],
      // 图片上传 end
      defaultFileList: [],
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      articleList: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      // 文章的消息
      formModel: {
        isWap: undefined,
        employ: undefined,
        accessoryList: [],
        title: '',
        pic: '',
        status: 0,
        id: 0
      },
      optionList: [
      ],
      cityList: [{
        type: 0,
        label: '全部'
      },
      {
        type: 1,
        label: '发布'
      }, {
        type: 3,
        label: '撤销'
      }
      ],
      rules: {
        title: [{ required: true, message: '简介不能为空', trigger: 'blur' }],
        employ: [{ required: true, message: '请选择轮播图展示位置', trigger: 'blur' }],
        isWap: [{ required: true, message: '请选择轮播图展示设备端', trigger: 'blur' }]
      },
      formData: {
        endTime: '',
        startTime: '',
        title: ''
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      },
      upUrl: Url + '/file/uploadFiles'
    }
  },
  created () {
    this.getarticleList()
    this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    //  图片上传 start
    handleUploadSuccess (file) {
      console.log(file.data.fileName)
      console.log(file.data.fileUrl)
      console.log(this.fileList)
      console.log(this.formModel.accessoryList)
      var arr = [{
        name: '',
        url: ''
      }]
      if (this.fileList.length === 0) {
        console.log('111')
        arr[0].name = file.data.fileName
        arr[0].url = file.data.fileUrl
        if (arr[0].name) {
          this.fileList = arr
          console.log(this.fileList)
        }
      } else {
        console.log('222')
        this.fileList[0].name = file.data.fileName
        this.fileList[0].url = file.data.fileUrl
      }

      console.log(this.fileList)
    },
    handleRemove (file, fileList) {
      console.log(file)
      console.log(file, fileList)
      console.log(this.formModel.accessoryList)
    },
    handlePreview (file) {
      console.log(file)
      console.log(this.data.fileList)
    },
    // 图片上传 end
    // 模态框 start
    showModal (id, data) {
      if (id === 0) {
        this.title = '新增首页轮播'
        this.formModel.accessory = ''
        this.formModel.title = ''
        this.formModel.pic = ''
        this.formModel.status = ''
      } else {
        this.title = '编辑首页轮播'
        console.log(data)
        this.editorChange(id)
      }
      this.visible = true
    },
    // 根据id查询订单
    async  editorChange (id) {
      const { data: res } = await this.$http.get('/slideShow/querySlideShowById/' + id)
      this.formModel = res.data
      if (res.data.employ === false) {
        this.formModel.employ = 0
      } else {
        this.formModel.employ = 1
      }
      if (res.data.isWap === false) {
        this.formModel.isWap = 0
      } else {
        this.formModel.isWap = 1
      }
      //   isWap
      this.fileList = res.data.accessoryList
    },
    // 新增首页轮播
    async submodal (e) {
      if (e === 0) {
        return this.$message.success('请点击文件预览即可查看')
      } else if (e === 1) {
        this.formModel.status = 1
      } else if (e === 2) {
        this.formModel.status = 2
      }
      this.$refs.formModel.validate(async valid => {
      // console.log(valid)
        if (!valid) return this.$message.error('输入非法数据，请重新输入')
        if (this.title === '新增首页轮播') {
          const { data: res } = await this.$http.post('/slideShow/addSlideShow', {
            accessoryList: this.fileList,
            title: this.formModel.title,
            employ: this.formModel.employ,
            isWap: this.formModel.isWap,
            status: this.formModel.status
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.data)
          this.formModel.accessoryList = []
          this.fileList = []
          this.getarticleList()
          this.visible = false

          this.formModel.employ = undefined
          this.formModel.isWap = undefined
        } else {
          if (!this.fileList) {
            return this.$message.info('请上传轮播图片')
          }
          const { data: res } = await this.$http.post('/slideShow/doUpdateSlideShow', {
            accessoryList: this.fileList,
            title: this.formModel.title,
            employ: this.formModel.employ,
            isWap: this.formModel.isWap,
            status: this.formModel.status,
            id: this.formModel.id
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.visible = false
          this.formModel.accessoryList = []
          this.fileList = []
          this.getarticleList()

          this.formModel.employ = undefined
          this.formModel.isWap = undefined
          this.$message.success(res.data)
        }
      })
    },
    // 取消
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
      this.formModel.accessoryList = []
      this.fileList = []
      this.formModel.employ = ''
      this.formModel.isWap = ''
    },
    // 模态框 end
    // this.$router.push('admin/index')
    // 更改文章状态
    async confirm (e, statu) {
      const { data: res } = await this.$http.post('/backNotary/updateArticlePoll', {
        id: e,
        status: parseInt(statu)
      })
      this.$message.success(res.msg)
      this.getarticleList()
    },

    handleUser (key) {
    },

    // 删除
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backNotary/deleteInArticlePoll', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.ids = []
      this.getarticleList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.title = ''
      this.getarticleList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (value, dateString) {
      console.log(dateString)
      this.formData.startTime = dateString[0]
      this.formData.endTime = dateString[1]
      console.log(this.formData.startTime)
      console.log(this.formData.endTime)
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async getarticleList () {
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      const { data: res } = await this.$http.post('/slideShow/articlePolling', {
        formData: this.formData,
        pageData: this.pageData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.articleList = res.data.list
      this.pagination.total = res.data.total
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.getarticleList()
    },
    // 获取部门列表
    async optionListChange () {
      const { data: res } = await this.$http.get('/backArticle/insertArticleDate')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)

      this.optionList = res.data
    },
    // 部门下拉选择
    handleProperty (key) {
      console.log(key)
    }
  }
}
</script>

<style  scoped>
/* 图片上传 start */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 图片上传 结束 end */
.style_80{
  display: flex;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #00BFBF;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.footer{
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
  align-items: center;
}
.imgListr{
    width: 100px;
    /* height: 100px; */
}
/deep/.ant-form input[type='file']{
 display: none!important;
}
/deep/.el-upload-list--picture .el-upload-list__item-thumbnail{
    width: auto;
}
 .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
 /deep/.bgc_none .ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active{
     background-color: none !important;;
 }
</style>

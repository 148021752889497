<template>
  <div>
        <a-tabs type="card" @change="callback">
          <a-tab-pane key="1" tab="操作日志">
             <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="he_2">
                <div class="header_3">
                     <a-form-model-item label="系统模块:"  >
                          <a-input v-model="formData.makeName" allowClear />
                    </a-form-model-item>
                    <a-form-model-item label="操作人员:">
                        <a-input v-model="formData.makePhone" allowClear />
                    </a-form-model-item>
                    <a-form-model-item label="操作时间:">
                          <a-range-picker
                                :show-time="{ format: 'HH:mm' }"
                                format="YYYY-MM-DD HH:mm"
                                :value="createValue"
                                 :placeholder="['开始时间', '结束时间']"
                                @change="onChange"
                                @ok="onOk"
                              />
                      </a-form-model-item>
                </div>
                <div class="width_30">
                       <a-button class="search" icon="search" @click="getuserList">
                                搜索
                            </a-button>
                            <a-button   class="reset" icon="redo" @click="reset">
                                重置
                            </a-button>
                </div>
            </div>
         </a-form-model>
              <div class="bgc_white  marginTop">
            <!-- <a-row :gutter="24" >
             <a-col class="gutter-row " :span="6">
                  <a-button class="prink_search" icon="delete" @click="deleteList">
                          批量删除
                 </a-button>
             </a-col>
            </a-row> -->
            <a-row :gutter="24" >
              <a-col class="gutter-row marginTop" :span="24">
                  <a-table
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                     rowKey='id'
                    :columns="columns"
                    :pagination= pagination
                    :data-source="userlist"
                     bordered
                     @change="handleTableChange"
                  >
                  <span slot="sex" slot-scope="sex">{{sex=='男'?'男':'女'}} </span>
                  <!-- roleName -->
                  <span slot="stName" slot-scope="stName">{{ stName == '发布'?'已发布':'已撤回'}} </span>
                 <template slot="action" slot-scope="data">
                      <div class="actionSlot">
                        <a-button  class="chaxun"  icon='search' @click="showModal( data,1)" >
                          详情
                        </a-button>
                    </div>
                   </template>
                  </a-table>
              </a-col>
            </a-row>
        </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="登录日志">
               <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                  <div class="he_2">
                <div class="header_3">
                      <a-form-model-item label="登录地址:"  >
                          <a-input v-model="formData.makeName" allowClear />
                      </a-form-model-item>

                     <a-form-model-item label="用户名称:">
                         <a-input v-model="formData.makeName" allowClear />
                      </a-form-model-item>
                     <a-form-model-item label="登录时间:">
                          <a-range-picker
                                :show-time="{ format: 'HH:mm' }"
                                format="YYYY-MM-DD HH:mm"
                                :placeholder="['开始时间', '结束时间']"
                                @change="onChange"
                                @ok="onOk"
                              />
                      </a-form-model-item>
                </div>
                <div class="width_30">
                     <a-button class="search" icon="search" @click="getuserList">
                                搜索
                            </a-button>
                            <a-button   class="reset" icon="redo" @click="reset">
                                重置
                            </a-button>
                </div>
                  </div>
               </a-form-model>
              <div class="bgc_white  marginTop">
            <!-- <a-row :gutter="24" >
             <a-col class="gutter-row " :span="6">
                  <a-button class="prink_search" icon="delete" @click="deleteList">
                          批量删除
                 </a-button>
             </a-col>
            </a-row> -->
            <a-row :gutter="24" >
              <a-col class="gutter-row marginTop" :span="24">
                  <a-table
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                     rowKey='id'
                    :columns="columns1"
                    :pagination= pagination
                    :data-source="userlist"
                     bordered
                     @change="handleTableChange"
                  >
                  <span slot="sex" slot-scope="sex">{{sex=='男'?'男':'女'}} </span>
                  <!-- roleName -->
                  <span slot="stName" slot-scope="stName">{{ stName == '发布'?'已发布':'已撤回'}} </span>
                 <template slot="action" slot-scope="data">
                      <div class="actionSlot">
                        <a-button  class="chaxun"  icon='search' @click="showModal(data,2)" >
                          详情
                        </a-button>
                    </div>
                   </template>
                  </a-table>
              </a-col>
            </a-row>
        </div>
          </a-tab-pane>

        </a-tabs>
      <div>
        <!-- 模态框 start -->
         <a-modal
            width='40%'
            :title="title"
            :visible="visible"
            :confirm-loading="confirmLoading"
            fill="#4a72b7"
            @ok="handleOk"
            @cancel="handleCancel"
            ok-text="确认"
            cancelText="取消"
          >
              <a-form-model ref='ruleForm' :rules="rules" :model="formModel" :label-col="labelCol" :wrapper-col="wrapperCol">
                  <a-form-model-item v-if="title=='操作日志详情'" label="登录姓名：" prop="systemModule">
                    <a-input disabled v-model="formModel.systemModule" />
                  </a-form-model-item>
                  <a-form-model-item v-if="title=='操作日志详情'" label="操作类型" prop="operation">
                    <a-input disabled v-model="formModel.operation" />
                  </a-form-model-item>
                  <a-form-model-item v-if="title=='操作日志详情'"  label="操作人员" prop="userName">
                      <a-input disabled v-model="formModel.userName" />
                  </a-form-model-item>
                  <a-form-model-item v-if="title=='操作日志详情'" label="登录状态" prop="ip">
                    <a-input disabled v-model="formModel.ip" />
                  </a-form-model-item>
                  <a-form-model-item v-if="title=='操作日志详情'"  label="操作日期"  prop='operationTime'>
                    <a-input disabled v-model="formModel.operationTime" />
                  </a-form-model-item>
                  <a-form-model-item v-if="title=='操作日志详情'"   label="请求参数"  prop='reqParam'>
                    <a-input disabled type='textarea' v-model="formModel.reqParam" />
                  </a-form-model-item>
                     <a-form-model-item v-if="title=='操作日志详情'"   label="响应参数"  prop='respParam'>
                    <a-input disabled type='textarea' v-model="formModel.respParam" />
                  </a-form-model-item>

                   <a-form-model-item v-if="title=='登录日志详情'" label="用户名称" prop="systemModule">
                    <a-input disabled v-model="formModel.systemModule" />
                  </a-form-model-item>
                   <a-form-model-item v-if="title=='登录日志详情'" label="登录地址" prop="operation">
                    <a-input disabled v-model="formModel.operation" />
                  </a-form-model-item>
                   <a-form-model-item v-if="title=='登录日志详情'" label="登录地点" prop="userName">
                    <a-input disabled v-model="formModel.userName" />
                  </a-form-model-item>
                   <a-form-model-item v-if="title=='登录日志详情'" label="登录状态" prop="ip">
                    <a-input disabled v-model="formModel.ip" />
                  </a-form-model-item>
                    <a-form-model-item v-if="title=='登录日志详情'" label="浏览器" prop="operationTime">
                    <a-input disabled v-model="formModel.operationTime" />
                  </a-form-model-item>
                   <a-form-model-item v-if="title=='登录日志详情'" label="登录日期" prop="operationTime">
                    <a-input disabled v-model="formModel.operationTime" />
                  </a-form-model-item>
                   <a-form-model-item v-if="title=='登录日志详情'" label="登录地点" prop="reqParam">
                    <a-input disabled v-model="formModel.reqParam" />
                  </a-form-model-item>
                   <a-form-model-item v-if="title=='登录日志详情'" label="登录状态" prop="respParam">
                    <a-input disabled type='textarea' v-model="formModel.respParam" />
                  </a-form-model-item>
                </a-form-model>
         </a-modal>
        <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    align: 'center',
    key: 'id'
  },
  {
    title: '系统模块',
    dataIndex: 'systemModule',
    align: 'center',
    key: 'systemModule'
  },
  {
    title: '操作类型',
    dataIndex: 'operation',
    align: 'center',
    key: 'operation'
  },
  {
    title: '操作人员',
    dataIndex: 'userName',
    align: 'center',
    key: 'userName'
  },
  {
    title: '主机',
    dataIndex: 'ip',
    align: 'center',
    key: 'ip'
  },
  {
    title: '操作日期',
    dataIndex: 'operationTime',
    align: 'center',
    key: 'operationTime'
  },
  {
    title: '操作',
    width: '8%',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]
const columns1 = [
  {
    title: '序号',
    dataIndex: 'id',
    align: 'center',
    key: 'id'
  },
  {
    title: '用户名称',
    dataIndex: 'systemModule',
    align: 'center',
    key: 'systemModule'
  },
  {
    title: '登录地址',
    dataIndex: 'operation',
    align: 'center',
    key: 'operation'
  },
  {
    title: '登录地点',
    dataIndex: 'userName',
    align: 'center',
    key: 'userName'
  },
  {
    title: '登录状态',
    dataIndex: 'ip',
    align: 'center',
    key: 'ip'
  },
  {
    title: '浏览器',
    dataIndex: 'operationTime',
    align: 'center',
    key: 'operationTime'
  },
  {
    title: '登录日期',
    dataIndex: 'operationTime',
    align: 'center',
    key: 'operationTime'
  },
  {
    title: '操作',
    width: '8%',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]
const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  data () {
    return {
      createValue: [],
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      userlist: [],
      // 列
      columns,
      columns1,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      formModel: {
        systemModule: '',
        operation: '',
        userName: '',
        ip: '',
        operationTime: '',
        reqParam: [],
        respParam: {}
      },
      statusList: [
        {
          type: 0,
          label: '正常'
        }, {
          type: 1,
          label: '禁用'
        }
      ],
      roleList: [
        {
          type: 2,
          label: '公证员'
        }, {
          type: 3,
          label: '管理员'
        }
      ],
      optionList: [
      ],
      cityList: [
        {
          type: 0,
          label: '全部'
        },
        {
          type: 1,
          label: '发布'
        }, {
          type: 3,
          label: '撤销'
        }, {
          type: 2,
          label: '草稿'
        }
      ],
      formData: {
        endTime: '',
        startTime: '',
        makeName: '',
        makePhone: '',
        logType: 1
      },
      rules: {
        systemModule: [{ required: true, message: '登录姓名不能为空', trigger: 'blur' }],
        operation: [{ required: true, message: '真实姓名不能为空', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        dept: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        role: [{ required: true, message: '请选择角色', trigger: 'blur' }]
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      },
      tabsType: 1
    }
  },
  created () {
    this.callback(1)
    this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    callback (key) {
      console.log(key)
      this.tabsType = key
      this.getuserList(key)
    },
    // 重置密码
    async confirm (e) {
      console.log(e)
      var id = e
      const { data: res } = await this.$http.get('/backUser/resetPassword/' + id)
      this.$message.success(res.msg)
    },
    // 获取部门列表
    async optionListChange () {
      const { data: res } = await this.$http.get('/backUser/insertData')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res.data.deptList)
      this.optionList = res.data.deptList
    },
    handleUser (key) {
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    },
    // 模态框 start
    showModal (data, status) {
      if (status === 1) {
        this.title = '操作日志详情'
      } else {
        this.title = '登录日志详情'
        console.log(data)
      }
      this.formModel = data
      this.visible = true
    },
    // 新增
    async handleOk (e) {
    },
    // 取消
    handleCancel (e) {
      this.visible = false
    },
    // 模态框 end
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backLog/DeleteInLog', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.userlist = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.ids = []
      console.log(this.ids)
      this.onSelectChange(this.ids)
      this.$message.success(res.msg)
      this.getuserList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.createValue = []
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.makeName = ''
      this.formData.makePhone = ''
      this.getuserList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (value, dateString) {
      this.createValue = value
      console.log(dateString)
      this.formData.startTime = dateString[0]
      this.formData.endTime = dateString[1]
      console.log(this.formData.startTime)
      console.log(this.formData.endTime)
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async getuserList (e) {
      var path = ''
      if (e === 1) {
        path = '/backLog/queryOperationLog'
      } else {
        path = '/backLog/queryLoginLog'
      }
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      this.formData.logType = parseInt(this.tabsType)
      // this.$refs.loginFromRef.validate(async valid => {
      // console.log(valid)
      // if (!valid) return this.$message.error('输入非法数据，请重新输入')
      // 解构赋值，把data赋值给res { data: res }
      const { data: res } = await this.$http.post(path, {
        formData: this.formData,
        pageData: this.pageData
      })
      console.log(res)
      if (res.data == null) {
        this.userlist = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userlist = res.data.list
      this.pagination.total = res.data.total
      // console.log(res.data.token)
      // window.sessionStorage.getItem('token', res.data.token)
      // 直接连接到首页的index
      // this.$router.push('admin/index')
      // this.$router.push('admin/usermanagement')
      // })
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.getuserList()
    }
  }
}
</script>

<style     scoped>
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.fdfasaf{
  background-color: #F59A23;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #00BFBF;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
/deep/.ant-form-item-label {
width: 100px!important;
}
.he_2{
    width: 100%;
    display: flex;
}
.header_3{
    width: 75%;
    display: flex;
}
.width_100{
    width: 45%;
    padding-left: 5%;
    box-sizing: border-box;
}
.width_30{
    margin-top: 5px;
    padding-left: 10%;
    box-sizing: border-box;
      display: flex;
      float: right;
      justify-content:flex-end;
}
.width_30 :last-child{
    margin-left: 10%;
}

  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
 .chaxun{
     color: #fc9720;
     border: 1px solid #fc9720;
 }
</style>

<template>
 <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>

</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data () {
    return {
      locale: zhCN
    }
  }
}
</script>
<style  scoped>
 .v-modal{
    z-index: 11!important;
    display: none;
}
#app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
    background-image: none!important;;
}
.ant-menu-submenu-title >>>
    .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
        background: white!important;
    }
.ant-menu-submenu-title:hover>>>
    .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
        background: #fff!important;
    }

</style>

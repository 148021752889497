<template>
  <div>
      <a-card><div class="border-left">
          预约管理</div>
        </a-card>
      <div>
        <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row :gutter="24" >
             <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                 <a-form-model-item label="姓名:"  >
                     <a-input v-model="formData.userName" allowClear />
                 </a-form-model-item>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                <a-form-model-item label="创建时间:">
                    <a-range-picker
                          :show-time="{ format: 'HH:mm' }"
                          format="YYYY-MM-DD"
                          :value="createValue"
                          :placeholder="['开始时间', '结束时间']"
                          @change="onChange"
                          @ok="onOk"
                        />
                </a-form-model-item>
              </div>
            </a-col>
             <a-col class="gutter-row"  :span="7">
                    <div class="gutter-box style_80">
                      <a-button class="search" icon="search" @click="getuserList">
                          搜索
                      </a-button>
                      <a-button   class="reset" icon="redo" @click="reset">
                          重置
                      </a-button>
                    </div>
              </a-col>
          </a-row>
         </a-form-model>
        <div class="bgc_white  marginTop">
            <a-row :gutter="24" >
              <a-col class="gutter-row marginTop" :span="24">
                  <a-table
                     rowKey='id'
                    :columns="columns"
                    :pagination= pagination
                    :data-source="userlist"
                     bordered
                     @change="handleTableChange"
                  >
                  <span slot="sex" slot-scope="sex">{{sex=='男'?'男':'女'}} </span>
                  <span slot="stName" slot-scope="stName">{{ stName == '发布'?'已发布':'已撤回'}} </span>
                   <template slot="action" slot-scope="data">
                      <div class="actionSlot">
                      <a-button class="bgc_none fabu"  icon='search' @click="showModal(data.id,data)" >
                          查看
                      </a-button>
                      <a-popconfirm placement="left" ok-text="删除" cancel-text="不删除" @confirm="deleteChange(data.id)">
                        <template slot="title">
                          <p>是否删除此预约?</p>
                          <p>删除成功后将不能恢复</p>
                        </template>
                         <a-button class="bgc_none delete" icon="delete">
                            删除
                         </a-button>
                      </a-popconfirm>

                    </div>
                   </template>
                  </a-table>
              </a-col>
            </a-row>
        </div>
       <!-- 模态框 start -->
         <a-modal
            width='40%'
            title="查看信息"
            :visible="visible"
            :confirm-loading="confirmLoading"
            fill="#4a72b7"
            @cancel="handleCancel"
            cancelText="取消"
          >
             <a-form-model ref='ruleForm' :rules="rules" :model="formModel" :label-col="labelCol" :wrapper-col="wrapperCol">
                  <a-form-model-item label="姓名：" prop="name">
                    <a-input disabled v-model="formModel.name" />
                  </a-form-model-item>
                    <a-form-model-item label="性别：" prop="sex">
                    <a-radio-group disabled v-model="formModel.sex">
                      <a-radio value="女">
                        女
                      </a-radio>
                      <a-radio value="男">
                        男
                      </a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                   <a-form-model-item label="联系电话：" prop='phone' >
                    <a-input disabled  v-model="formModel.phone" />
                  </a-form-model-item>
                  <a-form-model-item label="电子邮箱："  prop='email'>
                    <a-input disabled v-model="formModel.email" />
                  </a-form-model-item>
                  <a-form-model-item label="预约事项"  prop='ceName'>
                    <a-input disabled v-model="formModel.ceName" />
                  </a-form-model-item>
                  <a-form-model-item label="证明使用地"  prop='ceAddr'>
                    <a-input disabled v-model="formModel.ceAddr" />
                  </a-form-model-item>
                  <a-form-model-item label="证书用途"  prop='ceUse'>
                    <a-input disabled v-model="formModel.ceUse" />
                  </a-form-model-item>
                    <a-form-model-item label="预约时间"  prop='createTime'>
                    <a-input disabled v-model="formModel.createTime" />
                  </a-form-model-item>
                 <a-form-model-item label="留言内容"  prop='noContext'>
                    <a-input disabled v-model="formModel.noContext" />
                  </a-form-model-item>
                 <a-form-model-item label="上传材料"  prop='noUrlArray'>
                    <!-- <img :src='item' style="width: 100px; height: 100px" v-for="(item,index) in formModel.noUrlArray" :key='index'  @click="handlerImg(item,index)"  alt="暂无"> -->
                    <el-upload v-if="fileList "
                            class="upload-demo"
                            :action="upUrl"
                            :on-success="handleUploadSuccess"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            >
                        </el-upload>
                    <div v-if="!fileList ">
                        未上传
                    </div>
                  </a-form-model-item>
                    <a-form-model-item v-if="fileList " label="选择下载材料"  prop='noUrlArray'>
                         <a-button   class="chongzhi "  v-for="(item,index) in fileList" :key="index" >
                            <a :href="item.url"  :download="item.name" >下载{{item.name}}</a>
                         </a-button>
                    </a-form-model-item>
                </a-form-model>
         </a-modal>
        <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
import { Url } from '../../plugin/http'
const columns = [
  {
    title: '序号',
    dataIndex: 'rowId',
    align: 'center',
    key: 'rowId'
  },
  {
    title: '姓名',
    dataIndex: 'name',
    align: 'center',
    key: 'name'
  },
  {
    title: '性别',
    dataIndex: 'sex',
    key: 'sex',
    align: 'center',
    scopedSlots: {
      customRender: 'sex'
    }
  },
  {
    title: '联系电话',
    dataIndex: 'phone',
    align: 'center',
    key: 'phone'
  },
  {
    title: '电子邮箱',
    dataIndex: 'email',
    align: 'center',
    key: 'email'
  },
  {
    title: '预约事项',
    dataIndex: 'ceName',
    align: 'center',
    key: 'ceName'
  },
  {
    title: '证书使用地',
    dataIndex: 'ceAddr',
    align: 'center',
    key: 'ceAddr'
  },
  {
    title: '证书用途',
    dataIndex: 'ceUse',
    align: 'center',
    key: 'ceUse'
  },
  {
    title: '预约创建时间',
    dataIndex: 'createTime',
    align: 'center',
    key: 'createTime'
  },
  {
    title: '预约办理时间',
    dataIndex: 'maCreate',
    align: 'center',
    key: 'maCreate'
  },
  {
    title: '留言内容',
    dataIndex: 'noContext',
    align: 'center',
    key: 'noContext'
  },
  {
    title: '操作',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  data () {
    // }
    // var checkMobile = (rule, value, cb) => {
    //   const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
    //   if (regMobile.test(value)) {
    //     // 合法的手机号码
    //     return cb()
    //   }
    //   cb(new Error('手机号码格式不正确'))
    // }
    // var checkEmail = (rule, value, cb) => {
    //   const regEmail = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
    //   if (regEmail.test(value)) {
    //     // 合法的邮箱
    //     return cb()
    //   }
    //   cb(new Error('请输入合法的邮箱'))
    // }
    return {
      createValue: [],
      upUrl: Url + '/file/uploadFiles',
      fileList: [],
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      userlist: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      formModel: {
        id: 0,
        name: '',
        sex: '男',
        phone: '',
        email: '',
        ceName: '',
        ceAddr: '',
        ceUse: '',
        dept: '',
        createTime: '',
        noContext: '',
        userStatu: 0
      },
      statusList: [
        {
          type: 0,
          label: '正常'
        }, {
          type: 1,
          label: '禁用'
        }
      ],
      roleList: [
        {
          type: 2,
          label: '公证员'
        }, {
          type: 3,
          label: '管理员'
        }
      ],
      optionList: [
      ],
      cityList: [
        {
          type: 0,
          label: '全部'
        },
        {
          type: 1,
          label: '发布'
        }, {
          type: 3,
          label: '撤销'
        }, {
          type: 2,
          label: '草稿'
        }
      ],

      formData: {
        endTime: '',
        startTime: '',
        userName: '',
        userStatu: 0
      },
      rules: {
        spTitle: [{ required: true, message: '登录姓名不能为空', trigger: 'blur' }],
        spId: [{ required: true, message: '真实姓名不能为空', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        dept: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        role: [{ required: true, message: '请选择角色', trigger: 'blur' }]
        // phone: [{
        //   validate: checkMobile, trigger: 'blur'
        // }],
        // emaill: [{
        //   validator: checkEmail, trigger: 'blur'
        // }]
      },
      pageData: {
        currentPage: 0,
        pageSize: 10
      }
    }
  },
  created () {
    this.getuserList()
    this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    handleUploadSuccess (file) {
      console.log(file.data.fileName)
      console.log(file.data.fileUrl)
      console.log(this.fileList)
      console.log(this.formModel.accessoryList)
      var arr = [{
        name: '',
        url: ''
      }]
      if (this.fileList.length === 0) {
        console.log('111')
        arr[0].name = file.data.fileName
        arr[0].url = file.data.fileUrl
        if (arr[0].name) {
          this.fileList = arr
          console.log(this.fileList)
        }
      } else {
        console.log('222')
        this.fileList[0].name = file.data.fileName
        this.fileList[0].url = file.data.fileUrl
      }

      console.log(this.fileList)
    },
    handleRemove (file, fileList) {
      console.log(file)
      console.log(file, fileList)
      console.log(this.formModel.accessoryList)
    },
    handlePreview (file) {
      console.log(file)
      console.log(this.data.fileList)
    },
    // 重置密码
    async confirm (e) {
      console.log(e)
      var id = e
      const { data: res } = await this.$http.get('/backUser/resetPassword/' + id)
      this.$message.success(res.msg)
    },
    // 获取部门列表
    async optionListChange () {
      const { data: res } = await this.$http.get('/backUser/insertData')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res.data.deptList)
      this.optionList = res.data.deptList
    },
    handleUser (key) {
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    },
    // 模态框 start
    showModal (id, data) {
      if (id === 0) {
      } else {
        console.log(data)
        this.editor(id)
        // this.formModel.dept = data.deptId
        // this.formModel.role = data.roleId
      }
      this.visible = true
    },
    // 根据id
    async editor (e) {
      var id = e
      const { data: res } = await this.$http.get('/backMakeAnAppointment/queryAppointmentById/' + id)
      console.log(res.data)
      this.formModel = res.data
      this.fileList = res.data.accessoryList
    },
    // 新增
    async handleOk (e) {
      var path = ''
      if (this.title === '新增用户') {
        path = '/backUser/insertUser'
      } else {
        path = '/backUser/updateBackUser'
      }
      this.$refs.ruleForm.validate(async valid => {
      // console.log(valid)
        if (!valid) return this.$message.error('输入非法数据，请重新输入')
        // 解构赋值，把data赋值给res { data: res }
        const { data: res } = await this.$http.post(path, {
          spTitle: this.formModel.spTitle,
          spId: this.formModel.spId,
          sex: this.formModel.sex,
          phone: this.formModel.phone,
          email: this.formModel.email,
          userStatu: this.formModel.userStatu,
          dept: this.formModel.dept,
          role: this.formModel.role,
          id: this.formModel.id
        })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.visible = false
        this.getuserList()
      })
    },
    // 取消
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
    },
    // 模态框 end
    deleteChange (e) {
    //   this.ids.push(e)
      this.deleteList(e)
    },
    // 批量删除
    async deleteList (e) {
      var id = e
      //   if (this.ids.length === 0) {
      //     return this.$message.error('还未选择数据无法删除')
      //   }
      //   for (var i = 0; i < this.ids.length; i++) {
      //     for (var j = i + 1; j < this.ids.length; j++) {
      //       if (this.ids[i] === this.ids[j]) {
      //         this.ids.splice(j, 1)
      //         j--
      //       }
      //     }
      //   }
      const { data: res } = await this.$http.get('/backMakeAnAppointment/deleteInMakeAnAppointment/' + id)
      console.log(res)
      if (res.data == null) {
        this.userlist = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getuserList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.userName = ''
      this.formData.userStatu = 0
      this.createValue = []
      this.getuserList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (value, dateString) {
      this.createValue = value
      console.log(dateString)
      this.formData.startTime = dateString[0]
      this.formData.endTime = dateString[1]
      console.log(this.formData.startTime)
      console.log(this.formData.endTime)
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async getuserList () {
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      // this.$refs.loginFromRef.validate(async valid => {
      // console.log(valid)
      // if (!valid) return this.$message.error('输入非法数据，请重新输入')
      // 解构赋值，把data赋值给res { data: res }
      const { data: res } = await this.$http.post('/backMakeAnAppointment/queryMakeAnAppointment', {
        formData: this.formData,
        pageData: this.pageData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      console.log(res)
      if (res.data == null) {
        this.userlist = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userlist = res.data.list
      this.pagination.total = res.data.total
      // console.log(res.data.token)
      // window.sessionStorage.getItem('token', res.data.token)
      // 直接连接到首页的index
      // this.$router.push('admin/index')
      // this.$router.push('admin/usermanagement')
      // })
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.getuserList()
    }
  }
}
</script>

<style     scoped>
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #00BFBF;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
/deep/.ant-form input[type='file']{
 display: none!important;
}

/deep/.ant-form input[type='file']{
 display: none!important;
}
/deep/.el-upload-list__item.is-success .el-upload-list__item-status-label{
    display: none;
}
/deep/.el-upload{
    display: none!important;
}
  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
</style>

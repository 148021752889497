<template>
  <div>
      <a-card>
          专题管理
        </a-card>
      <div>
        <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row :gutter="24" >
             <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                 <a-form-model-item label="专题名称:"  >
                     <a-input v-model="formData.userName" allowClear />
                 </a-form-model-item>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                 <a-form-model-item label="状态:">
                <a-select
                    :allowClear="true"
                    style="width: 100%"
                    placeholder="全部"
                    v-model="formData.userStatu">
                     >
                    <a-select-option v-for="(role,index) in cityList" :key="index"  :value="role.type" >
                      {{ role.label }}
                    </a-select-option>
                </a-select>
                 </a-form-model-item>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                <a-form-model-item label="创建时间:">
                    <a-range-picker
                          :show-time="{ format: 'HH:mm' }"
                          format="YYYY-MM-DD HH:mm"
                          :placeholder="['开始时间', '结束时间']"
                          @change="onChange"
                          @ok="onOk"
                        />
                </a-form-model-item>
              </div>
            </a-col>
          </a-row>
           <a-row :gutter="24">
               <a-col class="gutter-row"  :span="24">
                    <div class="gutter-box style_80">
                      <a-button class="search" icon="search" @click="getuserList">
                          搜索
                      </a-button>
                      <a-button   class="reset" icon="redo" @click="reset">
                          重置
                      </a-button>
                    </div>
              </a-col>
            </a-row>
         </a-form-model>
        <div class="bgc_white  marginTop">
            <a-row :gutter="24" >
             <a-col class="gutter-row style_70" :span="6">
                 <a-button class="search"  icon="plus" @click="showModal(0,0)">
                          新增
                 </a-button>
                  <a-button class="prink_search" icon="delete" @click="deleteList">
                          批量删除
                 </a-button>
                  <!-- <a-button   :disabled="!hasSelected" :loading="loading" @click="start">
                          预览
                    </a-button> -->
             </a-col>
            </a-row>
            <a-row :gutter="24" >
              <a-col class="gutter-row marginTop" :span="24">
                <!-- 已选多少个 start -->
                 <!-- <div style="margin-bottom: 16px">

                    <span style="margin-left: 8px">
                      <template v-if="hasSelected">
                        {{ `Selected ${selectedRowKeys.length} items` }}
                      </template>
                    </span>
                  </div> -->
                   <!--  -->
                  <a-table
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                     rowKey='id'
                    :columns="columns"
                    :pagination= pagination
                    :data-source="userlist"
                     bordered
                     @change="handleTableChange"
                  >
                  <span slot="sex" slot-scope="sex">{{sex=='男'?'男':'女'}} </span>
                  <!-- roleName -->
                  <span slot="stName" slot-scope="stName">{{ stName == '发布'?'已发布':'已撤回'}} </span>
                   <template slot="action" slot-scope="data">
                      <div class="actionSlot">
                      <a-button  class="search"  icon='edit' @click="showModal(data.id,data)" >
                          编辑
                      </a-button>
                      <div>
                        <a-popconfirm placement="left" ok-text="发布" cancel-text="不发布" @confirm="confirm(data.id,1)">
                          <template slot="title">
                            <p>发布此文章</p>
                            <p>发布此文章后直接适应到平台</p>
                          </template>
                          <a-button class="chongzhi" icon='upload'  v-if="data.stName=='草稿'" >
                            草稿
                          </a-button>
                        </a-popconfirm>
                        <a-popconfirm placement="left" ok-text="发布" cancel-text="不发布" @confirm="confirm(data.id,1)">
                          <template slot="title">
                            <p>发布此文章</p>
                            <p>发布此文章后直接适应到平台</p>
                          </template>
                          <a-button class="chongzhi" icon='upload'  v-if="data.stName=='撤销'" >
                            发布
                          </a-button>
                        </a-popconfirm>
                        <a-popconfirm placement="left" ok-text="撤销" cancel-text="不撤销" @confirm="confirm(data.id,3)">
                          <template slot="title">
                            <p>是否撤销?</p>
                            <p>撤销后将取消此文章发布</p>
                          </template>
                          <a-button class="chongzhi" icon='redo'  v-if="data.stName=='发布'"   >
                              撤销
                          </a-button>
                        </a-popconfirm>
                      </div>
                      <a-popconfirm placement="left" ok-text="删除" cancel-text="不删除" @confirm="deleteChange(data.id)">
                        <template slot="title">
                          <p>是否删除此用户?</p>
                          <p>删除成功后将不能恢复</p>
                        </template>
                         <a-button class="prink_search" icon="delete">
                            删除
                         </a-button>
                      </a-popconfirm>

                    </div>
                   </template>
                  </a-table>
              </a-col>
            </a-row>
        </div>
        <!-- 模态框 start -->
          <a-modal
              width='83%'
              :title="title"
              :visible="visible"
              :confirm-loading="confirmLoading"
              fill="#4a72b7"
              @cancel="handleCancel"
              cancelText="关闭"
              wrapClassName="ant-modal-cust-warp"
              style="top:5%;height: 85%;overflow-y: hidden"
            >
                <a-form-model ref='formModel' :rules="rules" :model="formModel"   :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="专题标题:" prop="spTitle">
                      <a-input v-model="formModel.spTitle" />
                    </a-form-model-item>
                    <a-form-model-item label="所属栏目:" prop="spId">
                      <a-select
                          :allowClear="true"
                          style="width: 100%"
                          placeholder="全部"
                          v-model="formModel.spId">
                          >
                          <a-select-option v-for="(role,index) in optionList" :key="index"  >
                            {{ role.typeName }}
                          </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="正文:" prop='spContext' >
                      <Edia v-model="formModel.spContext"></Edia>
                    </a-form-model-item>
                  </a-form-model>
                    <template slot="footer">
                        <div class="footer">
                          <a-button @click=" submodal(0)" style="background:#70B603;color:white">预览</a-button>
                          <a-button  @click="submodal(2)" style="background:#F59A23;color:white">草稿</a-button>
                          <a-button  @click="submodal(1)" type="primary">发布</a-button>
                          <a-button  @click="handleCancel">取消</a-button>
                        </div>
                      </template>
          </a-modal>
          <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
import { Url } from '../../plugin/http'
import Edia from '../editor/index.vue'
const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    align: 'center',
    key: 'id'
  },
  {
    title: '专题名称',
    dataIndex: 'spTitle',
    width: '30%',
    align: 'center',
    key: 'spTitle'
  },
  {
    title: '点击数',
    dataIndex: 'spId',
    align: 'center',
    key: 'spId'
  },
  // stId
  {
    title: '状态',
    dataIndex: 'stName',
    key: 'stName',
    align: 'center',
    scopedSlots: {
      customRender: 'stName'
    }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
    key: 'createTime'
  },
  {
    title: '操作',
    width: '30%',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  components: { Edia },
  data () {
    return {
      title: '',
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      userlist: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      formModel: {
        id: 0,
        spTitle: '',
        spId: '',
        spContext: '',
        stId: 0
      },
      statusList: [
        {
          type: 0,
          label: '正常'
        }, {
          type: 1,
          label: '禁用'
        }
      ],
      roleList: [
        {
          type: 2,
          label: '公证员'
        }, {
          type: 3,
          label: '管理员'
        }
      ],
      optionList: [
      ],
      cityList: [
        {
          type: 0,
          label: '全部'
        },
        {
          type: 1,
          label: '发布'
        }, {
          type: 2,
          label: '草稿'
        }
      ],

      formData: {
        endTime: '',
        startTime: '',
        userName: '',
        userStatu: 0
      },
      rules: {
        spTitle: [{ required: true, message: '专题标题不能为空', trigger: 'blur' }],
        spId: [{ required: true, message: '所属栏目不能为空', trigger: 'blur' }]
        // phone: [{
        //   validate: checkMobile, trigger: 'blur'
        // }],
        // emaill: [{
        //   validator: checkEmail, trigger: 'blur'
        // }]
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      },
      upUrl: Url + '/file/uploadFiles',
      deleteUrl: ''
    }
  },
  created () {
    this.getuserList()
    this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    // 更改文章状态
    async confirm (e, statu) {
      const { data: res } = await this.$http.post('/backSpecial/updateSpecial', {
        id: e,
        stId: parseInt(statu)
      })
      this.$message.success(res.msg)
      this.getuserList()
    },
    // 获取部门列表
    async optionListChange () {
      const { data: res } = await this.$http.get('/backArticle/insertArticleDate')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.optionList = res.data
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    },
    handleUser (key) {
    },
    // 模态框 start
    showModal (id, data) {
      console.log(id)
      if (id === 0) {
        this.title = '新增专题'
        this.formModel.spTitle = ''
        this.formModel.spId = ''
        this.formModel.spContext = ''
        this.formModel.stId = ''
      } else {
        this.title = '编辑专题'
        console.log(data)
        this.formModel.spTitle = data.spTitle
        this.formModel.spId = data.spId
        this.formModel.spContext = data.spContext
        this.formModel.stId = data.stId
        this.formModel.id = data.id
        // upDown
        // journalismContent: '',
      }
      this.visible = true
    },
    // 新增
    async submodal (e) {
      if (e === 0) {
        return this.$message.success('请点击文件预览即可查看')
      } else if (e === 1) {
        this.formModel.stId = 1
      } else if (e === 2) {
        this.formModel.stId = 2
      }

      this.$refs.formModel.validate(async valid => {
      // console.log(valid)
        if (!valid) return this.$message.error('输入非法数据，请重新输入')
        // 解构赋值，把data赋值给res { data: res }
        if (this.title === '新增专题') {
          const { data: res } = await this.$http.post('/backSpecial/insertSpecial', {
            spTitle: this.formModel.spTitle,
            spId: this.formModel.spId,
            spContext: this.formModel.spContext,
            stId: this.formModel.stId
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.getuserList()
          this.visible = false
        } else {
          const { data: res } = await this.$http.post('/backSpecial/updateSpecial', {
            spTitle: this.formModel.spTitle,
            spId: this.formModel.spId,
            spContext: this.formModel.spContext,
            stId: this.formModel.stId,
            id: this.formModel.id
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.visible = false
          this.getuserList()
          this.$message.success(res.msg)
        }
      })
    },
    // 取消
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
    },
    // 模态框 end
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backSpecial/deleteInSpecial', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.userlist = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)

      this.getuserList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.userName = ''
      this.formData.userStatu = 0
      this.getuserList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (value, dateString) {
      console.log(dateString)
      this.formData.startTime = dateString[0]
      this.formData.endTime = dateString[1]
      console.log(this.formData.startTime)
      console.log(this.formData.endTime)
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async getuserList () {
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      // this.$refs.loginFromRef.validate(async valid => {
      // console.log(valid)
      // if (!valid) return this.$message.error('输入非法数据，请重新输入')
      // 解构赋值，把data赋值给res { data: res }
      const { data: res } = await this.$http.post('/backSpecial/QuerySpecial', {
        formData: this.formData,
        pageData: this.pageData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      console.log(res)
      if (res.data == null) {
        this.userlist = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userlist = res.data.list
      this.pagination.total = res.data.total
      // console.log(res.data.token)
      // window.sessionStorage.getItem('token', res.data.token)
      // 直接连接到首页的index
      // this.$router.push('admin/index')
      // this.$router.push('admin/usermanagement')
      // })
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.getuserList()
    }
  }
}
</script>

<style     scoped>
.style_80{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #00BFBF;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.footer{
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
  align-items: center;
}
  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
</style>

<template>
  <div>
    <a-card>
        <div class="border-left">
           便民服务/人员轮播
        </div>
        </a-card>
      <div>
        <a-form-model   class="bgc_white" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row :gutter="24" >
             <a-col class="gutter-row" :span="7">
              <div class="gutter-box">
                 <a-form-model-item label="姓名:"  >
                     <a-input v-model="formData.title" allowClear />
                 </a-form-model-item>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="7">
              <div class="gutter-box style_80">
                    <a-button class="search"  icon="plus" @click="showModal(0,0)"  >
                                新增
                    </a-button>
                      <a-button class="search" icon="search" @click="getarticleList">
                          搜索
                      </a-button>
                      <a-button   class="reset" icon="redo" @click="reset">
                          重置
                      </a-button>
                    </div>
            </a-col>
          </a-row>
           <a-row :gutter="24" >
                <a-col class="gutter-row marginTop" :span="24">
                    <a-table
                      rowKey='id'
                      :columns="columns"
                      :pagination= pagination
                      :data-source="articleList"
                      bordered
                      @change="handleTableChange"
                    >
                   <span slot="notaryPic" slot-scope="notaryPic">
                  <img style="width:100px"  :src="notaryPic" alt="">
                  </span>
                    <span slot="status" slot-scope="status">{{ status == true?'使用中':'未使用'}} </span>
                    <template slot="action" slot-scope="data">
                        <div class="actionSlot">
                        <a-button class="bgc_none ed"  icon='form' @click="showModal(data.id,data)" >
                            编辑
                        </a-button>
                      <div>
                        <a-popconfirm placement="left" ok-text="发布" cancel-text="不发布" @confirm="confirm(data.id,1)">
                          <template slot="title">
                            <p>发布此公证员</p>
                            <p>发布此公证员后直接适应到平台</p>
                          </template>
                          <a-button class="cao" icon='container'  v-if="data.status==2" >
                            草稿
                          </a-button>
                        </a-popconfirm>
                        <a-popconfirm placement="left" ok-text="发布" cancel-text="不发布" @confirm="confirm(data.id,1)">
                          <template slot="title">
                            <p>发布此公证员</p>
                            <p>发布此公证员后直接适应到平台</p>
                          </template>
                          <a-button class="bgc_none fabu" icon='upload'  v-if="data.status==3" >
                            发布
                          </a-button>
                        </a-popconfirm>
                        <a-popconfirm placement="left" ok-text="撤销" cancel-text="不撤销" @confirm="confirm(data.id,3)">
                          <template slot="title">
                            <p>是否撤销?</p>
                            <p>撤销后将取消此公证员发布</p>
                          </template>
                          <a-button class="bgc_none che" icon='rollback'  v-if="data.status==1"   >
                              撤销
                          </a-button>
                        </a-popconfirm>
                      </div>
                        <a-popconfirm placement="left" ok-text="删除" cancel-text="不删除" @confirm="deleteChange(data.id)">
                          <template slot="title">
                            <p>是否删除此公证员?</p>
                            <p>删除成功后将不能恢复</p>
                          </template>
                          <a-button class="bgc_none delete" icon="delete">
                              删除
                          </a-button>
                        </a-popconfirm>

                      </div>
                    </template>

                    </a-table>
                </a-col>
              </a-row>
         </a-form-model>
       <!-- 模态框 start -->
          <a-modal
              width='70%'
              :title="title"
              :visible="visible"
              :confirm-loading="confirmLoading"
              fill="#4a72b7"
              @cancel="handleCancel"
              cancelText="关闭"
              wrapClassName="ant-modal-cust-warp"
              style="top:5%;height: 85%;overflow-y: hidden"
            >
                <a-form-model ref='formModel' :rules="rules" :model="formModel"   :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="名称" prop="notaryName">
                      <a-input v-model="formModel.notaryName" />
                    </a-form-model-item>
                     <a-form-model-item label="所属部门:" prop="notaryDept">
                      <a-select
                          :allowClear="true"
                          style="width: 100%"
                          placeholder="全部"
                          v-model="formModel.notaryDept">
                          >
                          <a-select-option v-for="(role,index) in optionList"  :key="index" :value="role.id"  >
                            {{ role.deptName }}
                          </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="职位:" prop="notaryPosition">
                      <a-select
                          :allowClear="true"
                          style="width: 100%"
                          placeholder="全部"
                          v-model="formModel.notaryPosition">
                          >
                          <a-select-option v-for="(role,index) in roleList" :key="index"  :value="role.id">
                            {{ role.roleName }}
                          </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="性别：" prop="sex">
                      <a-radio-group v-model="formModel.sex">
                        <a-radio value="女">
                          女
                        </a-radio>
                        <a-radio value="男">
                          男
                        </a-radio>
                      </a-radio-group>
                    </a-form-model-item>
                     <a-form-model-item label="学历" prop="education">
                      <a-radio-group v-model="formModel.education">
                        <a-radio value="博士">
                          博士
                        </a-radio>
                        <a-radio value="硕士">
                          硕士
                        </a-radio>
                        <a-radio value="本科">
                          本科
                        </a-radio>
                      </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="照片"  >
                                   <!-- <img v-if="formModel.notaryPic" class="imgListr" :src="formModel.notaryPic" alt=""> -->
                          <el-upload
                            class="upload-demo"
                            :action="upUrl"
                            :on-success="handleUploadSuccess"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            list-type="picture">
                            <el-button   size="small" type="primary">   点击上传</el-button> (必填)
                        </el-upload>
                    </a-form-model-item>
                     <!-- <a-form-model-item label="业务范围:" prop='notaryBrief' >
                        <a-input v-model="formModel.notaryBrief"   />
                    </a-form-model-item> -->
                    <a-form-model-item label="电子名片:" >
                        <el-upload
                            class="upload-demo"
                            :action="upUrl"
                            :on-success="handleUploadSuccess1"
                            :on-preview="handlePreview1"
                            :on-remove="handleRemove1"
                            :file-list="fileList1"
                            list-type="picture">
                            <el-button  size="small" type="primary">点击上传</el-button>(必填)
                        </el-upload>
                    </a-form-model-item>
                  </a-form-model>
                    <template slot="footer">
                        <div class="footer">
                          <!-- <a-button  @click="submodal(0)" style="background:#70B603;color:white">预览</a-button> -->
                          <a-button  @click="submodal(2)" style="background:#F59A23;color:white">草稿</a-button>
                          <a-button  @click="submodal(1)" type="primary">发布</a-button>
                          <a-button  @click="handleCancel">取消</a-button>
                        </div>
                      </template>
          </a-modal>
          <!-- 模态框 end -->
      </div>
  </div>
</template>

<script>
import { Url } from '../../plugin/http'
const columns = [
  {
    title: '序号',
    dataIndex: 'rowId',
    align: 'center',
    key: 'rowId'
  },
  {
    title: '姓名',
    width: '35%',
    dataIndex: 'notaryName',
    align: 'center',
    key: 'notaryName'
  },
  {
    title: '图片',
    dataIndex: 'notaryPic',
    align: 'center',
    key: 'notaryPic',
    scopedSlots: {
      customRender: 'notaryPic'
    }
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '上传时间',
    width: '15%',
    dataIndex: 'createTime',
    align: 'center',
    key: 'createTime'
  },
  {
    title: '操作',
    width: '25%',
    align: 'center',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
for (let i = 1; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  })
}
export default {
  data () {
    return {
      // 图片上传 start
      previewVisible: false,
      previewImage: '',
      fileList: [],
      fileList1: [],
      // 图片上传 end
      defaultFileList: [],
      title: '',
      optionList: [],
      roleList: [],
      // 模态框 start
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      // 模态框 end
      // 列表数据
      articleList: [],
      // 列
      columns,
      pagination: {
        pageSizeOptions: ['5', '10', '15'],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`
      },
      queryParam: {
        pageSize: 5,
        pagenum: 1
      },
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      ids: [],
      // 公证员的消息
      formModel: {
        picList: [],
        busCardList: [],
        notaryName: '',
        notaryDept: '',
        notaryPosition: '',
        sex: '男',
        education: '博士',
        notaryPic: '',
        status: 0,
        notaryBrief: '',
        accessory: '',
        id: 0
      },
      rules: {
        notaryName: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        notaryDept: [{ required: true, message: '请选择所属部门', trigger: 'blur' }],
        notaryPosition: [{ required: true, message: '请选择职位', trigger: 'blur' }],
        fileList: [{ required: true, message: '请上传照片', trigger: 'blur' }],
        fileList1: [{ required: true, message: '请上传照片', trigger: 'blur' }]
        // notaryBrief: [{ required: true, message: '请填写业务范围', trigger: 'blur' }]
      },
      formData: {
        endTime: '',
        startTime: '',
        title: ''
      },
      pageData: {
        currentPage: 1,
        pageSize: 5
      },
      upUrl: Url + '/file/uploadFiles'
    }
  },
  created () {
    this.getarticleList()
    this.optionListChange()
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    // 获取部门列表
    async optionListChange () {
      const { data: res } = await this.$http.get('/backUser/insertData')
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.optionList = res.data.deptList
      this.roleList = res.data.notaryRoleList
    },
    // 部门下拉选择
    handleProperty (key) { // 这里拿到的key是当前选项所有信息而不只是选中的label
      console.log(key)
    },
    //  图片上传 start
    handleUploadSuccess (file) {
      console.log(file.data.fileName)
      console.log(file.data.fileUrl)
      console.log(this.fileList)
      var arr = [{
        name: '',
        url: ''
      }]
      if (this.fileList.length === 0) {
        arr[0].name = file.data.fileName
        arr[0].url = file.data.fileUrl
        if (arr[0].name) {
          this.fileList = arr
          console.log(this.fileList)
        }
      } else {
        this.fileList[0].name = file.data.fileName
        this.fileList[0].url = file.data.fileUrl
      }

      console.log(this.fileList)
    },
    handleRemove (file, fileList) {
      console.log(file)
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
      console.log(this.data.fileList)
    },
    // 2
    handleUploadSuccess1 (file) {
      console.log(file.data.fileName)
      console.log(file.data.fileUrl)
      console.log(this.fileList1)
      var arr = [{
        name: '',
        url: ''
      }]
      if (this.fileList1.length === 0) {
        console.log('111')
        arr[0].name = file.data.fileName
        arr[0].url = file.data.fileUrl
        if (arr[0].name) {
          this.fileList1 = arr
        }
      } else {
        console.log('222')
        this.fileList1[0].name = file.data.fileName
        this.fileList1[0].url = file.data.fileUrl
      }
    },
    handleRemove1 (file, fileList) {
      console.log(file)
      console.log(file, fileList)
    },
    handlePreview1 (file) {
      console.log(file)
      console.log(this.data.fileList1)
    },
    // 图片上传 end
    // 模态框 start
    showModal (id, data) {
      if (id === 0) {
        this.title = '新增人员轮播'
        this.formModel.notaryName = ''
        this.formModel.notaryDept = ''
        this.formModel.notaryPosition = ''
        this.formModel.sex = ''
        this.formModel.education = 0
        this.formModel.notaryPic = ''
        this.formModel.status = ''
        this.formModel.notaryBrief = ''
        this.formModel.accessory = ''
      } else {
        this.title = '编辑人员轮播'
        console.log(data)
        this.editorChange(id)
      }
      this.visible = true
    },
    // 根据id查询订单
    async  editorChange (id) {
      const { data: res } = await this.$http.get('/backNotary/queryNotaryById/' + id)
      this.formModel = res.data
      this.roleList = res.data.roleList
      this.fileList = res.data.picList
      this.fileList1 = res.data.busCardList
    //   this.formModel.title = res.data.title
    //   this.formModel.disc = res.data.disc
    //   this.formModel.url = res.data.url
    //   this.formModel.status = res.data.status
    //   this.formModel.id = res.data.id
    },
    // 新增公证员
    // 新增编辑人员轮播
    async submodal (e) {
      if (this.fileList.length === 0) {
        return this.$message.success('请上传照片')
      }
      if (this.fileList1.length === 0) {
        return this.$message.success('请上传电子名片')
      }
      console.log(this.formModel.notaryDept)
      if (e === 0) {
        return this.$message.success('请点击文件预览即可查看')
      } else if (e === 1) {
        this.formModel.status = 1
      } else if (e === 2) {
        this.formModel.status = 2
      }

      this.$refs.formModel.validate(async valid => {
      // console.log(valid)
        if (this.fileList.length === 0) {
          return this.$message.info('请上传照片')
        }
        if (this.fileList1.length === 0) {
          return this.$message.info('请上传电子名片')
        }

        if (!valid) return this.$message.error('输入非法数据，请重新输入')
        // 解构赋值，把data赋值给res { data: res }
        if (this.title === '新增人员轮播') {
          const { data: res } = await this.$http.post('/backNotary/insertNotary', {
            notaryName: this.formModel.notaryName,
            notaryDept: this.formModel.notaryDept,
            sex: this.formModel.sex,
            education: this.formModel.education,
            notaryPosition: this.formModel.notaryPosition,
            notaryPic: this.formModel.notaryPic,
            status: this.formModel.status,
            notaryBrief: this.formModel.notaryBrief,
            picList: this.fileList,
            busCardList: this.fileList1
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.getarticleList()
          this.visible = false
        } else {
          const { data: res } = await this.$http.post('/backNotary/updateNotary', {
            notaryName: this.formModel.notaryName,
            notaryDept: this.formModel.notaryDept,
            sex: this.formModel.sex,
            education: this.formModel.education,
            notaryPosition: this.formModel.notaryPosition,
            notaryPic: this.formModel.notaryPic,
            status: this.formModel.status,
            notaryBrief: this.formModel.notaryBrief,
            picList: this.fileList,
            busCardList: this.fileList1,
            id: this.formModel.id
          })
          if (res.code !== 0) return this.$message.error(res.msg)

          this.visible = false
          this.getarticleList()
          this.$message.success(res.msg)
        }
      })
    },
    // 取消
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
      this.formModel.picList = []
      this.fileList = []
      this.formModel.busCardList = []
      this.fileList1 = []
    },
    // 模态框 end
    // this.$router.push('admin/index')
    // 更改公证员状态
    async confirm (e, statu) {
      const { data: res } = await this.$http.post('/backNotary/updateNotaryInfoStatus', {
        id: e,
        status: parseInt(statu)
      })
      this.$message.success(res.msg)
      this.getarticleList()
    },

    handleUser (key) {
    },

    // 删除
    deleteChange (e) {
      this.ids.push(e)
      this.deleteList()
    },
    // 批量删除
    async deleteList () {
      if (this.ids.length === 0) {
        return this.$message.error('还未选择数据无法删除')
      }
      for (var i = 0; i < this.ids.length; i++) {
        for (var j = i + 1; j < this.ids.length; j++) {
          if (this.ids[i] === this.ids[j]) { // 第一个等同于第二个，splice方法删除第二个
            this.ids.splice(j, 1)
            j--
          }
        }
      }
      const { data: res } = await this.$http.post('/backNotary/DeleteInNotary', {
        ids: this.ids
      })
      console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.ids = []
      this.getarticleList()
    },
    // 修改
    // 查询
    // 重置密码
    reset () {
      this.formData.endTime = ''
      this.formData.startTime = ''
      this.formData.title = ''
      this.getarticleList()
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    onChange (value, dateString) {
      console.log(dateString)
      this.formData.startTime = dateString[0]
      this.formData.endTime = dateString[1]
      console.log(this.formData.startTime)
      console.log(this.formData.endTime)
    },
    onOk (value) {
      console.log('onOk: ', value)
    },
    start () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange (selectedRowKeys) {
      this.ids = selectedRowKeys
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 获取数据回显
    async getarticleList () {
      this.pageData.pageSize = this.pagination.pageSize
      this.pageData.currentPage = this.queryParam.pagenum
      // this.$refs.loginFromRef.validate(async valid => {
      // console.log(valid)
      // if (!valid) return this.$message.error('输入非法数据，请重新输入')
      // 解构赋值，把data赋值给res { data: res }
      const { data: res } = await this.$http.post('/backNotary/queryNotary', {
        formData: this.formData,
        pageData: this.pageData
      })
      if (res.msg === 'token失效') {
        return window.sessionStorage.clear('token')
      }
      // console.log(res)
      if (res.data == null) {
        this.articleList = []
      }
      if (res.code !== 0) return this.$message.error(res.msg)
      this.articleList = res.data.list
      this.pagination.total = res.data.total
      // console.log(res.data.token)
      // window.sessionStorage.getItem('token', res.data.token)
      // 直接连接到首页的index
      // this.$router.push('admin/index')
      // this.$router.push('admin/usermanagement')
      // })
    },
    // 更改分页
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      this.queryParam.pagesize = pagination.pageSize
      this.queryParam.pagenum = pagination.current
      if (pagination.pageSize !== this.pagination.pageSize) {
        this.queryParam.pagenum = 1
        pager.current = 1
      }
      this.pagination = pager
      this.getarticleList()
    }
  }
}
</script>

<style  scoped>
/* 图片上传 start */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 图片上传 结束 end */
.style_80{
  display: flex;
  align-items: center;
}
.style_70{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.style_80 button{
  margin: 10px;
  margin-top: 0;
}
.search{
  background-color: #4a72b7;
  color: white;
}
.prink_search{
  background-color: #EC808D;
  color: white;
}
.chongzhi{
  background-color: #00BFBF;
  color: white;
}
.reset{
  background-color: #F2F2F2;
  color:black
}
.bgc_white{
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
}
.marginTop{
 margin-top: 20px;
}
.actionSlot{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.ant-modal-header{
  background-color: #4a72b7;
}
/deep/.ant-modal-title{
  color: white;
  font-weight: 800;
}
/deep/.ant-btn.ant-btn-primary{
  background-color: #4a72b7;
}
.footer{
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
  align-items: center;
}
.imgListr{
    width: 100px;
    /* height: 100px; */
}
/deep/.ant-form input[type='file']{
 display: none!important;
}
/deep/.el-upload-list--picture .el-upload-list__item-thumbnail{
    width: auto;
}
  .border-left{
   border-left: 4px solid #4E80E7;
   padding-left: 10px;
   box-sizing: border-box;
   font-size: 18px;
   color: #333333;
 }
 .ed{
     color:#22C82D ;
     border: 1px solid #22C82D;
 }
 .che{
     color: #ee7951 ;
     border: 1px solid #ee7951;
 }
 .delete{
     color: #FF1616;
     border: 1px solid #FF1616;
 }
 .fabu{
     color:#3779DD;
     border: 1px solid #3779DD;
 }
 .cao{
     color: #fcaa28;
     border: 1px solid #fcaa28;
 }
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Admin from '../views/Admin.vue'
// index
import Index from '../components/index.vue'
// 文章管理
import article from '../components/article/article.vue'
// 素材轮播
import materialrotation from '../components/materialrotation/materialrotation.vue'
// 抵押登记
import mortgagRegistration from '../components/mortgagRegistration/mortgagRegistration.vue'
// 表单下载
import formDownload from '../components/formDownload/formDownload.vue'
// 在线服务
import onlineService from '../components/onlineService/onlineService.vue'
// 文章轮播
import articlerotation from '../components/articlerotation/articlerotation.vue'
// 人员轮播
import personnelRotation from '../components/personnelRotation/personnelRotation.vue'
// 专题管理
import thematicmanagement from '../components/thematicmanagement/thematicmanagement.vue'
// 预约管理
import bookingmanagement from '../components/bookingmanagement/bookingmanagement.vue'
// 用户管理
import usermanagement from '../components/usermanagement/usermanagement.vue'
// 角色管理
import rolemanagement from '../components/rolemanagement/rolemanagement.vue'
// 产品管理

import productManage from '../components/productManage/productManage.vue'
// 热点产品
import hotProducts from '../components/hotProducts/hotProducts.vue'
// 首页链接
import externalLinks from '../components/externalLinks/externalLinks.vue'
// 联系我们
// contactUs
import contactUs from '../components/contactUs/contactUs.vue'
// 线上公正事项
import onlineNotarization from '../components/onlineNotarization/onlineNotarization.vue'
// 机构简介
// jigou
import jigou from '../components/jigou/jigou.vue'
// 办证流程
import certificationProcess from '../components/certificationProcess/certificationProcess.vue'
// 抵押须知
import mortgageNotice from '../components/mortgageNotice/mortgageNotice.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    children: [
      {
        path: 'productManage',
        component: productManage
      },
      {
        path: 'mortgagRegistration',
        component: mortgagRegistration
      },
      {
        path: 'index',
        component: Index

      }, {
        path: 'article',
        component: article
      },
      {
        path: 'materialrotation',
        component: materialrotation
      },
      {
        path: 'formDownload',
        component: formDownload
      },
      {
        path: 'onlineService',
        component: onlineService
      },
      {
        path: 'articlerotation',
        component: articlerotation
      },
      {
        path: 'personnelRotation',
        component: personnelRotation
      },
      {
        path: 'thematicmanagement',
        component: thematicmanagement
      },
      {
        path: 'bookingmanagement',
        component: bookingmanagement
      },
      {
        path: 'usermanagement',
        component: usermanagement
      },
      {
        path: 'rolemanagement',
        component: rolemanagement
      },
      {
        path: 'externalLinks',
        component: externalLinks
      },
      {
        path: 'hotProducts',
        component: hotProducts
      },
      {
        path: 'contactUs',
        component: contactUs
      },
      {
        path: 'onlineNotarization',
        component: onlineNotarization
      },
      {
        path: 'jigou',
        component: jigou
      },
      {
        path: 'certificationProcess',
        component: certificationProcess
      },
      {
        path: 'mortgageNotice',
        component: mortgageNotice
      }
    ]
  }
]
// mortgageNotice
const router = new VueRouter({
  routes
})

// 必须有token才可以访问
// 路由导航前置守卫
// router.beforeEach((to, from, next) => {
//   const token = window.sessionStorage.getItem('token')
//   if (to.path === '/') return next()
//   if (!token && to.path === '/') {
//     next('/')
//   } else {
//     next()
//   }
// })

// router.beforeEach((to, from, next) => {
//   const token = window.sessionStorage.getItem('token')
//   if (to.path !== '/' && !token) {
//     next({ path: '/' })
//     console.log('1111111111')
//   } else {
//     if (to.path === '/' && token) {
//       console.log('2222222222')
//       next('/admin/article')
//     } else {
//       // token过期
//       console.log('333333')
//       next()
//     }
//   }
// })

router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem('token')
  if (to.path !== '/' && !token) {
    next({ path: '/' })
    console.log('1111111111')
  } else {
    if (to.path === '/' && token) {
      console.log('2222222222')
      next('/admin/article')
    } else {
      //   axios({
      //     url: 'https://www.tjsbfgzc.com:442/website/backUser/checkToken',
      //     method: 'post',
      //     headers: {
      //       token
      //     }
      //   })
      //   const { data: res } = 'this'.$http.get('/backUser/checkToken', {
      //     token
      //   })
      //   if (res.code !== 0) return 'this'.$message.error(res.msg)
      // token过期
      console.log('333333')
      next()
    }
  }
})
export default router

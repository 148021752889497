import Vue from 'vue'
import axios from 'axios'
import echarts from 'echarts'
// 导入 NProgress 包对应的JS和CSS
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.prototype.$echarts = echarts
const Url = 'https://www.tjsbfgzc.com:442/website'
// const Url = 'http://172.16.200.85:38760/website'
axios.defaults.baseURL = Url

axios.interceptors.request.use((config) => {
  NProgress.start()
  config.headers.Authorization = window.sessionStorage.getItem('token')
  // 在最后必须 return config
  return config
})

// 在 response 拦截器中，隐藏进度条 NProgress.done()
axios.interceptors.response.use((config) => {
  NProgress.done()
  return config
})

Vue.prototype.$http = axios
export { Url }
